import api from "api";
import { useCallback, useEffect, useState } from "react";
import { OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";
import ImpactStatementView from "./ImpactStatementView";

type Challenge = OpenAPI.GET<"/challenges/{id}">["response"];
type ImpactStatement = OpenAPI.Schemas["ImpactStatements"];

type ImpactStatementProps = {
  statementDefintion: Challenge["impactStatements"];
  ideaId: string;
  onUpdate?: (statement: ImpactStatement) => void;
  editable?: boolean;
};

const ActiveImpactStatement = ({ statementDefintion, ideaId, onUpdate, editable }: ImpactStatementProps) => {
  const user = useAppSelector((state) => state.user);

  const [impactStatement, setImpactStatement] = useState<ImpactStatement>({});

  const updateImpact = useCallback(
    (impactStatement) => {
      setImpactStatement(impactStatement);
      onUpdate?.(impactStatement);
    },
    [onUpdate],
  );

  useEffect(() => {
    let isMounted = true;

    api.ideas.getActiveImpactStatement(
      ideaId,
      (response: OpenAPI.GET<"/ideas/{idea_id}/impactStatement/active">["response"]) => {
        if (!isMounted) return;
        setImpactStatement(response.impactStatement);
      },
      () => {},
    );

    return () => {
      isMounted = false;
    };
  }, [user.ownerOrganisation._id, ideaId]);

  return (
    <div>
      <ImpactStatementView
        statementDefintion={statementDefintion}
        impactStatement={impactStatement}
        setImpactStatement={updateImpact}
        ideaId={ideaId}
        editable={editable}
      />
    </div>
  );
};

export default ActiveImpactStatement;
