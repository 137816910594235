import React, { useContext, useMemo } from "react";
import { Button, Label, Popup, Progress, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import util from "utils/utils";
import moment from "moment";

import { UserChip, IdeaBusinessProfileChip } from "components/lib/Chips";

import EmojiChooser from "components/lib/Emoji/EmojiChooser";
import Emoji from "components/lib/Emoji/Emoji";
import { ShadowedCard } from "components/lib/UI";
import { IdeaAssessmentReport } from "components/lib/Ideas";
import PinIdeaHelper from "components/lib/Ideas/PinIdeaHelper";

const IdeaCardComponent = styled(ShadowedCard)`
  margin-top: 20px !important;
  .cover-header {
    width: 100%;
    height: 75px;
    background-size: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    ${(props) =>
      !props.hasProgress &&
      `
      border-radius: inherit;
    `}
  }
  .name-container {
    width: 100%;
    overflow-wrap: wrap;
  }
  .name-text {
    max-width: 100%;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .submitted-label {
    color: #a3ded8;
    float: "right";
  }
  .status-label {
    position: absolute;
    top: 5px;
    right: 5px;
    margin-top: 35px;
  }
  .draft-label {
    color: #f9dab3;
    padding: 20;
    float: "right";
  }
  .footer-container {
    text-align: center;
    font-weight: 600;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .owner-challenge-link {
    margin-bottom: 5px;
  }
  .owner-name {
    border-bottom: 1px dotted ${({ theme }) => theme.primaryColour};
  }

  .idea-authors {
    display: flex;
    color: gray;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    .idea-others {
      cursor: pointer;
      user-select: none;
      text-decoration: underline;
      &:hover {
        color: ${({ theme }) => theme.primaryColour};
      }
    }
  }
`;

const AuthorPopup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const IdeaCard = ({
  onHeaderClicked,
  user,
  idea,
  challenge,
  t,
  footer,
  themedFooter,
  isYours,
  pinIdea,
  projectManagement,
  labelIdea,
  onSelect,
  isSelected,
  removeAssessors,
  invitations,
  fetchIdeas,
}) => {
  const theme = useContext(ThemeContext);
  const pmBoard = projectManagement?.boards?.filter((b) => b.forId === challenge?._id)[0];
  const pmBoardEnabled = pmBoard?.isEnabled;

  const completenessPercentage = parseInt((idea.completeness || 0) * 100);
  const ideaComplete = idea.completeness && idea.completeness === 1;

  const hasAssessments = useMemo(
    () => idea.assessments?.length > 0 || idea?.invitedExternalAssessors?.length > 0,
    [idea],
  );
  const submittedAssessments = idea.assessments?.filter((a) => a.isSubmitted).length;
  const pendingInvitations = invitations && invitations.filter((i) => i?.forIdeas && i.forIdeas.includes(idea._id));

  const { activityInfo = {}, createdAt = 0, updatedAt } = idea;

  const { lastActivityAt = updatedAt ?? createdAt } = activityInfo;

  const momentLastActivityAt = moment(lastActivityAt);

  return (
    <IdeaCardComponent
      style={isYours ? { borderBottom: `5px solid ${theme.primaryColour}` } : null}
      hasProgress={completenessPercentage > 0 && !idea.isSubmitted}
    >
      {completenessPercentage && !idea.isSubmitted ? (
        <Popup
          content={`${t("common:capitalise", { key: "generic.idea" })} is ${completenessPercentage}% complete`}
          trigger={
            <Progress
              size="large"
              style={{ height: "0.6rem" }}
              label={`${completenessPercentage}% complete`}
              indicating={!ideaComplete}
              success={ideaComplete}
              percent={completenessPercentage}
              attached="top"
            />
          }
        />
      ) : null}
      <Link
        to={`/ideas/${idea._id}`}
        onClick={
          onHeaderClicked &&
          ((e) => {
            onHeaderClicked();
            e.preventDefault();
          })
        }
      >
        <div
          style={{
            position: "relative",
            backgroundImage: `url(${util.mixinCssUrlFallback(util.ideaCoverImage(idea), util.ideaCoverImage())})`,
          }}
          className="cover-header"
        >
          <div
            style={{
              position: "absolute",
              zIndex: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,0.2)",
            }}
          />
          <div style={{ position: "relative", zIndex: 1 }}>
            {idea.isSubmitted ? (
              <Label
                style={{ position: "absolute", marginTop: 7, left: "calc(100% - -13px)" }}
                size="small"
                color="green"
                ribbon="right"
              >
                {t("ideas.card.submitted")}
              </Label>
            ) : (
              <Label
                style={{ position: "absolute", marginTop: 7, left: "calc(100% - -13px)" }}
                size="small"
                color="yellow"
                ribbon="right"
              >
                {t("ideas.card.draft")}
              </Label>
            )}
          </div>
          {util.canManageChallenge(user, challenge) ||
          util.hasPermission(user, "challenge.editIdeaDeadlines", challenge?._id) ||
          util.hasPermission(user, "challenge.viewIdeas", challenge?._id) ? (
            <div className="status-label">
              {idea.submissionDeadline ? (
                <Popup
                  trigger={
                    <Button
                      secondary
                      size="mini"
                      icon="calendar"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  }
                  content={`This ${t("generic.idea")} has a custom deadline: ${moment(idea.submissionDeadline.deadline).format("DD/MM/YYYY HH:mm")}`}
                />
              ) : null}
              {labelIdea && (
                <Popup
                  on="click"
                  hoverable
                  position="bottom center"
                  mouseEnterDelay={300}
                  trigger={
                    <Button
                      basic={(idea.stamps || []).length === 0}
                      inverted={(idea.stamps || []).length === 0}
                      secondary
                      size="mini"
                      icon="smile outline"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  }
                  content={
                    <div style={{ width: 400 }}>
                      <h4>Label this {t("generic.idea")}</h4>
                      <EmojiChooser
                        onComplete={(em) => labelIdea(idea._id, em, true)}
                        existing={idea.stamps}
                        hideExisting
                        onRemoveExisting={(em) => labelIdea(idea._id, em, false)}
                      />
                    </div>
                  }
                />
              )}
              {pinIdea ? (
                <Popup
                  mouseEnterDelay={100}
                  trigger={
                    <Button
                      size="mini"
                      basic={!idea.isPinned}
                      inverted={!idea.isPinned}
                      icon="pin"
                      secondary
                      onClick={(e) => {
                        pinIdea(idea._id, !idea.isPinned);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  }
                  content={
                    <div>
                      <p>
                        <b>{idea.isPinned ? t("ideas.options.pin.unPin") : t("ideas.options.pin.challenge")}</b>
                      </p>

                      <PinIdeaHelper challenge={challenge} />
                    </div>
                  }
                />
              ) : null}
              {onSelect && (
                <Button
                  size="mini"
                  icon="check"
                  basic={!isSelected}
                  inverted={!isSelected}
                  secondary
                  onClick={(e) => {
                    onSelect(idea);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              )}
            </div>
          ) : (
            <div className="status-label">
              {idea.isPinned && <Button size="mini" secondary icon="pin" />}
              {onSelect && (
                <Button
                  size="mini"
                  icon="check"
                  basic={!isSelected}
                  inverted={!isSelected}
                  secondary
                  onClick={(e) => {
                    onSelect(idea);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              )}
            </div>
          )}
        </div>
      </Link>

      <div className="content">
        <div className="name-container">
          <h3 className="name-text">
            <Link to={`/ideas/${idea._id}`} style={{ color: "#4d4949" }}>
              {idea.children ? <Icon name="sitemap" size="small" /> : null}
              {idea.name}
            </Link>
          </h3>
        </div>
        {idea.ownerChallenge && (
          <div className="owner-challenge-link">
            <Link
              style={{ textDecorationLine: "underline" }}
              to={`/challenges/${idea.ownerChallenge._id}`}
              className="meta"
            >
              {idea.ownerChallenge?.name}
            </Link>
          </div>
        )}

        {idea?.owner ? (
          <div>
            <span className="idea-authors">
              {idea.lockedOwnerIdeaBusinessProfile ? (
                <IdeaBusinessProfileChip ideaBusinessProfile={idea.lockedOwnerIdeaBusinessProfile} compact="very" />
              ) : (
                <UserChip user={idea.owner} compact="very" />
              )}
              {(idea.collaborators?.length || !!idea.lockedOwnerIdeaBusinessProfile?.name) && idea.authors ? (
                <>
                  &nbsp;{"+"}&nbsp;
                  <Popup
                    on="click"
                    trigger={
                      <span className="idea-others" onClick={(e) => e.preventDefault()}>
                        {util.pluralise(
                          idea.lockedOwnerIdeaBusinessProfile?.name
                            ? (idea.collaborators?.length || 0) + 1
                            : idea.collaborators?.length,
                          "other",
                          "others",
                        )}
                      </span>
                    }
                    content={
                      <AuthorPopup>
                        {idea?.authors
                          .filter((a) => !!idea.lockedOwnerIdeaBusinessProfile?.name || a._id !== idea.user)
                          .map((a) => (
                            <UserChip key={a._id} user={a} />
                          ))}
                      </AuthorPopup>
                    }
                  />
                </>
              ) : null}
            </span>
            <p style={{ marginTop: 4, fontSize: 13, color: "grey" }}>
              {idea.isSubmitted
                ? `${t("ideas.card.submittedOn", { date: moment(idea.submittedAt).format("DD/MM/YY") })}`
                : `${t("ideas.card.updatedAt", { date: moment(idea.updatedAt).format("DD/MM/YY") })}`}
              , last activity on {momentLastActivityAt.format("DD/MM/YY")}.
            </p>
          </div>
        ) : null}

        {(util.canManageChallenge(user, challenge) ||
          util.hasPermission(user, "challenge.viewProjectBoard", challenge?._id)) &&
          pmBoardEnabled &&
          pmBoard?.laneObject && (
            <div style={{ marginTop: 5, display: "inline-flex" }}>
              <Icon style={{ color: "#aab6b2" }} name="trello" />
              <p style={{ color: "#aab6b2" }}>{pmBoard.laneObject?.name}</p>
            </div>
          )}

        <div
          style={{
            display: "flex",
            maxWidth: "100%",
            flexWrap: "wrap",
            marginTop: 5,
          }}
        >
          {(util.canManageChallenge(user, challenge) ||
            util.hasPermission(user, "challenge.viewAssessments", challenge?._id)) &&
          hasAssessments ? (
            <Popup
              trigger={
                pendingInvitations?.length > 0 ? (
                  <Label style={{ cursor: "pointer" }} color="violet" size="tiny">
                    {Math.round(idea.assessmentSubmittedScore || 0)}% from {submittedAssessments || 0}/
                    {idea.assessments?.length || 0} assessors ({pendingInvitations?.length} pending)
                  </Label>
                ) : (
                  <Label style={{ cursor: "pointer" }} color="violet" size="tiny">
                    {Math.round(idea.assessmentSubmittedScore || 0)}% from {submittedAssessments || 0}/
                    {idea.assessments?.length || 0} assessors
                  </Label>
                )
              }
              style={{ zIndex: 100 }}
              on="click"
              basic
              content={
                <div style={{ width: 500 }}>
                  <IdeaAssessmentReport
                    fetchIdeas={fetchIdeas}
                    challenge={challenge}
                    idea={idea}
                    removeAssessors={removeAssessors}
                  />
                </div>
              }
            />
          ) : null}
          {idea.stamps && idea.stamps.length
            ? idea.stamps.map((stamp, index) => (
                <div key={index} className="emoji">
                  {util.canManageChallenge(user, challenge) ||
                  util.hasPermission(user, "challenge.editIdeaLabels", challenge?._id) ? (
                    <Emoji emoji={{ id: stamp }} size={20} onClick={(em) => labelIdea(idea, em.id, false)} />
                  ) : (
                    <Emoji emoji={{ id: stamp }} size={20} />
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
      <div
        className="extra-content footer-container"
        style={themedFooter ? { backgroundColor: theme.primaryColour } : null}
      >
        {footer}
      </div>
    </IdeaCardComponent>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const IdeaCardContainer = connect(mapStateToProps)(IdeaCard);

export default withTranslation()(IdeaCardContainer);
