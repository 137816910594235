import React, { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal, Button, Tab, Icon, Menu } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import api, { asMutation, asQuery } from "api";
import toast from "react-hot-toast";

import { Overview } from "./Overview";
import { Users } from "./Users";
import { Roles } from "./Roles";
import { Ideas } from "./Ideas";
import util from "utils/utils";
import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import { EditOverview } from "./EditOverview";
import Prompt from "utils/Prompt";
import { Settings } from "./Settings";
import { useMutation, useQuery } from "@tanstack/react-query";

type IIdeaBusinessProfileProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  onClose: () => void;
  onSave?: (ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
  onDelete?: (ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
  openInEditMode?: boolean;
};

const IdeaBusinessProfileModal = ({
  businessProfile: initialProfile,
  onClose,
  onSave,
  onDelete,
  openInEditMode,
}: IIdeaBusinessProfileProps) => {
  const { t } = useTranslation();

  const profileQuery = useQuery({
    queryKey: ["get", "users", "profiles", initialProfile?._id],
    queryFn: asQuery(api.users.getProfile, { params: [initialProfile?._id] }),
    enabled: !!initialProfile?._id,
    initialData: { ideaBusinessProfile: initialProfile },
  });

  const isCreating = profileQuery.isSuccess && !profileQuery.data.ideaBusinessProfile._id;

  const user = useAppSelector((state) => state.user);

  const [searchParams, setSearchParams] = useSearchParams();
  const tabActiveIndex = parseInt(searchParams.get("businessProfileTab") || "0", 10);

  const [isUnsaved, setUnsaved] = useState(false);
  const [isEditing, setIsEditing] = useState(isCreating || openInEditMode);

  const onProfileUpdated = (businessProfile) => {
    api.queryClient.setQueryData(["get", "users", "profiles", businessProfile._id], {
      ideaBusinessProfile: businessProfile,
    });
    api.queryClient.invalidateQueries({ queryKey: ["get", "users", "profiles"] });

    setUnsaved(false);
    onSave?.(businessProfile);
  };

  const updateProfileMutation = useMutation({
    mutationKey: ["post", "users", "profiles", profileQuery.data?.ideaBusinessProfile?._id],
    mutationFn: asMutation(api.users.updateProfile),
    onSuccess: ({ ideaBusinessProfile }) => {
      toast.success("Profile updated");
      onProfileUpdated(ideaBusinessProfile);
    },
    onError: () => {
      toast.error("Failed to update profile");
    },
  });

  const updateActiveTab = useCallback(
    (newIndex: number | string) => {
      setSearchParams((prev) => {
        prev.set("businessProfileTab", newIndex.toString());
        return prev;
      });
    },
    [setSearchParams],
  );

  const closeModal = useCallback(() => {
    if (isUnsaved) {
      util
        .confirm(
          "Unsaved changes",
          "Unsaved changes will be lost if the modal is closed. Are you sure you want to proceed and discard your changes?",
        )
        .then(() => {
          onClose && onClose();
          setSearchParams((prev) => {
            prev.delete("businessProfileTab");
            return prev;
          });
        })
        .catch(() => {});
    } else {
      onClose && onClose();
      setSearchParams((prev) => {
        prev.delete("businessProfileTab");
        return prev;
      });
    }
  }, [onClose, setSearchParams, isUnsaved]);

  if (!profileQuery.isSuccess) return null;

  const canEditProfile =
    isCreating ||
    util.hasPermission(user, "ideaBusinessProfile.editDetails", profileQuery.data.ideaBusinessProfile._id);

  return (
    <Modal
      open={!!profileQuery.data.ideaBusinessProfile}
      dimmer={
        <Modal.Dimmer
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            }
            closeModal();
          }}
        />
      }
      closeOnDimmerClick={!isUnsaved}
      closeOnEscape={!isUnsaved}
      mountNode={document.getElementById("semantic-modal-mount-node")}
    >
      <Prompt
        when={isUnsaved}
        message="Unsaved changes will be lost if the modal is closed. Are you sure you want to proceed and discard your changes?"
        shouldBlock={(event) => event.nextLocation.pathname !== event.currentLocation.pathname}
      />
      <Modal.Header>{profileQuery.data.ideaBusinessProfile.name}</Modal.Header>
      <Modal.Content scrolling style={{ maxHeight: 600, height: "fit-content" }}>
        {util.hasPermission(user, "ideaBusinessProfile.viewMembers", profileQuery.data.ideaBusinessProfile._id) ||
        util.hasPermission(user, "ideaBusinessProfile.editMembers", profileQuery.data.ideaBusinessProfile._id) ||
        util.hasPermission(user, "ideaBusinessProfile.viewIdeas", profileQuery.data.ideaBusinessProfile._id) ? (
          <Tab
            menu={{ secondary: true, pointing: true }}
            activeIndex={tabActiveIndex}
            onTabChange={(e, { activeIndex }) => updateActiveTab(activeIndex)}
            style={{ position: "relative" }}
            panes={[
              {
                menuItem: (
                  <Menu.Item>
                    <Icon name="building" />
                    Profile
                  </Menu.Item>
                ),
                render: () =>
                  isEditing ? (
                    <EditOverview businessProfile={profileQuery.data.ideaBusinessProfile} setUnsaved={setUnsaved} />
                  ) : (
                    <Overview businessProfile={profileQuery.data.ideaBusinessProfile} />
                  ),
              },
              !isCreating &&
              util.hasPermission(user, "ideaBusinessProfile.viewMembers", profileQuery.data.ideaBusinessProfile._id)
                ? {
                    menuItem: (
                      <Menu.Item>
                        <Icon name="users" />
                        Users
                      </Menu.Item>
                    ),
                    render: () => (
                      <Users businessProfile={profileQuery.data.ideaBusinessProfile} closeModal={closeModal} />
                    ),
                  }
                : undefined,
              !isCreating &&
              util.hasPermission(user, "ideaBusinessProfile.viewIdeas", profileQuery.data.ideaBusinessProfile._id)
                ? {
                    menuItem: (
                      <Menu.Item>
                        <Icon name="lightbulb" />
                        {t("common:capitalise", { key: "generic.ideas" })}
                      </Menu.Item>
                    ),
                    render: () => (
                      <Ideas businessProfile={profileQuery.data.ideaBusinessProfile} closeModal={closeModal} />
                    ),
                  }
                : undefined,
              !isCreating &&
              util.hasPermission(user, "ideaBusinessProfile.deleteProfile", profileQuery.data.ideaBusinessProfile._id)
                ? {
                    menuItem: (
                      <Menu.Item>
                        <Icon name="cogs" />
                        Settings
                      </Menu.Item>
                    ),
                    render: () => (
                      <Settings
                        businessProfile={profileQuery.data.ideaBusinessProfile}
                        closeModal={closeModal}
                        onDelete={onDelete}
                      />
                    ),
                  }
                : undefined,
              !isCreating &&
              util.hasPermission(user, "ideaBusinessProfile.editMembers", profileQuery.data.ideaBusinessProfile._id)
                ? {
                    menuItem: (
                      <Menu.Item
                        style={{
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        <Icon name="lock" />
                        Roles
                      </Menu.Item>
                    ),
                    render: () => (
                      <Roles businessProfile={profileQuery.data.ideaBusinessProfile} closeModal={closeModal} />
                    ),
                  }
                : undefined,
            ]}
          />
        ) : isEditing ? (
          <EditOverview businessProfile={profileQuery.data.ideaBusinessProfile} setUnsaved={setUnsaved} />
        ) : (
          <Overview businessProfile={profileQuery.data.ideaBusinessProfile} />
        )}
      </Modal.Content>
      <Modal.Actions>
        {isEditing ? null : (
          <Button basic onClick={closeModal}>
            {isCreating ? "Discard" : "Close"}
          </Button>
        )}
        {canEditProfile ? (
          isEditing ? (
            <>
              <Button
                onClick={() => {
                  if (isUnsaved) {
                    util
                      .confirm(
                        "Unsaved changes",
                        "Unsaved changes will be lost. Are you sure you want to proceed and discard your changes?",
                      )
                      .then(() => {
                        setUnsaved(false);
                        setIsEditing(false);
                        api.queryClient.setQueryData(
                          ["users", "profiles", profileQuery.data.ideaBusinessProfile._id],
                          profileQuery.data.ideaBusinessProfile,
                        );
                      })
                      .catch(() => {});
                  } else {
                    setUnsaved(false);
                    setIsEditing(false);
                    api.queryClient.setQueryData(
                      ["users", "profiles", profileQuery.data.ideaBusinessProfile._id],
                      profileQuery.data.ideaBusinessProfile,
                    );
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                primary
                loading={updateProfileMutation.isPending}
                onClick={() => {
                  updateProfileMutation.mutate(
                    {
                      params: [profileQuery.data.ideaBusinessProfile._id, profileQuery.data.ideaBusinessProfile],
                    },
                    {
                      onSuccess: () => {
                        setIsEditing(false);
                      },
                    },
                  );
                }}
              >
                <Icon name="check" />
                {isCreating ? "Save profile" : "Save changes"}
              </Button>
            </>
          ) : (
            <Button
              primary
              onClick={() => {
                updateActiveTab(0);
                setIsEditing(true);
              }}
            >
              <Icon name="pencil" />
              Edit profile
            </Button>
          )
        ) : null}
      </Modal.Actions>
    </Modal>
  );
};

export default IdeaBusinessProfileModal;
