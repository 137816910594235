import { SearchParams } from "simplydo/core";

export const groups = (api) => ({
  create(name, success, fail) {
    api.authenticatedRequest("POST", "/groups", { name }, success, fail);
  },
  get(id, success, fail) {
    api.authenticatedRequest("GET", `/groups/${id}`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/groups/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest("DELETE", `/groups/${id}`, null, success, fail);
  },
  join(id, userId, success, fail) {
    api.authenticatedRequest("POST", `/groups/${id}/members/${userId}`, null, success, fail);
  },
  bulkJoin(id, data, success, fail) {
    api.authenticatedRequest("POST", `/groups/${id}/members`, data, success, fail);
  },
  leave(id, userId, success, fail) {
    api.authenticatedRequest("DELETE", `/groups/${id}/members/${userId}`, null, success, fail);
  },
  bulkLeave(id: string, data: { users: string[] }, success, fail) {
    api.authenticatedRequest("DELETE", `/groups/${id}/members`, data, success, fail);
  },
  discover(success, fail) {
    api.authenticatedRequest("GET", "/groups", null, (data) => success && success(data.groups), fail);
  },
  getMembers(id, params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/groups/${id}/members?${queryParams}`, null, success, fail);
  },
  getChallenges(id, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/groups/${id}/challenges`,
      null,
      (data) => success && success(data.challenges),
      fail,
    );
  },
  getFeed(id, params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/groups/${id}/feed?${queryParams}`, null, success, fail);
  },
  getFeedMentionable(id, query, success, fail) {
    api.authenticatedRequest("GET", `/groups/${id}/feed/mentionable?query=${query}`, null, success, fail);
  },
  getActivityFeed(id, params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/groups/${id}/activityfeed?${queryParams}`, null, success, fail);
  },
  postToActivityFeed(id, data, success, fail) {
    api.authenticatedRequest("POST", `/groups/${id}/activityfeed`, data, success, fail);
  },
  updateActivityFeedPost(id, postId, data, success, fail) {
    api.authenticatedRequest("POST", `/groups/${id}/activityfeed/${postId}`, data, success, fail);
  },
  deletePostFromActivityFeed(id, postId, success, fail) {
    api.authenticatedRequest("DELETE", `/groups/${id}/activityfeed/${postId}`, null, success, fail);
  },
  addComment(id, data, success, fail) {
    api.authenticatedRequest("POST", `/groups/${id}/feed`, data, success, fail);
  },
  deleteComment(id, commentId, success, fail) {
    api.authenticatedRequest("DELETE", `/groups/${id}/feed/${commentId}`, null, success, fail);
  },
  pinComment(id, commentId, isPinning, success, fail) {
    api.authenticatedRequest("PUT", `/groups/${id}/feed/${commentId}`, { isPinned: !!isPinning }, success, fail);
  },
  createAnnouncement(id, data, success, fail) {
    api.authenticatedRequest("POST", `/groups/${id}/announcements`, data, success, fail);
  },
  getAnnouncements(id, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/groups/${id}/announcements`,
      null,
      (data) => success && success(data.announcements),
      fail,
    );
  },
  deleteAnnouncement(id, announcementId, success, fail) {
    api.authenticatedRequest("DELETE", `/groups/${id}/announcements/${announcementId}`, null, success, fail);
  },
  updateAnnouncement(id, announcementId, data, success, fail) {
    api.authenticatedRequest("PUT", `/groups/${id}/announcements/${announcementId}`, data, success, fail);
  },
  getActivity(id, data, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/groups/${id}/activity?freq=${data.freq}&recent=${data.recent}`,
      null,
      success,
      fail,
    );
  },
  commentAddReaction(id, commentId, emoji, success, fail) {
    api.authenticatedRequest("PUT", `/groups/${id}/feed/${commentId}/reacts/${emoji}`, null, success, fail);
  },
  commentRemoveReaction(id, commentId, emoji, success, fail) {
    api.authenticatedRequest("DELETE", `/groups/${id}/feed/${commentId}/reacts/${emoji}`, null, success, fail);
  },
  getBusinesses(groupId, params, success, fail) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/groups/${groupId}/businesses${queryParams}`, null, success, fail);
  },
  exportUsersToCsv(id, success, fail) {
    api.authenticatedRequest("GET", `/groups/${id}/export/users`, null, success, fail);
  },
  exportBusinessesToCsv(id, success, fail) {
    api.authenticatedRequest("GET", `/groups/${id}/export/businesses`, null, success, fail);
  },
  getMemberships(success, fail) {
    api.authenticatedRequest("GET", `/groups/memberships`, null, success, fail);
  },
});
