import React, { Component } from "react";
import { Label, Divider, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import util from "utils/utils";
import { EmptyBox, MultiLineParagraph } from "components/lib/UI";
import Video from "components/lib/Video/Video";
import AchievementComponent from "components/lib/Users/Achievement";

const ProfileDiv = styled.div`
  .overview {
    font-size: 1.2rem;
  }
  .achievements {
    margin-top: 10px;
    .achievements-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      h3,
      h5 {
        display: block;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    .achievements-list {
      display: flex;
      gap: 16px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

class ProfileDetails extends Component {
  achievementScore = () => {
    const { profileUser } = this.props;
    const { achievements = [] } = profileUser;
    let achievementScore = 0;
    achievements.forEach((achievement) => {
      if (achievement.exp) {
        achievementScore += achievement.exp;
      }
    });
    return achievementScore;
  };

  render() {
    const { profileUser, isYourProfile, t, user, loading } = this.props;
    const { profile = {}, achievements = [], customAchievements = [], ownerTags = [] } = profileUser;
    const { overview, video, videoUrl } = profile;
    return (
      <ProfileDiv>
        {loading ? (
          <Loader active />
        ) : (
          <>
            {(overview || video) && <h3 style={{ textTransform: "uppercase" }}>{t("generic.overview")}</h3>}
            {video ? <Video style={{ margin: "30px auto" }} src={videoUrl} /> : null}
            {overview ? (
              <div className="overview">
                <MultiLineParagraph content={overview} />
              </div>
            ) : null}
            {(overview || video) && <Divider hidden />}
            {ownerTags && ownerTags.length ? (
              <div style={{ marginTop: 20 }}>
                <h3 style={{ textTransform: "uppercase" }}>{t("generic.tags")}</h3>
                {ownerTags.map((tag) => (
                  <Label style={{ marginTop: 3 }} key={tag._id} size="large" circular>
                    {tag.value}
                  </Label>
                ))}
                <Divider hidden />
              </div>
            ) : null}

            {(isYourProfile || util.hasPermission(user, "org.viewDashboard", user.ownerOrganisation._id)) && (
              <div className="achievements">
                <div className="achievements-title">
                  <div>
                    <h3 style={{ textTransform: "uppercase" }}>{t("generic.achievements")}</h3>
                  </div>
                  <div>
                    <Link className="with-border" to={"/achievements"}>
                      View all achievements
                    </Link>
                  </div>
                </div>
                {!achievements.length && !customAchievements.length ? (
                  <EmptyBox title="No achievements unlocked" />
                ) : (
                  <div className="achievements-list">
                    {customAchievements.map((a) => (
                      <AchievementComponent showAllInfo key={a._id} achievement={a} />
                    ))}
                    {achievements
                      .filter((a) => a.name)
                      .map((achievement, index) => (
                        <AchievementComponent showAllInfo key={index} achievement={achievement} />
                      ))}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </ProfileDiv>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

const ProfileDetailsContainer = withTranslation()(connect(mapStateToProps)(ProfileDetails));

export default ProfileDetailsContainer;
