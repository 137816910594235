import React, { useState, useCallback, useMemo } from "react";
import { Button, Label, Icon, Popup, SemanticICONS, Accordion, Input, Form, TextArea } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import styled from "styled-components";
import util from "utils/utils";
import api from "api";
import toast from "react-hot-toast";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { MetaInfo } from "./MetaContainer";
import { IHeaderImpactMeasure } from "./ProjectBoard";
import { ILaneData } from "./Board";
import { LaneSettingsProps, DropdownLaneSettings } from "./LaneSettings";
import { Checkbox } from "components/lib/UI";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { formatImpact, impactMultipliers } from "../Ideas/ImpactStatements/statementUtils";

const StyledLaneHeader = styled.div<{ $isSelecting?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  ${({ $isSelecting }) =>
    $isSelecting
      ? `
    >*:not(.selection-box) {
      opacity: 0.6;
      cursor: pointer;
    }
  `
      : null}
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-right: 5px;
  h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;

    font-size: 1.2em;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  span {
    font-weight: 375;
  }
`;

const LaneDescription = styled.p`
  color: gray;
  width: 100%;
  word-wrap: break-word;
  word-break: normal;
  white-space: normal;
  margin: 2.5px 0;
  text-align: left;
`;

const InvisibleButton = styled(Button)`
  &&& {
    background: #f1f2f4;
    &:hover {
      background: #e0e1e2;
    }
  }
`;

const Header = styled.div`
  display: flex !important;
  flex-direction: column !important;
  gap: 8px;
  background: rgb(241, 242, 244);
  padding: 10px 8px;
  .selection-box {
    opacity: 1;
    top: 0px;
    right: 0px;
    z-index: 200;
    display: flex;
    justify-content: space-between;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    padding: 5px;
    align-items: center;
    p {
      display: block;
      margin: 0 5px 0 5px;
    }
  }
`;

const StyledTitle = styled.h3<{ $editable?: boolean }>`
  ${({ $editable }) =>
    $editable &&
    `
    cursor: pointer;
  `}
`;

const CircleIconLabel = styled(Label)`
  i {
    margin: 0 !important;
  }
`;

const ImpactAccordion = styled(Accordion)`
  &&& {
    background: #f1f2f4;

    .title {
      user-select: none;
    }
  }
`;

const HeaderImpactMeasure = ({
  icon,
  title,
  value,
  impactType,
  impactName,
  impactEffect,
}: {
  icon: string;
  title: string;
  value: string | number;
  impactType?: Other.ImpactTypes;
  impactName: string;
  impactDescription: string;
  impactEffect?: Other.ImpactEffect;
}) => {
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  let usingValue;
  let usingTitle;

  const usesImpactStatementSystem = user?.ownerOrganisation?.enabledFeatures?.includes("impactStatements");
  if (usesImpactStatementSystem) {
    usingValue = formatImpact({ type: impactType, effect: impactEffect }, { value });
    usingTitle = "";
  } else {
    usingValue = impactType === "time" ? util.convertToDayOrWeek(value) : value;
    usingTitle = impactType === "time" ? "" : title;

    if (typeof usingValue === "number") {
      usingValue = usingValue.toLocaleString();
    }
    if (!usingValue) {
      usingValue = "None";
      usingTitle = "";
    }
  }

  const annualImpactValue = (impact) => {
    if (usesImpactStatementSystem) {
      return formatImpact(impact.ownerImpact, impact, {
        annually: true,
      });
    }

    if (typeof impact?.value === "string") {
      return impact?.value;
    }
    const annual = (impactMultipliers[impact?.frequency] ?? 1) * impact?.value;
    if (impact?.ownerImpact?.type === "time") {
      return util.convertToDayOrWeek(annual);
    }
    if (impact?.ownerImpact?.type === "money") {
      return `£${annual}`;
    }
    return annual;
  };

  const generateImpactText = (impact) => {
    const effectMap = {
      cost: "cost",
      saving: "save",
    };

    const effectText = effectMap[impact?.ownerImpact?.effect] ?? "impact";
    let text = `The ${t("generic.ideas")} in this lane will have a total ${effectText} of `;
    if (usesImpactStatementSystem) {
      text = `The ${t("generic.ideas")} in this lane will have a total ${effectText} of up to `;
    }
    if (usesImpactStatementSystem) {
      text += formatImpact(impact.ownerImpact, impact);
    } else {
      if (impact?.ownerImpact?.type === "time") {
        text += `${impact?.value} hours`;
      } else if (impact?.ownerImpact?.type === "money") {
        text += `£${impact?.value}`;
      } else {
        text += `"${impact?.value}"`;
      }
    }

    if (!impact?.frequency || impact?.frequency === "one-off") {
      text += " once";
    } else {
      text += ` ${impact?.frequency}`;
      if (impact?.frequency !== "annually") {
        text += ` (${annualImpactValue(impact)} annually)`;
      }
    }
    text += ".";

    return (
      <>
        <p>
          <strong>{impact?.ownerImpact?.name}</strong>
        </p>
        <p>{text}</p>
        {impact?.justification ? <i>{impact?.justification}</i> : null}
      </>
    );
  };

  return (
    <li style={{ paddingLeft: 2 }}>
      <Popup
        content={generateImpactText({
          value,
          frequency: "annually",
          ownerImpact: { name: impactName, type: impactType, effect: impactEffect },
        })}
        trigger={
          <div>
            <span>
              <Icon name={icon as SemanticICONS} />
              {impactName}
              {" - "}
              {impactEffect === "cost" ? "Costs " : impactEffect === "saving" ? "Saving " : ""}
              {usesImpactStatementSystem ? (impactEffect ? "up to " : "Up to ") : ""}
              {usingValue}
            </span>
            <span>{` ${impactType === "money" ? "" : usingTitle}`}</span>
          </div>
        }
      />
    </li>
  );
};

type ICustomLaneHeader = {
  lane: ILaneData;
  handleProps: DraggableProvidedDragHandleProps;
  user: Other.IUserMe;
  canManageBoard?: boolean;
  totalIdeas?: number;
  clickLane: (laneId: string) => void;
  laneSettingsProps: LaneSettingsProps;
  laneImpactMeasures?: IHeaderImpactMeasure[];
  isSelecting?: boolean;
  isSelected?: boolean;
  onSelect?: (ideaIds: string[]) => void;
};

const CustomLaneHeader = (props: ICustomLaneHeader) => {
  const {
    lane,
    handleProps = {},
    clickLane: _clickLane,
    canManageBoard,
    laneImpactMeasures,
    laneSettingsProps,
    isSelecting,
    isSelected,
    onSelect,
  } = props;
  const user = useAppSelector((state) => state.user);
  const { id, title, description, metaType, cards } = lane;
  const { forId, updateDefaultLane, setLanes, setIdeas, handleWebsocketLanes, handleWebsocketIdeas, lanes, ideas } =
    laneSettingsProps;

  const [editingLaneName, setEditingLaneName] = useState(false);
  const [newLaneName, setNewLaneName] = useState(title ?? "");
  const [savingNewLaneName, setSavingNewLaneName] = useState(false);

  const [editingLaneDescription, setEditingLaneDescription] = useState(false);
  const [newLaneDescription, setNewLaneDescription] = useState(description ?? "");
  const [savingNewLaneDescription, setSavingNewLaneDescription] = useState(false);

  const { t } = useTranslation();

  const metaTypeInformation = {
    notProgressing: {
      color: "orange",
      icon: "exclamation circle",
      text: `${t("common:capitalise", { key: "generic.ideas" })} not progressing`,
    },
    completed: {
      color: "teal",
      icon: "check circle",
      text: `Completed ${t("generic.ideas")}`,
    },
    resolved: {
      color: "blue",
      icon: "check circle",
      text: `Resolved ${t("generic.ideas")}`,
    },
  };

  const updateLane = useCallback(
    (data: Partial<Other.IProjectLane>, onComplete = () => {}, onError = () => {}) => {
      api.boards.updateProjectLane(
        forId,
        id,
        data,
        (updatedLane) => {
          if (id === "default") {
            updateDefaultLane(updatedLane);
          } else {
            const updatedLanes = Object.assign([], lanes).map((l) => (l._id === id ? updatedLane : l));
            setLanes(updatedLanes);
            handleWebsocketLanes(updatedLanes);
          }
          onComplete();
        },
        (err) => {
          toast.error(err.message);
          onError();
        },
      );
    },
    [forId, id, updateDefaultLane, lanes, setLanes, handleWebsocketLanes],
  );

  const saveNewLaneName = useCallback(() => {
    if (newLaneName !== title) {
      setSavingNewLaneName(true);
      updateLane(
        { name: newLaneName },
        () => {
          setEditingLaneName(false);
          setSavingNewLaneName(false);
        },
        () => {
          setEditingLaneName(false);
          setSavingNewLaneName(false);
        },
      );
    } else {
      setEditingLaneName(false);
    }
  }, [newLaneName, title, updateLane]);

  const saveNewLaneDescription = useCallback(() => {
    if (newLaneDescription !== description) {
      setSavingNewLaneDescription(true);
      updateLane(
        { description: newLaneDescription },
        () => {
          setEditingLaneDescription(false);
          setSavingNewLaneDescription(false);
        },
        () => {
          setEditingLaneDescription(false);
          setSavingNewLaneDescription(false);
        },
      );
    } else {
      setEditingLaneDescription(false);
    }
  }, [description, newLaneDescription, updateLane]);

  const reorderLaneIdeas = useCallback(
    (sort: string, sortDirection: string) => {
      api.boards.reorderLaneIdeas(
        forId,
        id,
        sort,
        sortDirection,
        ({ ideas: newIdeas }) => {
          const updatedIdeas = ideas.map((idea) => {
            const relatedIdea: Other.IIdea = newIdeas.find((i) => i._id === idea._id);
            if (!relatedIdea) {
              return idea;
            }
            const currentBoard = idea.projectManagement.boards.find((b) => b.forId === forId);
            const relatedBoard = relatedIdea.projectManagement.boards.find((b) => b.forId === forId);
            return {
              ...idea,
              projectManagement: {
                ...idea.projectManagement,
                boards: [
                  ...idea.projectManagement.boards.filter((b) => b.forId !== forId),
                  {
                    ...currentBoard,
                    order: relatedBoard.order,
                  },
                ],
              },
            };
          });
          setIdeas(() => updatedIdeas);
          handleWebsocketIdeas(updatedIdeas);
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [forId, handleWebsocketIdeas, id, ideas, setIdeas],
  );

  const headerProps = useMemo(
    () =>
      isSelecting
        ? {
            isSelecting: true,
            onClick: () => onSelect(cards.map((c) => c.id)),
          }
        : {
            ...handleProps,
          },
    [cards, handleProps, isSelecting, onSelect],
  );

  const metaTypeInfo = metaTypeInformation[metaType];
  return (
    <>
      <Header {...headerProps}>
        <StyledLaneHeader>
          <HeaderContainer>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: 8,
                width: "100%",
              }}
            >
              {!editingLaneName ? (
                <Label style={{ display: "flex" }}>
                  <Icon name="lightbulb" />
                  {cards.length}
                </Label>
              ) : null}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                  width: "100%",
                }}
              >
                {id === "default" && !editingLaneName ? (
                  <Popup
                    content={
                      <div>
                        This is the default lane, it cannot be deleted or moved to a different position.
                        {(canManageBoard || util.hasPermission(user, "challenge.editProjectBoard", forId)) &&
                        title === "Default" ? (
                          <Label size="small" icon="info" content="You can edit the name of this lane" />
                        ) : null}
                      </div>
                    }
                    trigger={
                      <div>
                        <CircleIconLabel
                          color={
                            (canManageBoard || util.hasPermission(user, "challenge.editProjectBoard", forId)) &&
                            title === "Default"
                              ? "yellow"
                              : "grey"
                          }
                          icon="lock"
                          circular
                        />
                      </div>
                    }
                  />
                ) : null}

                {editingLaneName ? (
                  <Input
                    value={newLaneName}
                    onChange={(e) => setNewLaneName(e.target.value)}
                    autoFocus
                    size="small"
                    fluid
                    style={{
                      width: "100%",
                      height: 28,
                    }}
                    action={
                      <Button
                        primary
                        icon="check"
                        onClick={() => saveNewLaneName()}
                        size="small"
                        loading={savingNewLaneName}
                      />
                    }
                  />
                ) : (
                  <Popup
                    trigger={
                      <StyledTitle
                        $editable={canManageBoard || util.hasPermission(user, "challenge.editProjectBoard", forId)}
                        onClick={() =>
                          (canManageBoard || util.hasPermission(user, "challenge.editProjectBoard", forId)) &&
                          setEditingLaneName(true)
                        }
                      >
                        {title}
                      </StyledTitle>
                    }
                    content={title}
                  />
                )}
              </div>
            </div>
          </HeaderContainer>

          {canManageBoard || util.hasPermission(user, "challenge.editProjectBoard", forId) ? (
            <DropdownLaneSettings
              {...laneSettingsProps}
              laneId={id}
              editLaneName={() => setEditingLaneName(true)}
              editLaneDescription={() => setEditingLaneDescription(true)}
              updateLane={updateLane}
              reorderLaneIdeas={reorderLaneIdeas}
              trigger={
                <InvisibleButton icon size="mini">
                  <Icon name="ellipsis horizontal" />
                </InvisibleButton>
              }
            />
          ) : null}
        </StyledLaneHeader>

        {metaTypeInfo ? (
          <MetaInfo icon={metaTypeInfo.icon} text={metaTypeInfo.text} color={metaTypeInfo.color} />
        ) : null}

        {editingLaneDescription ? (
          <div>
            <Form>
              <TextArea
                placeholder="Enter a description for this lane.."
                value={newLaneDescription}
                onChange={(e) => setNewLaneDescription(e.target.value)}
                autoFocus
              />
              <Button
                primary
                content="Save"
                icon="check"
                onClick={() => saveNewLaneDescription()}
                size="mini"
                loading={savingNewLaneDescription}
                style={{ marginTop: 5 }}
                fluid
              />
            </Form>
          </div>
        ) : (
          <>{description ? <LaneDescription>{description}</LaneDescription> : null}</>
        )}

        {isSelecting && cards?.length ? (
          <div className="selection-box">
            <p>Select all {t("generic.ideas")} in this lane</p>
            <Checkbox isSelected={isSelected} compact />
          </div>
        ) : null}

        <ImpactAccordion
          styled
          fluid
          defaultActiveIndex={0}
          style={
            laneImpactMeasures.filter((m) => !!m.laneTotalImpactValue).length
              ? {}
              : {
                  display: "none",
                }
          }
          panels={[
            {
              key: "total-impact",
              title: "Annual impact",
              content: (content, { active }) => (
                <div style={{ paddingRight: 10 }}>
                  <ul
                    style={{
                      display: active ? "block" : "none",
                      marginTop: 0,
                      paddingLeft: 31,
                      paddingRight: 21,
                      maxHeight: 100,
                      overflow: "auto",
                    }}
                  >
                    {laneImpactMeasures
                      .filter((m) => !!m.laneTotalImpactValue)
                      .map((impactMeasure) => {
                        if (impactMeasure.type === "dropdown") {
                          return null;
                        }
                        return (
                          <HeaderImpactMeasure
                            key={impactMeasure._id}
                            icon={impactMeasure.icon}
                            title={impactMeasure.name}
                            value={impactMeasure.laneTotalImpactValue ?? null}
                            impactType={impactMeasure.type}
                            impactName={impactMeasure.name}
                            impactDescription={impactMeasure.description}
                            impactEffect={impactMeasure.effect}
                          />
                        );
                      })}
                  </ul>
                </div>
              ),
            },
          ]}
        />
      </Header>
    </>
  );
};

export default CustomLaneHeader;
