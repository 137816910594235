import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  useRef,
  lazy,
  Suspense,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import uuid from "uuid";
import { OpenAPI, Other } from "simplydo/interfaces";
import { Divider, Message, Button, Label, Icon, Card, Popup, Menu, Loader, Dropdown, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { DateInput } from "components/lib/DateInputs";
import { useDidMount } from "utils/useDidMount";
import { useAppSelector, useAppDispatch } from "store";
import { ChallengesReducer, defaultInitialState as challengeReducerDefaultState } from "reducers/challenges";
import toast from "react-hot-toast";
import actions from "actions";
import util from "utils/utils";
import api from "api";
import useTheme from "theme/useTheme";
import styled from "styled-components";
import moment from "moment";

import IdeaPDFExport from "components/lib/PDFExport/IdeaPDFExport";
import { confirmMerge } from "components/lib/IdeaMerge";
import { EmptyBox } from "components/lib/UI";
import { HandledIdeaPreview as IdeaPreview } from "components/lib/Ideas";
import { ChallengeChooser, UserChooser } from "components/lib/Choosers";
import ProjectBoard from "components/lib/ProjectBoard";
import BoardEvents from "components/lib/ProjectBoard/BoardEvents";
import ChallengeIdeaFilters, { ChallengeFilterKeys } from "components/lib/Filters/ChallengeIdeaFilters";
import EmojiChooser from "components/lib/Emoji/EmojiChooser";
import PinIdeaHelper from "components/lib/Ideas/PinIdeaHelper";
import ProductTour from "components/lib/ProductTour";
import IdeaCard from "./IdeaCard";
import ChallengeMyIdeas from "./MyIdeas";

import {
  updateIdea as updateIdeaAction,
  labelIdea as labelIdeaAction,
  bulkUpdateAssessors,
  bulkRemoveAssessors as bulkRemoveAssessorsAction,
  bulkUpdateStatus,
  voteForIdea as voteForIdeaAction,
  addTag as addTagAction,
  unTag as unTagAction,
  Pagination,
  IdeaDeletionModal,
  IdeaDeadlineModal,
  updateIdea,
} from "./IdeaActions";

import { IdeaListContainer, StyledModal, ActionPopup } from "./Styles";
import { SearchParams } from "simplydo/core";
import { GroupUserManagementModal } from "components/lib/GroupUserManagementModal";

const SpreadsheetIdeas = lazy(() => import("./SpreadsheetIdeas"));

const StyledIdeasContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const StyledIdeaItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 100%;
  flex: 1;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (!theme.sizes.isComputer ? "column-reverse" : "row")};
  align-items: ${({ theme }) => (!theme.sizes.isComputer ? "flex-start" : "center")};
  margin-bottom: ${({ theme }) => (theme.sizes.isMobile ? "0" : "10px")};
  justify-content: space-between;
`;

const StyledFilters = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
  align-items: center;
  gap: 10px;
  .ui.button,
  a,
  .ui.dropdown {
    min-width: fit-content;
    ${({ theme }) =>
      theme.sizes.isMobile &&
      `
      margin-bottom: 5px;
      width: 100%;
    `}
  }
  .ui.button,
  .ui.dropdown {
    height: 33px;
  }
  ${({ theme }) =>
    theme.sizes.isMobile &&
    `
    width: 100%;
  `}
  ${({ theme }) =>
    !theme.sizes.isLargeComputer &&
    `
    margin-bottom: 5px;
  `}
`;

const IdeaViewTypes = [
  {
    key: "card",
    text: "Card",
    value: "card",
    icon: "vcard",
  },
  {
    key: "list",
    text: "List",
    value: "list",
    icon: "list",
  },
  {
    key: "board",
    text: "Board",
    value: "board",
    icon: "trello",
  },
  {
    key: "spreadsheet",
    text: "Table",
    value: "spreadsheet",
    icon: "table",
    requiredFeature: "ideaSpreadsheetView",
  },
];

type IdeasProps = {
  challenge: Other.IChallenge;
  myIdeas: Other.IIdeaList[];
  newIdea: () => void;
  allStamps: any[];
  updateAllStamps: Dispatch<SetStateAction<any[]>>;
  popularTags: Other.ITag[];
  updatePopularTags: Dispatch<SetStateAction<any[]>>;
  availableGroups: Other.IGroup[];
  setAvailableGroups: Dispatch<SetStateAction<any[]>>;
  availableOrganisations: Other.IOrganisation[];
  setAvailableOrganisations: Dispatch<SetStateAction<any[]>>;
  toggleStamp: (stamp: any) => void;
};

const Ideas = (props: IdeasProps) => {
  const {
    challenge,
    myIdeas,
    newIdea,
    allStamps,
    updateAllStamps,
    popularTags,
    updatePopularTags,
    availableGroups,
    setAvailableGroups,
    availableOrganisations,
    setAvailableOrganisations,
    toggleStamp,
  } = props;
  const [actionPopupOpen, setActionPopupOpen] = useState(false);

  const dispatch = useAppDispatch();

  const onFollow = useCallback(
    (context: string, id: string) => {
      dispatch(actions.user.onFollow(context, id));
    },
    [dispatch],
  );

  const updateFilters = useCallback(
    (filters: Partial<ChallengesReducer>) => {
      dispatch(actions.challenges.updateIdeaFilters(filters));
    },
    [dispatch],
  );

  const setIdeaViewType = useCallback(
    (viewType: string, resetParams = true) => {
      if (resetParams) {
        // Remove view type from query params
        const params = new SearchParams(window.location.search);
        params.delete("queryView");
        window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
      }
      if (["card", "list"].includes(viewType) && util.localStorageIsSupported()) {
        localStorage.setItem("ideaViewTypePreference", viewType);
      }
      dispatch(actions.challenges.setIdeaViewType(viewType));
    },
    [dispatch],
  );

  const updateTagList = useCallback(
    (id: string, tagList: Other.ITag[]) => {
      dispatch(actions.challenges.update(id, { tagList }));
    },
    [dispatch],
  );

  const updateDefaultLane = useCallback(
    (updatedLane) => {
      dispatch(
        actions.challenges.receive({
          ...challenge,
          projectBoard: {
            ...(challenge.projectBoard ?? {}),
            defaultLane: updatedLane,
          },
        }),
      );
    },
    [challenge, dispatch],
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const user: OpenAPI.GET<"/users/me">["response"] | undefined = useAppSelector((state) => state.user);
  const hasUser: boolean = !!user;
  const { organisation } = user ?? {};
  const ideaMergeEnabled = organisation?.enabledFeatures?.includes("ideaMerging");
  const canMakeIdea = util.canCreateIdea(user, challenge);
  const coverImageEnabled = !challenge?.preventIdeaCoverImages;

  const challengesReducer: ChallengesReducer = useAppSelector((state) => state.challenges);
  const {
    tagFilter,
    ideaFilter: query,
    ideaIncludes: filter,
    ideaOrder: sort,
    ideaOrderDirection: sortDirection,
    ideaPage: page,
    stampsFilter: stamps,
    ideaLimit: limit,
    ideaFieldChoices: fieldChoices,
    projectBoardLanesFilter,
    postFetchPreviewIndex,
    groupFilter,
    orgFilter,
    projectBoardFilter,
    ideaViewType,
    adminHasCommented,
    filtersVisible,
    impactFilter,
  } = challengesReducer;

  const [ideaState, setIdeaState] = useState({
    ideas: [],
    nextPageAvailable: false,
    previousPageAvailable: false,
    total: 0,
    basicLoading: true,
    loading: false,
  });
  const [showMyIdeas, setShowMyIdeas] = useState(true);

  const setIdeasLoading = useCallback(
    (isLoading) => setIdeaState((prevState) => ({ ...prevState, loading: isLoading })),
    [setIdeaState],
  );
  const setIdeasBasicLoading = useCallback(
    (isLoading) => setIdeaState((prevState) => ({ ...prevState, basicLoading: isLoading })),
    [setIdeaState],
  );

  const [ideasVotedFor, setIdeasVotedFor] = useState(0);
  const [challengeChooserIdea, setChallengeChooserIdea] = useState("");
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const [assessmentAssignIdeas, setAssessmentAssignIdeas] = useState([]);
  const [projectAssignIdeas, setProjectAssignIdeas] = useState([]);
  const [emojiStampChooserOpen, setEmojiStampChooserOpen] = useState("");
  const [deletingIdeas, setDeletingIdeas] = useState([]);
  const [deadlineIdeas, setDeadlineIdeas] = useState([]);
  const [groupAddIdeas, setGroupAddIdeas] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [openToComments, setOpenToComments] = useState(false);
  const [previewId, setPreviewId] = useState("");
  const [availableSortParameters, setAvailableSortParameters] = useState({});
  const [availableFilterParameters, setAvailableFilterParameters] = useState({});
  const [availableImpacts, setAvailableImpacts] = useState<OpenAPI.Schemas["OrganisationImpact"][]>([]);
  const [allProjectAssignees, setAllProjectAssignees] = useState<Other.IUser[]>([]);
  const [removingIdeaFromMerge, setRemovingIdeaFromMerge] = useState<string>("");
  const ideaFetchId = useRef<string>();

  const [allChallengeProjectLanes, setAllChallengeProjectLanes] = useState([]);
  const [selectingMultipleCards, setSelectingMultipleCards] = useState(false);

  const challengeId = challenge?._id;
  const { ideas, total, basicLoading, loading, nextPageAvailable, previousPageAvailable } = ideaState;
  const theme = useTheme();

  const params = new SearchParams(window.location.search);
  const paramIdeaId = params.get("idea");
  const paramViewType = params.get("queryView");
  useEffect(() => {
    setIdeaState((prevState) => ({ ...prevState, basicLoading: true }));
    if (paramViewType && IdeaViewTypes.map((v) => v.value).includes(paramViewType)) {
      setIdeaViewType(paramViewType, false);
    }
  }, [paramViewType, setIdeaViewType]);

  const canGoPrevIdea = useMemo(
    () => ideas.findIndex((i) => i._id === previewId) > 0 || previousPageAvailable,
    [ideas, previousPageAvailable, previewId],
  );
  const canGoNextIdea = useMemo(
    () => ideas.findIndex((i) => i._id === previewId) !== ideas.length - 1 || nextPageAvailable,
    [ideas, nextPageAvailable, previewId],
  );

  const listView = useMemo(() => ideaViewType === "list", [ideaViewType]);
  const { ideaFilter } = useAppSelector((state) => state.challenges);
  const filterDebounce = useRef<ReturnType<typeof setTimeout>>();
  const updateSearchTerm = useCallback(
    (term) => {
      updateFilters({ ideaFilter: term, ideaPage: 1 });
    },
    [updateFilters],
  );

  const canManageChallenge = useMemo(() => util.canManageChallenge(user, challenge), [user, challenge]);
  const canActionIdeas = useMemo(() => util.canActionIdeas(user, challenge), [user, challenge]);
  const canViewProjectBoard = useMemo(
    () => util.hasPermission(user, "challenge.viewProjectBoard", challenge?._id),
    [user, challenge],
  );

  useEffect(() => {
    setAssessmentAssignIdeas([]);
    setChallengeChooserIdea("");
    setEmojiStampChooserOpen("");
    setSelectedIdeas([]);
    setIdeaState((prevState) => ({ ...prevState, ideas: [] }));
    updateFilters({
      ideaIncludes: ideaViewType === "board" ? "submitted" : null,
      ideaLimit:
        // eslint-disable-next-line no-nested-ternary
        ideaViewType === "board" || ideaViewType === "spreadsheet" ? 10000 : ideaViewType === "list" ? 30 : 16,
    });
  }, [ideaViewType, setIdeasLoading, updateFilters]);

  useEffect(() => {
    if (!previewId) setOpenToComments(false);
  }, [previewId]);

  const openIdeaPreview = useCallback((ideaId) => {
    setPreviewId(ideaId);
  }, []);

  useDidMount(() => {
    setPreviewId(paramIdeaId);
  });

  useEffect(() => {
    if (ideaViewType !== "board") {
      setSelectingMultipleCards(false);
    }
  }, [ideaViewType]);

  const movePreviewIdea = useCallback(
    (amount) => {
      const ideaIndex = ideas.findIndex((i) => i._id === previewId);
      if (ideaIndex === -1) return;
      const newIndex = ideaIndex + amount;
      if (newIndex === -1 && previousPageAvailable) {
        // Set page to (current page - 1)
        // Set postFetchPreviewIndex to (limit - 1)
        updateFilters({ ideaPage: page - 1, postFetchPreviewIndex: limit - 1 });
      } else if (newIndex === ideas.length && nextPageAvailable) {
        // Set page to (current page + 1)
        // Set postFetchPreviewIndex to (0)
        updateFilters({ ideaPage: page + 1, postFetchPreviewIndex: 0 });
      } else {
        const newPreviewId = ideas.map((i) => i._id)[newIndex];
        setPreviewId(newPreviewId);
        // Set old previewId as viewed
        setIdeaState((prevState) => ({
          ...prevState,
          ideas: prevState.ideas.map((idea) => {
            if (idea._id !== previewId) return idea;
            return {
              ...idea,
              currentUserHasViewed: true,
            };
          }),
        }));
      }
    },
    [ideas, previewId, nextPageAvailable, previousPageAvailable, page, limit, updateFilters],
  );

  const shouldFetchIdeas = useMemo(() => challengeId && hasUser, [challengeId, hasUser]);

  useEffect(() => {
    let isMounted = true;
    api.challenges.getIdeaFilters(
      challengeId,
      (data: OpenAPI.GET<"/challenges/{id}/ideas/filters">["response"]) => {
        if (!isMounted) return;
        updateAllStamps(data.allStamps);
        updatePopularTags(data.popularTags);
        setAvailableGroups(data.availableGroups);
        setAvailableOrganisations(data.availableOrganisations);
        setAvailableFilterParameters(data.availableFilterParameters);
        setAvailableSortParameters(data.availableSortParameters);
        setAvailableImpacts(data.availableImpacts);

        // @ts-ignore
        setAllProjectAssignees(data.allProjectAssignees);
      },
      (err) => {
        toast.error(err.message);
        setIdeasLoading(false);
      },
    );

    return () => {
      isMounted = false;
    };
  }, [challengeId, setAvailableGroups, setAvailableOrganisations, setIdeasLoading, updateAllStamps, updatePopularTags]);

  const fetchIdeas = useCallback(() => {
    const fetchId = uuid.v4();
    ideaFetchId.current = fetchId;

    // Non-users can view this page, but we don't
    // want to load ideas. So don't enter load loop if no user
    if (!shouldFetchIdeas) return;
    setIdeasLoading(true);
    setIdeasBasicLoading(true);

    const queryParams = {
      page,
      query,
      tags: tagFilter,
      filter: filter || [],
      sort,
      sortDirection,
      stamps,
      groupFilter,
      orgFilter,
      fieldChoices,
      limit,
      adminHasCommented,
      projectBoardFilter,
      projectBoardLanesFilter,
      impactFilter,
    };

    // Keep track of whether the main query has finished
    let mainFinished = false;
    const queryFunc = (handleLoading = false) => {
      if (fetchId && fetchId !== ideaFetchId.current) {
        return;
      }
      if (handleLoading) {
        setIdeasLoading(true);
      }
      api.challenges.getIdeas(
        challengeId,
        { ...queryParams, contentType: "full" },
        (data: OpenAPI.GET<"/challenges/{id}/ideas">["response"]) => {
          mainFinished = true;
          if (fetchId && fetchId !== ideaFetchId.current) {
            return;
          }
          setIdeaState((prevState) => ({
            ...prevState,
            ideas: data.ideas,
            nextPageAvailable: data.nextPageAvailable,
            previousPageAvailable: data.previousPageAvailable,
            total: data.total,
          }));
          setIdeasVotedFor(data.ideasVotedFor);
          setInvitations(data.invitations);
          if (postFetchPreviewIndex !== null) {
            setPreviewId(data.ideas.map((i) => i._id)[postFetchPreviewIndex]);
          }
          setIdeasLoading(false);
        },
        (err) => {
          mainFinished = true;
          toast.error(err.message);
          setIdeasLoading(false);
        },
      );
    };

    // First kick off the main loader
    queryFunc();

    // Spreadsheet we always wait for the full content type
    if (ideaViewType !== "spreadsheet") {
      if (fetchId && fetchId !== ideaFetchId.current) {
        return;
      }
      // Then also load the basic version
      api.challenges.getIdeas(
        challengeId,
        { ...queryParams, contentType: "basic" },
        ({ ideas: basicIdeas }) => {
          if (fetchId && fetchId !== ideaFetchId.current) {
            return;
          }
          if (mainFinished) {
            return;
          }
          setIdeaState((prevState) => ({
            ...prevState,
            ideas: basicIdeas,
            basicLoading: false,
          }));
        },
        (err) => {
          toast.error(err.message);
          setIdeasLoading(false);
          setIdeasBasicLoading(false);
        },
      );
    }
  }, [
    shouldFetchIdeas,
    tagFilter,
    setIdeasLoading,
    setIdeasBasicLoading,
    page,
    query,
    filter,
    sort,
    sortDirection,
    stamps,
    groupFilter,
    orgFilter,
    fieldChoices,
    limit,
    adminHasCommented,
    projectBoardFilter,
    projectBoardLanesFilter,
    impactFilter,
    ideaViewType,
    challengeId,
    postFetchPreviewIndex,
  ]);

  useEffect(() => {
    if (filterDebounce.current) {
      clearTimeout(filterDebounce.current);
    }
    filterDebounce.current = setTimeout(() => {
      filterDebounce.current = undefined;
      fetchIdeas();
    }, 350);
  }, [fetchIdeas]);

  const getIdeaAssignees = useCallback(() => {
    api.challenges.getIdeaAssignees(
      challengeId,
      ({ allProjectAssignees: updatedAllProjectAssignees }) => {
        setAllProjectAssignees(updatedAllProjectAssignees);
      },
      () => {},
    );
  }, [challengeId]);

  const updateTagListWithTag = useCallback(
    (tag) => {
      if (challenge) {
        const tagList = [...challenge.tagList];
        const index = tagList.findIndex((allTag) => allTag._id === tag._id);
        tagList[index] = tag;

        updateTagList(challenge._id, tagList);
      }
    },
    [updateTagList, challenge],
  );

  const onSelectIdea = useCallback(
    (idea) => {
      const prevSelectedIdeas = Object.assign([], selectedIdeas);
      const index = prevSelectedIdeas.indexOf(idea._id);
      if (index === -1) prevSelectedIdeas.push(idea._id);
      else prevSelectedIdeas.splice(index, 1);
      setSelectedIdeas(prevSelectedIdeas);
    },
    [selectedIdeas, setSelectedIdeas],
  );

  // Update idea in ideas array using given data
  const onUpdateIdea = useCallback(
    (ideaId, data) => {
      updateIdeaAction(setIdeaState, ideaId, data);
    },
    [setIdeaState],
  );

  /* Update idea project info */
  const getChallengeProjectLanes = useCallback(() => {
    api.boards.getProjectLanes(
      challengeId,
      (data) => {
        setAllChallengeProjectLanes(data.lanes);
      },
      () => {},
    );
  }, [challengeId]);

  useEffect(() => {
    if (canManageChallenge || canViewProjectBoard) {
      getChallengeProjectLanes();
    }
  }, [getChallengeProjectLanes, canManageChallenge, canViewProjectBoard]);

  const updateProjectLane = useCallback(
    (ideaId, laneId) => {
      const prevIdea = ideas.find((i) => i._id === ideaId);
      const prevIdeaBoards = prevIdea?.projectManagement?.boards || [];
      const ideaIsInCurrentBoard = prevIdeaBoards.findIndex((b) => b.forId === challengeId) > -1;
      const newLane = allChallengeProjectLanes.find((l) => l._id === laneId) ?? {
        name: challenge?.projectBoard?.defaultLane?.name ?? "Default",
      };
      onUpdateIdea(ideaId, {
        projectManagement: {
          ...(prevIdea?.projectManagement || {}),
          boards: ideaIsInCurrentBoard
            ? prevIdeaBoards.map((b) => {
                if (b.forId !== challengeId) return b;
                return {
                  ...b,
                  lane: laneId,
                  laneObject: newLane,
                };
              })
            : [
                {
                  lane: laneId,
                  forId: challengeId,
                  laneObject: newLane,
                },
              ],
        },
      });
      api.boards.updateProjectIdea(
        challengeId,
        ideaId,
        laneId,
        0,
        () => {},
        (err) => {
          onUpdateIdea(ideaId, { projectManagement: prevIdea.projectManagement });
          toast.error(err.message);
        },
      );
    },
    [allChallengeProjectLanes, challenge?.projectBoard?.defaultLane?.name, challengeId, ideas, onUpdateIdea],
  );
  /*  */

  // Pass array of idea ids and user ids, and remove the users as assessors from these ideas
  const bulkRemoveAssessors = useCallback(
    (ideaIds, userIds) => {
      bulkRemoveAssessorsAction(ideaIds, challengeId, userIds, setAssessmentAssignIdeas, ideas, setIdeaState);
    },
    [setAssessmentAssignIdeas, challengeId, ideas, setIdeaState],
  );

  const onRemoveExternalInvitation = useCallback((ideaId, invitation) => {
    const { _id, invitee } = invitation;
    setInvitations((prevInvitations) => prevInvitations.filter((i) => i._id !== _id));
    setIdeaState((prevState) => ({
      ...prevState,
      ideas: prevState.ideas.map((idea) => {
        if (idea._id !== ideaId) return idea;
        return {
          ...idea,
          invitedExternalAssessors: idea.invitedExternalAssessors.filter((i) => i._id !== invitee),
        };
      }),
    }));
  }, []);

  const pinIdea = useCallback(
    (ideaId, isPinned) => {
      const originalIdeas = Object.assign([], ideas);
      updateIdea(setIdeaState, ideaId, { isPinned });
      api.ideas.updateStatus(
        ideaId,
        { isPinned },
        () => {
          toast.success(
            isPinned
              ? `${t("common:capitalise", { key: "generic.idea" })} pinned`
              : `${t("common:capitalise", { key: "generic.idea" })} unpinned`,
          );
        },
        () => {
          toast.error("Failed to pin idea");
          setIdeaState((prevState) => ({ ...prevState, ideas: originalIdeas }));
        },
      );
    },
    [t, ideas],
  );

  const bulkMoveIdeas = useCallback(
    (ideaIds, challengeId, onComplete = () => {}) => {
      api.ideas.moveToChallenge(
        ideaIds,
        challengeId,
        () => {
          toast.success(`${t("common:capitalise", { key: "generic.ideas" })} moved to ${t("generic.challenge")}`);
          setSelectedIdeas([]);
          setIdeaState((prevState) => ({
            ...prevState,
            ideas: prevState.ideas.filter((i) => !ideaIds.includes(i._id)),
          }));
          if (onComplete) onComplete();
        },
        (err) => toast.error(err.message),
      );
    },
    [t],
  );

  // Add emoji stamp to idea, with choice of whether to apply or remove
  const labelIdea = useCallback(
    (ideaId, label, isApplied) => {
      labelIdeaAction(ideas, ideaId, label, isApplied, toggleStamp, setIdeaState);
    },
    [setIdeaState, ideas, toggleStamp],
  );

  const voteForIdea = useCallback(
    (ideaId, addVote) => {
      voteForIdeaAction(setIdeaState, ideaId, ideas, ideasVotedFor, addVote, onFollow);
    },
    [setIdeaState, ideas, ideasVotedFor, onFollow],
  );

  const addTag = useCallback(
    (idea, newTagData) => {
      addTagAction(setIdeaState, updateTagListWithTag, ideas, idea, newTagData);
    },
    [setIdeaState, ideas, updateTagListWithTag],
  );

  const unTag = useCallback(
    (idea, tag) => {
      unTagAction(setIdeaState, updateTagListWithTag, idea, tag);
    },
    [setIdeaState, updateTagListWithTag],
  );

  const createChallengeFromIdea = useCallback(
    (ideaId) => {
      api.challenges.createFromIdea(
        { idea: ideaId },
        (data) => {
          navigate(`/challenges/${data.challenge._id}`);
          toast.success("Challenge created");
        },
        (err) => toast.error(err.message),
      );
    },
    [navigate],
  );

  const removeIdeaFromMerge = useCallback(
    (
      mergeIdeaId: string,
      ideaId: string,
      onComplete = (_mergeIdeaId: string, _ideaId: string) => {},
      onError = (_mergeIdeaId: string, _ideaId: string) => {},
    ) => {
      // If you're removing the 2nd to last idea from a merge, then we delete the merge container, as there'd be no point in having it
      const mergeIdea = ideas.find((i) => i._id === mergeIdeaId);
      const willDeleteMergeContainer = mergeIdea?.children?.length <= 2;
      util
        .confirm(
          `Are you sure you want to remove this ${t("generic.idea")} from the merge?`,
          `The ${t("generic.idea")} will not be deleted and will remain in the ${t("generic.challenge")}. You can re-add it to the merge at any time. ${willDeleteMergeContainer ? `The merge container will be deleted as it will only contain one ${t("generic.idea")}.` : ""}`,
        )
        .then(() => {
          setRemovingIdeaFromMerge(ideaId);
          api.ideas.removeIdeaFromMerge(
            mergeIdeaId,
            ideaId,
            () => {
              if (willDeleteMergeContainer) {
                // If we delete the merge container, we get the ID of the idea that wasn't deleted, and change the preview ID to that
                const nonDeletedIdeaId = mergeIdea?.children?.find((c) => c !== ideaId);
                if (nonDeletedIdeaId) {
                  setPreviewId(nonDeletedIdeaId);
                }
              }
              setRemovingIdeaFromMerge("");
              fetchIdeas();
              onComplete(mergeIdeaId, ideaId);
            },
            (err) => {
              toast.error(err.message);
              setRemovingIdeaFromMerge("");
              onError(mergeIdeaId, ideaId);
            },
          );
        });
    },
    [fetchIdeas, ideas, t],
  );

  const updateIdeaPosition = useCallback(
    (targetIdea: string, targetLane: string) => {
      api.boards.updateProjectIdea(
        challengeId,
        targetIdea,
        targetLane,
        0,
        ({ idea: updatedIdea }) => {
          setIdeaState((prevState) => ({
            ...prevState,
            ideas: prevState.ideas.map((idea) => {
              if (idea._id !== targetIdea) {
                return idea;
              }
              return {
                ...idea,
                projectManagement: updatedIdea.projectManagement,
              };
            }),
          }));
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [challengeId],
  );

  const updateIdeaPositionMulti = useCallback(
    (targetLane: string) => {
      const moveData = {
        targetLane,
        ideaIds: selectedIdeas,
      };
      api.boards.updateProjectIdeasMulti(
        challengeId,
        moveData,
        ({ updatedIdeas }) => {
          setIdeaState((prevState) => ({
            ...prevState,
            ideas: prevState.ideas.map((idea) => {
              if (!selectedIdeas.includes(idea._id)) {
                return idea;
              }
              const updatedIdea = updatedIdeas.find((i) => i._id === idea._id);
              return {
                ...idea,
                projectManagement: updatedIdea.projectManagement,
              };
            }),
          }));
          toast.success(
            `Updated lane for ${util.pluralise(updatedIdeas.length, t("generic.idea"), t("generic.ideas"), true)}`,
          );
          setSelectedIdeas([]);
        },
        (err) => {
          toast.error(`Unable to move ${t("generic.ideas")}. ${err.message}`);
        },
      );
    },
    [challengeId, selectedIdeas, t],
  );

  const updateIdeaProjectDeadlineMulti = useCallback(
    (deadline: Date) => {
      const formattedDeadline = moment(deadline, "YYYY-MM-DD").toDate();
      api.boards.updateProjectIdeasDeadline(
        challengeId,
        { deadline: formattedDeadline, ideaIds: selectedIdeas },
        ({ updatedIdeas }) => {
          setIdeaState((prevState) => ({
            ...prevState,
            ideas: prevState.ideas.map((idea) => {
              if (!updatedIdeas.map((i) => i._id).includes(idea._id)) {
                return idea;
              }
              const updatedIdea = updatedIdeas.find((i) => i._id === idea._id);
              return {
                ...idea,
                projectManagement: updatedIdea.projectManagement,
              };
            }),
          }));
          toast.success(
            `Updated deadline for ${util.pluralise(updatedIdeas.length, t("generic.idea"), t("generic.ideas"), true)}`,
          );
        },
        (err) => {
          toast.error(`Unable to update deadline. ${err.message}`);
        },
      );
    },
    [challengeId, selectedIdeas, t],
  );

  const bulkProjectAssign = useCallback(
    (userIds: string[]) => {
      api.boards.bulkAssignProjectIdeas(
        challengeId,
        {
          ideaIds: selectedIdeas,
          userIds,
        },
        ({ updatedIdeas, allAssignees }) => {
          setIdeaState((prevState) => ({
            ...prevState,
            ideas: prevState.ideas.map((idea) => {
              if (!selectedIdeas.includes(idea._id)) {
                return idea;
              }
              const updatedIdea = updatedIdeas.find((i) => i._id === idea._id);
              return {
                ...idea,
                projectManagement: updatedIdea.projectManagement,
              };
            }),
          }));
          setSelectedIdeas([]);
          setAllProjectAssignees(allAssignees);
          toast.success(
            `Assigned ${util.pluralise(userIds.length, "user", "users", true)} for ${util.pluralise(updatedIdeas.length, t("generic.idea"), t("generic.ideas"), true)}`,
          );
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [challengeId, selectedIdeas, t],
  );

  const RenderIdeaActions = useCallback(
    (ideaIds) => {
      const actionIdeas = ideas.filter((i) => ideaIds.includes(i._id));

      const isBulk = actionIdeas.length > 1;
      const usingSingleIdea = isBulk ? null : actionIdeas[0];

      // You can only merge ideas, where either:
      // - None of the ideas have been merged
      // - One of the ideas is a merge parent, and the rest of the selected ideas have not been merged/are not merge parents
      const canMerge =
        isBulk &&
        (actionIdeas.filter((i) => i.parent || i.children).length === 0 ||
          (actionIdeas.filter((i) => i.children).length === 1 &&
            actionIdeas.filter((i) => !(i.parent || i.children)).length === actionIdeas.length - 1));

      if (!challenge) return null;
      return (
        <ActionPopup>
          <Menu secondary vertical>
            {ideaViewType === "board" &&
            (canManageChallenge ||
              util.hasPermission(user, "challenge.moveProjectIdeas", challengeId) ||
              util.hasPermission(user, "challenge.editProjectAssignees", challengeId) ||
              util.hasPermission(user, "challenge.editProjectDates", challengeId)) ? (
              <>
                {canManageChallenge || util.hasPermission(user, "challenge.moveProjectIdeas", challengeId) ? (
                  <Dropdown item pointing="left" text={`Move all selected ${t("generic.ideas")} to project lane`}>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        content={challenge?.projectBoard?.defaultLane?.name ?? "Default"}
                        onClick={() => updateIdeaPositionMulti("default")}
                      />
                      {allChallengeProjectLanes.map((lane) => (
                        <Dropdown.Item
                          key={lane._id}
                          content={lane.name}
                          onClick={() => updateIdeaPositionMulti(lane._id)}
                        />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
                {canManageChallenge || util.hasPermission(user, "challenge.editProjectAssignees", challengeId) ? (
                  <Menu.Item
                    name={`Project assign users to all selected ${t("generic.ideas")}`}
                    onClick={() => setProjectAssignIdeas(ideaIds)}
                  />
                ) : null}
                {canManageChallenge || util.hasPermission(user, "challenge.editProjectDates", challengeId) ? (
                  <Dropdown item pointing="left" text="Set deadline" onClick={(e) => e.stopPropagation()}>
                    <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
                      <DateInput
                        clearable
                        inline
                        placeholder={`Set project deadline for all selected ${t("generic.ideas")}`}
                        dateFormat="YYYY-MM-DD"
                        iconPosition="left"
                        size="tiny"
                        value={moment().format("YYYY-MM-DD")}
                        onChange={(e, { value }) => updateIdeaProjectDeadlineMulti(value)}
                        closeOnMouseLeave={false}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
              </>
            ) : null}
            {canManageChallenge || util.hasPermission(user, "challenge.pinIdeas", challengeId) ? (
              <Popup
                on="hover"
                position="right center"
                content={<PinIdeaHelper challenge={challenge} />}
                trigger={
                  <Menu.Item
                    icon="pin"
                    name={isBulk ? "Pin" : usingSingleIdea?.isPinned ? "Unpin" : "Pin"}
                    onClick={() =>
                      !isBulk
                        ? pinIdea(usingSingleIdea._id, !usingSingleIdea?.isPinned)
                        : bulkUpdateStatus(challengeId, selectedIdeas, setIdeaState, "isPinned", true)
                    }
                  />
                }
              />
            ) : null}
            {canManageChallenge || util.hasPermission(user, "challenge.editIdeaDeadlines", challengeId) ? (
              <Menu.Item
                icon="calendar"
                name="Set deadline"
                onClick={() => {
                  setDeadlineIdeas(actionIdeas);
                }}
              />
            ) : null}
            {util.hasPermission(user, "org.addToGroupsDirectly", user?.ownerOrganisation?._id) ? (
              <Menu.Item
                icon="users"
                name="Add authors to group"
                onClick={() => {
                  setGroupAddIdeas(actionIdeas);
                }}
              />
            ) : null}
            {ideaMergeEnabled &&
            (canManageChallenge || util.hasPermission(user, "challenge.mergeIdeas", challengeId)) ? (
              <Popup
                on="hover"
                content={
                  canMerge
                    ? `Merge ${t("generic.ideas")} together`
                    : selectedIdeas.length > 1
                      ? `You can only merge ${t("generic.ideas")} where none of the previous ${t("generic.ideas")} have been merged`
                      : `Select at least two ${t("generic.ideas")} to merge`
                }
                trigger={
                  <Menu.Item
                    disabled={selectedIdeas.length < 2 || !canMerge}
                    icon="sitemap"
                    name="Merge"
                    onClick={() => {
                      setActionPopupOpen(false);
                      // @ts-ignore
                      confirmMerge(
                        ideas.filter((sIdea) => selectedIdeas.includes(sIdea._id)),
                        allChallengeProjectLanes,
                        challenge,
                      )
                        .then(() => {
                          setSelectedIdeas([]);
                          fetchIdeas();
                        })
                        .catch(() => {});
                    }}
                  />
                }
              />
            ) : null}
            {challenge?.ideaTemplateData?.assessmentEnabled &&
              (canManageChallenge || util.hasPermission(user, "challenge.editAssessments", challengeId)) && (
                <Menu.Item
                  icon="user plus"
                  name="Assign assessors"
                  onClick={() => {
                    setAssessmentAssignIdeas(ideaIds);
                  }}
                />
              )}
            {ideaViewType !== "board" &&
            (canManageChallenge || util.hasPermission(user, "challenge.moveIdeasToChallenge", challengeId)) ? (
              <>
                <Menu.Item
                  icon="arrow right"
                  name={`Move to another ${t("generic.challenge")}`}
                  onClick={() => {
                    setActionPopupOpen(false);
                    setChallengeChooserIdea(ideaIds);
                  }}
                />
                {!isBulk && usingSingleIdea?.challengeCreation && (
                  <Menu.Item
                    icon="target"
                    name={`Create ${t("generic.challenge")} from ${t("generic.idea")}`}
                    onClick={() => createChallengeFromIdea(usingSingleIdea._id)}
                  />
                )}
              </>
            ) : null}

            <IdeaPDFExport
              ideas={isBulk ? selectedIdeas.map((sId) => ({ _id: sId })) : [usingSingleIdea]}
              challenge={challenge as unknown as OpenAPI.Schemas["Challenge"]}
              trigger={<Menu.Item icon="pdf file outline" name="Export as PDF" />}
            />

            {!isBulk &&
            listView &&
            (canManageChallenge || util.hasPermission(user, "challenge.editIdeaLabels", challengeId)) ? (
              <Menu.Item
                icon="paint brush"
                name="Apply a label"
                onClick={() => setEmojiStampChooserOpen(usingSingleIdea._id)}
              />
            ) : null}
            {isBulk && <Menu.Item icon="ban" name="Deselect all" onClick={() => setSelectedIdeas([])} />}
            {canManageChallenge || util.hasPermission(user, "challenge.deleteIdeas", challengeId) ? (
              <Popup
                disabled={!(!isBulk && !!usingSingleIdea?.children)}
                content={`Delete this merged ${t("generic.idea")}. None of the contained ${t("generic.ideas")} will be deleted, and they will be returned to the ${t("generic.idea")} list.`}
                trigger={
                  <Menu.Item
                    icon="trash"
                    name={
                      !isBulk && !!usingSingleIdea?.children
                        ? `Delete merged ${t("generic.idea")}`
                        : `Delete ${t("generic.idea")}${!isBulk ? "" : "s"}`
                    }
                    onClick={() => {
                      setDeletingIdeas(actionIdeas);
                    }}
                  />
                }
              />
            ) : null}
          </Menu>
        </ActionPopup>
      );
    },
    [
      ideas,
      challenge,
      ideaViewType,
      allChallengeProjectLanes,
      ideaMergeEnabled,
      selectedIdeas,
      canManageChallenge,
      listView,
      user,
      updateIdeaPositionMulti,
      updateIdeaProjectDeadlineMulti,
      pinIdea,
      challengeId,
      fetchIdeas,
      createChallengeFromIdea,
      t,
      setActionPopupOpen,
    ],
  );

  const userId = user?._id;
  const onClosePreview = useCallback(
    (ideaId) => {
      const idea = ideas.find((i) => i._id === ideaId);
      // Set idea as viewed
      setIdeaState((prevState) => ({
        ...prevState,
        ideas: prevState.ideas.map((idea) => {
          if (idea._id !== ideaId) return idea;
          return {
            ...idea,
            currentUserHasViewed: true,
          };
        }),
      }));
      if (canManageChallenge || util.hasPermission(user, "challenge.viewProjectAssignees", challenge?._id)) {
        getIdeaAssignees();
      }
      const isAssessor = idea?.assessments?.find((a) => a.user === userId);
      if (
        idea &&
        (canManageChallenge || isAssessor || util.hasPermission(user, "challenge.viewAssessments", challenge?._id))
      ) {
        api.ideas.getAssessmentScore(
          ideaId,
          ({ assessments }) => {
            onUpdateIdea(ideaId, { assessments });
          },
          (err) => toast.error(err.message),
        );
      }
    },
    [ideas, canManageChallenge, userId, user, challenge?._id, getIdeaAssignees, onUpdateIdea],
  );

  const hasPinnedIdea = useMemo(() => ideas.some((i) => i.isPinned), [ideas]);

  const RenderIdeaCard = useCallback(
    (idea) => (
      // @ts-ignore
      <IdeaCard
        fetchIdeas={fetchIdeas}
        key={idea._id}
        idea={idea}
        invitations={invitations}
        ideasVotedFor={ideasVotedFor}
        challenge={challenge}
        addTag={(tag) => addTag(idea, tag)}
        unTag={(tag) => unTag(idea, tag)}
        voteForIdea={voteForIdea}
        listView={listView}
        labelIdea={labelIdea}
        onSelectIdea={onSelectIdea}
        selectedIdeas={selectedIdeas}
        ideaActions={RenderIdeaActions}
        onUpdateIdea={onUpdateIdea}
        removeAssessors={bulkRemoveAssessors}
        pinIdea={pinIdea}
        setPreviewOpen={openIdeaPreview}
        setOpenToComments={setOpenToComments}
        onRemoveExternalInvitation={onRemoveExternalInvitation}
        hasPinnedIdea={hasPinnedIdea}
        allChallengeProjectLanes={allChallengeProjectLanes}
        updateProjectLane={updateProjectLane}
        loading={loading}
        basicLoading={basicLoading}
      />
    ),
    [
      fetchIdeas,
      invitations,
      ideasVotedFor,
      challenge,
      voteForIdea,
      listView,
      labelIdea,
      onSelectIdea,
      selectedIdeas,
      RenderIdeaActions,
      onUpdateIdea,
      bulkRemoveAssessors,
      pinIdea,
      openIdeaPreview,
      onRemoveExternalInvitation,
      hasPinnedIdea,
      allChallengeProjectLanes,
      updateProjectLane,
      loading,
      basicLoading,
      addTag,
      unTag,
    ],
  );

  const usedVotes = parseInt(challenge?.voteLimit, 10) === ideasVotedFor;
  const votesRemaining = parseInt(challenge?.voteLimit, 10) - ideasVotedFor;
  const emojiIdea = ideas.find((i) => i._id === emojiStampChooserOpen);

  const isFullSize = !theme.sizes.isMobile;

  const activeIdeaViewType = useMemo(
    () => IdeaViewTypes.find((viewType) => viewType.key === ideaViewType),
    [ideaViewType],
  );

  const searchComponent = useMemo(
    () => (
      <Input
        size="small"
        placeholder={
          canManageChallenge || canViewProjectBoard
            ? `Filter ${t("generic.ideas")} by name, author, or project assignee...`
            : `Filter ${t("generic.idea")} by name or author...`
        }
        icon="search"
        value={ideaFilter}
        onChange={(e) => updateSearchTerm(e.target.value)}
        style={{ height: 33, minWidth: canManageChallenge ? 325 : 250 }}
        fluid={theme.sizes.isMobile}
      />
    ),
    [canManageChallenge, canViewProjectBoard, ideaFilter, theme.sizes.isMobile, updateSearchTerm, t],
  );

  const numberOfFiltersNotDefault = useMemo(
    () =>
      ChallengeFilterKeys.filter((key) => {
        // If we're on the board view, we don't want to count the ideaOrder filter as a filter since we hide the filter option and it can't be changed
        if (ideaViewType === "board" && key === "ideaOrder") {
          return false;
        }
        return !util.variablesAreEqual(challengesReducer[key], challengeReducerDefaultState[key]);
      }).length,
    [challengesReducer, ideaViewType],
  );

  return (
    <StyledIdeasContainer>
      <ProductTour
        tour="projectBoardOnIdeasPage"
        isReady={!!challenge && ideaViewType === "board" && window.location.pathname.indexOf("/ideas") > -1}
      />

      <ChallengeIdeaFilters
        // @ts-ignore
        challenge={challenge}
        popularTags={popularTags}
        allStamps={allStamps}
        availableGroups={availableGroups}
        availableOrganisations={availableOrganisations}
        availableSortParameters={availableSortParameters}
        availableFilterParameters={availableFilterParameters}
        allProjectAssignees={allProjectAssignees}
        availableImpacts={availableImpacts}
      />

      <IdeaDeletionModal
        ideasToDelete={deletingIdeas}
        setIdeasToDelete={setDeletingIdeas}
        setIdeaState={setIdeaState}
        setSelectedIdeas={setSelectedIdeas}
      />
      <IdeaDeadlineModal
        deadlineIdeas={deadlineIdeas}
        setDeadlineIdeas={setDeadlineIdeas}
        setIdeaState={setIdeaState}
        setSelectedIdeas={setSelectedIdeas}
      />
      <GroupUserManagementModal
        users={groupAddIdeas.flatMap((idea) => {
          const userIds = [];
          if (idea.user) {
            userIds.push(idea.user);
          }
          if (idea.collaborators) {
            userIds.push(...idea.collaborators);
          }
          return userIds;
        })}
        onClose={() => setGroupAddIdeas([])}
      />
      <IdeaPreview
        totalIdeas={total}
        currentIdeaPosition={ideas.findIndex((i) => i._id === previewId) + 1}
        openToComments={openToComments}
        ideaId={previewId}
        assessmentEnabled={challenge?.ideaTemplateData?.assessmentEnabled}
        closeModal={() => {
          setPreviewId("");
          onClosePreview(previewId);
        }}
        onUpdateIdea={onUpdateIdea}
        moveIdea={movePreviewIdea}
        canGoNextIdea={canGoNextIdea}
        canGoPrevIdea={canGoPrevIdea}
        removeIdeaFromMerge={removeIdeaFromMerge}
        removingIdeaFromMerge={removingIdeaFromMerge}
        isProjectBoard={ideaViewType === "board"}
        challenge={challenge}
        lanes={allChallengeProjectLanes}
        updateIdeaPosition={updateIdeaPosition}
      />
      <StyledModal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={!!emojiStampChooserOpen}
        onClose={() => setEmojiStampChooserOpen("")}
        basic
        closeIcon
      >
        <EmojiChooser
          onComplete={(em) => labelIdea(emojiIdea._id, em, true)}
          existing={emojiIdea?.stamps}
          onRemoveExisting={(em) => labelIdea(emojiIdea._id, em, false)}
          useNative={false}
        />
      </StyledModal>
      <UserChooser
        controlled
        isOpen={projectAssignIdeas.length > 0}
        onClose={() => setProjectAssignIdeas([])}
        onComplete={(users) => bulkProjectAssign(users.map((u) => u._id))}
        forType="projectAssignee"
        forId={challengeId}
        clearOnComplete
        enabledFeatures={{ search: true, invite: false }}
        instructions={`Choose multiple users to project assign to selected ${t("generic.idea")}.`}
        confirm="Assign these users"
        searchFunction={(term, cb, fail) =>
          api.boards.searchProjectAssignees(challengeId, term, ({ users }) => cb(users), fail)
        }
      />
      <UserChooser
        single={undefined}
        controlled
        isOpen={assessmentAssignIdeas.length > 0}
        onClose={() => setAssessmentAssignIdeas([])}
        onCompleteItems={assessmentAssignIdeas}
        onComplete={(users) => {
          // @ts-ignore
          const emailUsers = users.filter((u) => u.isEmailInvitee);
          // @ts-ignore
          const existingUsers = users.filter((u) => !u.isEmailInvitee);
          if (emailUsers.length) {
            api.invitations.createBulk(
              {
                invitees: emailUsers.map((u) => u._id),
                invitationType: "email",
                forType: "ideaAssessor",
                forId: challengeId,
                forIdeas: assessmentAssignIdeas,
              },
              ({ invitations: newInvitations }) => {
                setInvitations((prevInvitations) => [...prevInvitations, ...newInvitations]);
                toast.success(`Invitation${emailUsers.length > 1 ? "s" : ""} sent`);
              },
              () => {},
            );
            setIdeaState((prevState) => ({
              ...prevState,
              ideas: prevState.ideas.map((idea) => {
                if (assessmentAssignIdeas.indexOf(idea._id) === -1) return idea;
                return {
                  ...idea,
                  invitedExternalAssessors: [
                    ...(idea?.assessment?.invitedExternalAssessors || []),
                    ...emailUsers.filter((e) => (idea?.assessment?.invitedExternalAssessors || []).indexOf(e) === -1),
                  ],
                };
              }),
            }));
          }
          bulkUpdateAssessors(existingUsers, challengeId, assessmentAssignIdeas, ideas, setIdeaState);
        }}
        externalInvitesInAudience={challenge?.visibility?.organisations?.length > 0}
        forType="assessor"
        forId={challengeId}
        audienceWarningText={
          <span>
            The users below are currently outside of the {t("generic.challenge")} audience. Users not in the{" "}
            {t("generic.challenge")} audience will be unable to view the {t("generic.challenge")} and their assessments.
            To ensure assessors can access their assessments, add them to the{" "}
            <a href={`/challenges/${challengeId}/settings/audience`} target="_blank" rel="noreferrer">
              challenge audience
            </a>{" "}
            after making this assignment.
          </span>
        }
        clearOnComplete
        enabledFeatures={{ search: true, invite: true }}
        instructions={`Choose users to assign as assessors for ${assessmentAssignIdeas.length > 1 ? `the selected ${t("generic.ideas")}` : `the selected ${t("generic.idea")}`}. Assessors will be able to find the ${t("generic.ideas")} on their 'Assessments' tab within this ${t("generic.challenge")}.`}
        confirm="Assign these assessors"
        message={
          challenge?.ideaTemplateData?.assessmentClosed ? (
            <Message warning>
              <Message.Header>Assessment closed</Message.Header>
              <Message.Content>
                Assessment is now closed for this {t("generic.challenge")}. If you assign assessors, they will not be
                able to complete their assessment. You can change this{" "}
                <Link to={`/challenges/${challenge._id}/settings/format`}>here</Link>.
              </Message.Content>
            </Message>
          ) : null
        }
        searchFunction={(term, cb, fail) =>
          api.search.challengeAssessorsIncludeExisting(challengeId, term, ({ users }) => cb(users), fail)
        }
        trigger={undefined}
      />
      {challenge?._id && challengeChooserIdea?.length > 0 ? (
        // @ts-ignore
        <ChallengeChooser
          controlled
          trigger={undefined}
          isOpen={challengeChooserIdea.length > 0}
          onClose={() => setChallengeChooserIdea("")}
          onComplete={(c) => bulkMoveIdeas(challengeChooserIdea, c._id, () => setChallengeChooserIdea(""))}
          instructions={`We'll move the selected ${t("generic.idea")}(s) to another ${t("generic.challenge")} that you can manage.`}
          ignores={[challenge._id]}
          messageProps={{
            warning: true,
            content: (
              <>
                <p>
                  <Icon name="warning" />
                  <b>Caution</b>:
                  {`Moving ${t("generic.ideas")} to another ${t("generic.challenge")} will have the following side effects.`}
                </p>
                <ul>
                  <li>
                    The {t("generic.idea")} will be removed from the original {t("generic.challenge")}
                  </li>
                  <li>
                    The {t("generic.idea")} will keep the {t("generic.idea")} format of the original{" "}
                    {t("generic.challenge")}
                  </li>
                  <li>{t("common:capitalise", { key: "generic.idea" })} collaborators will be removed</li>
                  <li>Assessments will be removed</li>
                  <li>Project board assignees will be removed</li>
                  <li>Project board notes will be removed</li>
                </ul>
              </>
            ),
          }}
        />
      ) : null}
      {ideaViewType !== "spreadsheet" && ideaViewType !== "board" ? (
        <ChallengeMyIdeas
          myIdeas={myIdeas}
          style={{ marginBottom: 20 }}
          showMyIdeas={showMyIdeas}
          setShowMyIdeas={setShowMyIdeas}
        />
      ) : null}
      {user ? (
        <>
          <FilterContainer>
            <StyledFilters>
              {ideaViewType !== "board" ? (
                <>
                  {challenge?.voteLimit && challenge?.votingVisibility === "users" && (
                    <Label>
                      {!usedVotes && <Icon name={"thumbs up"} />} {util.pluralise(votesRemaining, "like", "likes")}{" "}
                      remaining
                    </Label>
                  )}
                </>
              ) : null}

              <Button
                primary
                icon="filter"
                size="tiny"
                content={`${theme.sizes.isLargeComputer ? (filtersVisible ? `Hide ${t("generic.idea")} filters` : `Show ${t("generic.idea")} filters`) : "Filters"}${numberOfFiltersNotDefault ? ` (${numberOfFiltersNotDefault})` : ""}`}
                onClick={() => updateFilters({ filtersVisible: !filtersVisible })}
              />

              {theme.sizes.isMobile ? null : searchComponent}

              {canManageChallenge ? (
                <React.Fragment>
                  {ideaViewType === "board" ? (
                    <Button
                      content={selectingMultipleCards ? "Exit selection mode" : "Select multiple cards"}
                      icon="grab"
                      size="tiny"
                      onClick={() => setSelectingMultipleCards((prev) => !prev)}
                      style={{ width: 180, marginRight: 0 }}
                    />
                  ) : (
                    <Button
                      size="tiny"
                      icon="check"
                      basic
                      content="Select all on page"
                      onClick={() =>
                        setSelectedIdeas((prevSelectedIdeas) =>
                          util.union(
                            prevSelectedIdeas,
                            ideas.map((i) => i._id),
                          ),
                        )
                      }
                    />
                  )}
                </React.Fragment>
              ) : null}

              {basicLoading ? <Loader active inline="centered" /> : null}

              {selectedIdeas && selectedIdeas.length > 0 && (
                <Popup
                  on="click"
                  open={actionPopupOpen}
                  onOpen={() => setActionPopupOpen(true)}
                  onClose={() => setActionPopupOpen(false)}
                  trigger={
                    <Button
                      primary
                      icon="dropdown"
                      size="tiny"
                      content={util.pluralise(selectedIdeas.length, t("generic.idea"), t("generic.ideas"))}
                    />
                  }
                  content={RenderIdeaActions(selectedIdeas)}
                  style={{ padding: 5 }}
                />
              )}

              {ideaViewType === "board" ? (
                <>
                  {canManageChallenge || util.hasPermission(user, "challenge.viewProjectBoard", challenge?._id) ? (
                    <BoardEvents
                      forId={challengeId}
                      setCurrentIdeaId={setPreviewId}
                      buttonStyle={{ height: 32.5, marginLeft: theme.sizes.isMobile ? 0 : 5 }}
                      buttonProps={{ size: "tiny" }}
                    />
                  ) : null}
                  {filter === "submitted" && theme.sizes.isComputer ? (
                    <span
                      style={{
                        color: "#666",
                        maxWidth: 400,
                        fontSize: 12,
                        lineHeight: "16px",
                      }}
                    >
                      Draft {t("generic.ideas")} are hidden by default in board view. You can change this in the
                      filters.
                    </span>
                  ) : null}
                </>
              ) : null}
            </StyledFilters>

            <StyledFilters>
              {(util.canManageChallenge(user, challenge) ||
                util.hasPermission(user, "challenge.editSettings", challengeId)) &&
              theme.sizes.isLargeComputer ? (
                <Link style={{ display: "block" }} to={`/challenges/${challenge?._id}/settings/ideas`}>
                  <Icon name="cogs" /> {t("generic.settings")}
                </Link>
              ) : null}
              <Popup
                on="hover"
                content={`Change ${t("generic.idea")} view`}
                trigger={
                  <Dropdown
                    icon={null}
                    direction="left"
                    trigger={
                      <Button
                        icon={activeIdeaViewType?.icon}
                        content={activeIdeaViewType?.text}
                        size="tiny"
                        style={{
                          flexWrap: "nowrap",
                          display: "flex",
                        }}
                        className="project-board-ideas-page-1"
                      />
                    }
                    options={[
                      !theme.sizes.isLargeComputer &&
                      (util.canManageChallenge(user, challenge) ||
                        util.hasPermission(user, "challenge.editSettings", challengeId))
                        ? {
                            key: "settings",
                            value: "settings",
                            as: Link,
                            to: `/challenges/${challenge?._id}/settings/ideas`,
                            icon: "cogs",
                            text: `View ${t("generic.idea")} settings`,
                          }
                        : null,
                      ...IdeaViewTypes.filter((viewType) => {
                        if (viewType.key === "board") {
                          return (
                            challenge?.projectManagementVisibility === "users" ||
                            canManageChallenge ||
                            util.hasPermission(user, "challenge.viewProjectBoard", challengeId)
                          );
                        }
                        if (viewType.key === "spreadsheet") {
                          return canManageChallenge || util.hasPermission(user, "challenge.viewSettings", challengeId);
                        }
                        return (
                          !viewType.requiredFeature ||
                          // @ts-ignore
                          user?.ownerOrganisation?.enabledFeatures?.includes(viewType.requiredFeature)
                        );
                      }).map(({ key, text, value, icon }) => ({
                        key,
                        value,
                        icon,
                        text: `${text} view`,
                      })),
                    ].filter((item) => Boolean(item))}
                    onChange={(e, { value }) => setIdeaViewType(value as string)}
                    selectOnBlur={false}
                  />
                }
              />
            </StyledFilters>
          </FilterContainer>

          {theme.sizes.isMobile ? <div style={{ marginBottom: 5, width: "100%" }}>{searchComponent}</div> : null}
        </>
      ) : null}

      {!user && (
        <>
          <Message>
            <h3>Please login to view this page</h3>
            <p>
              Register or login to your Simply Do account to track your own {t("generic.ideas")} or to view, comment,
              and like other {t("generic.ideas")} in this {t("generic.challenge")}.
            </p>
            <Divider section />
            <Button as={Link} to={`/login?then=/challenges/${challenge?._id}/ideas`} primary>
              Login or register
            </Button>
          </Message>
        </>
      )}
      {user &&
      (challenge?.ideaVisibility === "users" ||
        canManageChallenge ||
        util.hasPermission(user, "challenge.viewIdeas", challengeId)) ? (
        <StyledIdeaItemsContainer>
          {listView ? (
            <>
              <IdeaListContainer>
                <colgroup>
                  {/* @ts-ignore */}
                  {isFullSize && (canManageChallenge || canActionIdeas) ? <col width="40" height="55" /> : null}
                  {/* @ts-ignore */}
                  <col width="35" height="55" />
                  {coverImageEnabled && <col width="40" />}
                  {/* @ts-ignore */}
                  {isFullSize ? <col height="55" /> : null}
                  <col />
                  <col />
                </colgroup>
                <tbody>{ideas.map((idea) => RenderIdeaCard(idea))}</tbody>
              </IdeaListContainer>
            </>
          ) : null}
          {ideaViewType === "card" ? (
            <Card.Group itemsPerRow={4} doubling stackable style={{ marginBottom: 20, zIndex: 5 }}>
              {ideas.map((idea) => RenderIdeaCard(idea))}
            </Card.Group>
          ) : null}
          {ideaViewType === "board" ? (
            <ProjectBoard
              forId={challengeId}
              getBoard={(idea) => idea?.projectManagement?.boards?.filter((b) => b.forId === challengeId)[0]}
              canManageBoard={canManageChallenge}
              canViewBoard={
                canManageChallenge ||
                challenge?.projectManagementVisibility === "users" ||
                util.hasPermission(user, "challenge.viewProjectBoard", challenge?._id)
              }
              loading={loading}
              basicLoading={basicLoading}
              assessmentEnabled={challenge?.ideaTemplateData?.assessmentEnabled}
              defaultLane={challenge?.projectBoard?.defaultLane}
              updateDefaultLane={updateDefaultLane}
              ideas={ideas}
              setIdeas={(cb: (prevIdeas: Other.IIdea[]) => Other.IIdea[]) =>
                setIdeaState((prevState) => ({ ...prevState, ideas: cb(prevState.ideas) }))
              }
              totalIdeas={total}
              currentIdeaId={previewId}
              setCurrentIdeaId={setPreviewId}
              selectionMode={selectingMultipleCards ? "multi" : undefined}
              selectedIdeaIds={selectedIdeas}
              setSelectedIdeaIds={setSelectedIdeas}
              laneFilter={projectBoardLanesFilter}
            />
          ) : null}
          {ideaViewType === "spreadsheet" ? (
            <Suspense
              fallback={
                <div>
                  <Loader active inline="centered" />
                </div>
              }
            >
              <SpreadsheetIdeas
                challenge={challenge}
                ideas={ideas}
                loadingIdeas={loading}
                setIdeas={(newIdeas) => {
                  setIdeaState((prevState) => ({ ...prevState, ideas: newIdeas }));
                }}
                fetchIdeas={fetchIdeas}
                removeAssessors={bulkRemoveAssessors}
                onRemoveExternalInvitation={onRemoveExternalInvitation}
                selectedIdeaIds={selectedIdeas}
                setSelectedIdeaIds={setSelectedIdeas}
              />
            </Suspense>
          ) : null}
          {!basicLoading && ideas.length === 0 && ["board", "spreadsheet"].indexOf(ideaViewType) === -1 ? (
            <EmptyBox>
              <h2>{t("challenge.ideas.noneTitle")}</h2>
              {challenge?.stage === "published" && canMakeIdea && (
                <Button
                  primary
                  icon="plus"
                  content={challenge?.buttonText ? challenge.buttonText : t("challenge.ideas.new")}
                  onClick={() => newIdea()}
                />
              )}
            </EmptyBox>
          ) : null}
          {!basicLoading && ideas.length > 0 && ["board", "spreadsheet"].indexOf(ideaViewType) === -1 ? (
            <Pagination
              limit={limit}
              limitValues={listView ? [15, 30, 50, 100] : [16, 32, 52, 100]}
              page={page}
              total={total}
              updateFilters={updateFilters}
            />
          ) : null}
        </StyledIdeaItemsContainer>
      ) : null}
    </StyledIdeasContainer>
  );
};

export default Ideas;
