import React, { useState, useEffect, useCallback, SetStateAction, Dispatch } from "react";
import { Divider, Form, Button, Message, Icon, Popup } from "semantic-ui-react";
import util from "utils/utils";
import { Other } from "simplydo/interfaces";
import toast from "react-hot-toast";
import api from "api";
import RoleEditorIconChooser from "./IconChooser";
import { CheckboxHeader } from "components/lib/UI";

type RoleEditorPermissionsProps = {
  forType: "organisation" | "group" | "global" | "ideaBusinessProfile" | "challenge";
  setRoles: Dispatch<SetStateAction<Other.IRole[]>>;
  canSetRolesAsDefault: boolean;
  roleId: string;
  usingRole: Other.IRole | undefined;
  forId: string;
  roles: Other.IRole[];
};

const typeNames = {
  organisation: "organisation",
  group: "group",
  global: "global",
  ideaBusinessProfile: "business profile",
};

const RoleEditorPermissions = ({
  forType,
  setRoles,
  canSetRolesAsDefault,
  roleId,
  usingRole,
  forId,
  roles,
}: RoleEditorPermissionsProps) => {
  const [updatedRoleName, setUpdatedRoleName] = useState<string>("");
  // Setting default role state
  const [_settingDefaultRole, setSettingDefaultRole] = useState<string>("");
  useEffect(() => {
    setUpdatedRoleName(usingRole?.name ?? "");
  }, [usingRole?.name]);

  const updateRole = useCallback(
    (data: Partial<Other.IRole>) => {
      api.roles.updateRole(
        forType,
        forId,
        roleId,
        data,
        ({ role }) => {
          toast.success("Role updated");
          setRoles((prevRoles) => {
            const newRoles = [...prevRoles];
            const index = newRoles.findIndex((role) => role._id === roleId);
            newRoles[index] = role;
            return newRoles;
          });
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [forType, roleId, forId, setRoles],
  );

  const setRoleAsDefault = useCallback(
    (roleId: string) => {
      const usingRole = roles.find((role) => role._id === roleId);
      util
        .confirm(
          usingRole.isDefaultRole ? "Remove as default role" : "Add as default role",
          usingRole.isDefaultRole
            ? `Are you sure you want to remove "${usingRole?.name}" as a default role for new users? This will not affect existing users.`
            : `Are you sure you want to add "${usingRole?.name}" as a default role for new users? All users who join your ${typeNames[forType]} will be granted this role, please be mindful of the level of permissions granted. This will not affect existing users.`,
        )
        .then(() => {
          const apiFunc = usingRole.isDefaultRole ? api.roles.removeDefaultRole : api.roles.addDefaultRole;
          setSettingDefaultRole(roleId);
          apiFunc(
            forType,
            forId,
            roleId,
            () => {
              setSettingDefaultRole("");
              setRoles((prevRoles) =>
                prevRoles.map((role) => {
                  if (role._id === roleId) {
                    return {
                      ...role,
                      isDefaultRole: role.isDefaultRole ? false : true,
                    };
                  }
                  return role;
                }),
              );
            },
            (err) => {
              toast.error(err.message);
              setSettingDefaultRole("");
            },
          );
        })
        .catch(() => {});
    },
    [roles, forType, forId, setRoles],
  );

  const saveRoleName = useCallback(() => {
    updateRole({ name: updatedRoleName });
  }, [updateRole, updatedRoleName]);

  return (
    <>
      <Form>
        <Form.Input
          label="Role name"
          placeholder="Role name"
          value={updatedRoleName ?? usingRole?.name}
          onChange={(e) => setUpdatedRoleName(e.target.value)}
          fluid
          action={<Button primary content="Save" onClick={() => saveRoleName()} />}
        />
      </Form>
      <Divider hidden />
      <span>
        <b>Icon</b>
      </span>
      <span>
        Role icons are displayed next to users names across SimplyDo, if they have this role assigned to them.
      </span>
      <RoleEditorIconChooser containerStyle={{ margin: "10px 0 0" }} role={usingRole} updateRole={updateRole} />

      <Divider hidden />
      {usingRole?.preventUserEditing ? (
        <div>
          <Message info>
            <Icon name="info" />
            This role is a system role, its permissions cannot be edited. It is required for the system to function
            correctly.
          </Message>
          <Divider hidden />
        </div>
      ) : null}
      {canSetRolesAsDefault && !usingRole?.preventUserEditing ? (
        <>
          <Popup
            hoverable
            content="This role will be assigned to every user by default."
            trigger={
              <CheckboxHeader
                header="Set as default role"
                description={`Every user will be automatically assigned this role upon joining the ${typeNames[forType]}.`}
                as="h5"
                checked={usingRole?.isDefaultRole}
                onChange={() => setRoleAsDefault(roleId)}
              />
            }
          />
        </>
      ) : null}
    </>
  );
};

export default RoleEditorPermissions;
