import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Loader, Icon, Popup, Form, Input, Button } from "semantic-ui-react";
import TextareaAutosize from "react-autosize-textarea";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import moment from "moment";
import actions from "actions";
import api from "api";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import websocketApi from "api/websocket";
import util from "utils/utils";
import uuid from "uuid";
import { useTranslation } from "react-i18next";

import ChatSettings from "./ChatSettings";
import { UserChip } from "../Chips";
import FormattedComment from "../FormattedComment";

const eventTypes = [
  {
    type: "participantAdded",
    message: "was added to the chat",
  },
  {
    type: "participantRemoved",
    message: "was removed from the chat",
  },
  {
    type: "participantLeft",
    message: "left the chat",
  },
  {
    type: "ownerChanged",
    message: "was made the owner of the chat",
  },
  {
    type: "nameChanged",
    message: "renamed the chat",
  },
];

const MessageMoleDiv = styled.div`
  position: fixed;
  bottom: 0;
  background: white;
  z-index: 500;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex-direction: column;
  right: ${({ theme }) => (theme.sizes.isMobile ? 0 : 100)}px;
  height: ${({ $minimised }) => ($minimised ? 40 : 400)}px;
  width: ${({ theme }) => (theme.sizes.isMobile ? "100%" : "500px")};
`;

const NameHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  color: white;
  border-radius: 4px 4px 0px 0px;
  padding: 5px 5px 5px 5px;
  font-size: 14px;
  background-color: ${({ theme, $light }) => util.adjustColour(theme.primaryColour, $light ? 0.1 : 0)};
`;

const Messages = styled.div`
  flex: 1;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  background-color: ${({ $isYou, theme }) => ($isYou ? theme.primaryColour : "rgb(230,230,230)")};
  color: ${({ $isYou }) => ($isYou ? "white" : "black")};
  text-align: ${({ $isYou }) => ($isYou ? "right" : "left")};
  align-self: ${({ $isYou }) => ($isYou ? "flex-end" : "flex-start")};
  font-size: 16px;
  border-radius: 10px;
  margin: ${({ $isYou }) => ($isYou ? "5px" : "0 5px 5px 5px")};
  padding: 5px 10px 5px 10px;
  max-width: 80%;
  word-break: break-word;
  white-space: pre-line;
`;

const MessageMeta = styled.div`
  color: gray;
  font-size: 14px;
  margin: 0 5px;
`;

const MessageEvent = styled.div`
  width: 100%;
  text-align: center;
  color: gray;
  font-size: 11px;
  margin: 5px 0;
`;

const MessageInputContainer = styled.div`
  padding: 8px;
  background: white;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9ebee;
`;

const MessageInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const SafariSpacer = styled.div`
  /* Safari specific to respect bottom navigation */
  @supports (-webkit-touch-callout: none) {
    height: 50px;
  }
`;

function MessageMole({
  user,
  thread = {},
  markAsSeen,
  onMessageThreadSelected,
  closeThread,
  index,
  totalThreads,
  onThreadCreated,
}) {
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [minimised, setMinimised] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputOpen, setInputOpen] = useState(false);
  const [newChatName, setNewChatName] = useState("");
  const [settingsOpen, setSettingsOpen] = useState(false);
  const lastMessagesRef = useRef(null);
  const nameInputRef = useRef(null);
  const messageSubscription = useRef(null);
  const threadId = thread?._id;
  const threadName = thread?.name;
  const userId = user?._id;
  const isNewThread = threadId && threadId.indexOf("newThread") === 0;
  const events = useMemo(() => thread?.events || [], [thread]);
  const theme = useTheme();
  const { t } = useTranslation();

  const scrollToLastMessage = useCallback(() => {
    if (lastMessagesRef?.current) {
      setTimeout(() => lastMessagesRef.current.scrollIntoView(), 50);
    }
  }, []);

  const getMessages = useCallback(() => {
    if (!threadId) return;
    setLoading(true);
    setMessages([]);
    api.messages.getThread(
      threadId,
      ({ messages: newMessages, newMessageCount }) => {
        setMessages(newMessages.reverse());
        markAsSeen([threadId], false, userId, newMessageCount);
        setLoading(false);
        scrollToLastMessage();
      },
      () => setLoading(false),
    );
  }, [threadId, userId, markAsSeen, scrollToLastMessage]);

  const handleNewMessage = useCallback(
    (threadId) => {
      api.messages.getThread(threadId, ({ messages: newMessages, newMessageCount }) => {
        markAsSeen([threadId], false, userId, newMessageCount);
        setMessages(newMessages.reverse());
        scrollToLastMessage();
      });
    },
    [userId, markAsSeen, scrollToLastMessage],
  );

  useEffect(() => {
    if (!messageSubscription.current) {
      messageSubscription.current = websocketApi.subscribe(`newMessage-${userId}`, (data) => {
        const { thread: receivedThread } = data;
        handleNewMessage(receivedThread);
      });
    }
  }, [handleNewMessage, userId]);

  useEffect(() => {
    return () => {
      if (messageSubscription.current) {
        messageSubscription.current.unsubscribe();
        messageSubscription.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (threadId) {
      setMinimised(false);
      if (threadId.indexOf("newThread") === -1) getMessages();
      else {
        api.messages.findThreadByUser(
          threadId.replace("newThread:", ""),
          (data) => {
            if (data) onMessageThreadSelected(data.thread);
            else setMessages([]);
          },
          (err) => toast.error(err.message),
        );
      }
    }
  }, [threadId, getMessages, onMessageThreadSelected]);

  useEffect(() => {
    setNewChatName(threadName || "");
  }, [threadName]);

  useEffect(() => {
    if (inputOpen) nameInputRef.current.focus();
  }, [inputOpen]);

  const sendMessage = useCallback(() => {
    setLoading(true);

    const messageId = uuid.v4();

    const dummyMessage = {
      _id: messageId,
      createdAt: new Date(),
      from: user._id,
      messageThread: thread._id,
      content: newMessage,
    };

    // Immediately add new message to local ui
    const newMessages = Object.assign([], messages);
    newMessages.push(dummyMessage);
    setMessages(newMessages);
    setNewMessage("");

    api.messages.send(
      thread._id,
      newMessage,
      () => {
        setLoading(false);
        scrollToLastMessage();
      },
      (err) => {
        // Reset messages to previous state if error occurs
        setMessages(messages);
        setNewMessage(newMessage);
        toast.error(err.message);
        setLoading(false);
      },
    );
  }, [setLoading, messages, setMessages, setNewMessage, scrollToLastMessage, newMessage, thread._id, user._id]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && (e.ctrlKey || e.shiftKey)) {
      // Do not send enter to text box
      e.preventDefault();
      if (isNewThread) {
        createThread();
      } else {
        sendMessage();
      }
    }
  };

  const createThread = useCallback(
    (onCreate = () => {}, onFail = () => {}) => {
      setLoading(true);
      const newUserId = threadId.replace("newThread:", "");
      api.messages.createThread(
        { users: [newUserId], message: newMessage },
        (newThread) => {
          setLoading(false);
          onThreadCreated(threadId, newThread);
          setNewMessage("");
          onCreate(newThread);
        },
        () => {
          setLoading(false);
          onFail();
        },
      );
    },
    [newMessage, threadId, onThreadCreated],
  );

  const renameChat = useCallback(() => {
    setInputOpen(false);
    api.messages.updateThread(
      thread._id,
      { name: newChatName },
      (newThread) => {
        onMessageThreadSelected(newThread);
      },
      (err) => toast.error(err.message),
    );
  }, [setInputOpen, newChatName, thread, onMessageThreadSelected]);

  const eventsBeforeFirstMessage = useCallback(() => {
    const isMessages = messages.length > 0;
    const firstMessage = messages[0] || null;
    const firstMessageTime = firstMessage ? moment(firstMessage.createdAt) : null;
    return events.filter((e) => {
      if (!isMessages) return true;
      return moment(e.createdAt).isBefore(firstMessageTime);
    });
  }, [messages, events]);

  const hasMoreThanTwoParticipants = thread?.participantUsers?.length && thread?.participantUsers?.length > 2;
  const otherUsers = (thread.participantUsers ?? []).filter((p) => p._id !== user._id);
  const someoneOnline = otherUsers.find((u) => u.isOnline);

  const fromRight = (totalThreads - index) * 50 + (totalThreads - 1 - index) * 400;
  const rightMargin = 30;
  const messageWidth = 450;
  return (
    <MessageMoleDiv
      style={{
        right: theme.sizes.isMobile ? 0 : fromRight + rightMargin,
        height: minimised ? 40 : 475,
        width: theme.sizes.isMobile ? "100%" : messageWidth,
        marginRight: theme.sizes.isMobile ? 0 : 20,
      }}
    >
      <ChatSettings
        thread={thread}
        modalOpen={settingsOpen}
        closeModal={() => setSettingsOpen(false)}
        closeThread={closeThread}
      />
      <NameHeader $light>
        <div style={{ flex: 0.8, cursor: "text", maxWidth: "75%" }}>
          {inputOpen ? (
            <Input
              tabIndex={0}
              placeholder="Enter chat name..."
              value={newChatName}
              onChange={(e) => setNewChatName(e?.target?.value || "")}
              onBlur={renameChat}
              size="miny"
              style={{ height: 30 }}
              ref={nameInputRef}
            />
          ) : (
            <>
              {hasMoreThanTwoParticipants || threadName ? (
                <span onClick={() => setInputOpen(true)} style={{ marginLeft: 5, fontWeight: "bold" }}>
                  {util.getChatName(user, thread)}
                </span>
              ) : (
                <UserChip compact user={otherUsers[0]} />
              )}
            </>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {someoneOnline && (
            <Popup
              basic
              trigger={<Icon size="small" style={{ marginRight: 5 }} name="circle" color="green" />}
              content={
                <div>
                  {hasMoreThanTwoParticipants
                    ? "Some users are or were recently online"
                    : "User is or was recently online"}
                </div>
              }
            />
          )}
          {!minimised && (
            <Button
              compact
              inverted
              basic
              size="mini"
              icon="settings"
              style={{ marginRight: 5 }}
              onClick={() => setSettingsOpen(true)}
            />
          )}
          {minimised ? (
            <Button
              compact
              inverted
              basic
              size="mini"
              icon="window maximize outline"
              onClick={() => {
                setMinimised(false);
              }}
            />
          ) : (
            <Button compact inverted basic size="mini" icon="window minimize" onClick={() => setMinimised(true)} />
          )}
          <Button compact inverted basic size="mini" icon="close" onClick={() => closeThread(threadId)} />
        </div>
      </NameHeader>
      {!minimised && !loading && !messages.length && (
        <div style={{ textAlign: "center", margin: 5 }}>
          {thread?.forType === "idea"
            ? `This is a private conversation between ${t("generic.idea")} team members. Current participants: ${thread?.participantUsers ? thread.participantUsers.map((u) => `${u.profile.fullName}`).join(", ") : "Unknown"}. `
            : null}
          Be the first to message!
        </div>
      )}
      <Messages style={{ overflowY: "scroll" }}>
        {loading && <Loader active />}
        {eventsBeforeFirstMessage().map((e, messageIndex) => {
          const eventType = eventTypes.find((t) => t.type === e.type);
          if (e.participantUser) {
            return (
              <MessageContainer key={messageIndex}>
                <MessageEvent>
                  {e.participantUser.profile.fullName} {eventType.message}{" "}
                </MessageEvent>
              </MessageContainer>
            );
          }
          return null;
        })}
        {messages &&
          messages.map((m, mIndex) => {
            const isYou = user && m.from === user._id;
            const messageUser = thread?.allUsers && thread.allUsers.find((u) => u._id === m.from);
            const nextMessage = mIndex !== messages.length - 1 ? messages[mIndex + 1] : {};
            const isLastMessage = mIndex === messages.length - 1;
            const eventsAfterCurrentMessage = events.filter((e) => {
              if (e.createdAt && m.createdAt) {
                const eventTime = moment(e.createdAt);
                const messageTime = moment(m.createdAt);
                const nextMessageTime = nextMessage.createdAt ? moment(nextMessage.createdAt) : null;
                return (eventTime.isBefore(nextMessageTime) || !nextMessageTime) && eventTime.isAfter(messageTime);
              }
              return null;
            });

            const prevDay = mIndex === 0 ? null : moment(messages[mIndex - 1].createdAt).format("DD/MM/YYYY");
            const currentDay = moment(m.createdAt).format("DD/MM/YYYY");
            const showDay = prevDay !== currentDay;

            const isToday = moment(m.createdAt).isSame(moment(), "day");
            const isYesterday = moment(m.createdAt).isSame(moment().subtract(1, "day"), "day");
            return (
              <MessageContainer
                key={m._id}
                ref={(ref) => {
                  if (ref && isLastMessage) {
                    if (!lastMessagesRef.current) {
                      ref.scrollIntoView();
                    }
                    lastMessagesRef.current = ref;
                  }
                }}
              >
                {showDay ? (
                  <MessageEvent style={{ textAlign: "center" }}>
                    {isToday ? "Today" : isYesterday ? "Yesterday" : moment(m.createdAt).format("DD/MM/YYYY")}
                  </MessageEvent>
                ) : null}
                {!isYou &&
                  messageUser &&
                  (thread?.participantUsers.length > 2 || messageUser._id !== otherUsers[0]._id) && (
                    <MessageMeta>{messageUser.profile.firstName}</MessageMeta>
                  )}
                <Message $isYou={isYou}>
                  <FormattedComment>{m.content}</FormattedComment>
                  {m.createdAt ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: isYou ? "flex-end" : "flex-start",
                        fontSize: 11,
                        color: isYou ? "#ddd" : "#aaa",
                        marginRight: -5,
                        marginBottom: -5,
                      }}
                    >
                      {moment(m.createdAt).format("HH:mm")}
                    </div>
                  ) : null}
                </Message>
                {eventsAfterCurrentMessage.map((e) => {
                  const eventType = eventTypes.find((t) => t.type === e.type);
                  if (e.participantUser) {
                    return (
                      <MessageEvent>
                        {e.participantUser.profile.fullName} {eventType.message}{" "}
                      </MessageEvent>
                    );
                  }
                  return null;
                })}
                <div style={{ clear: "both" }} />
              </MessageContainer>
            );
          })}
      </Messages>
      {!minimised && (
        <MessageInputContainer>
          <MessageInput>
            <Form style={{ margin: "0 2px 0 0", width: "100%" }}>
              <TextareaAutosize
                rows={1}
                maxRows={4}
                onKeyPress={handleKeyPress}
                placeholder="Write a new message... Shift+Enter to send"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                style={{
                  border: "none",
                  padding: 0,
                  fontSize: 16,
                  width: "100%",
                }}
              />
            </Form>
            <Button
              size="medium"
              secondary
              compact
              disabled={!newMessage}
              icon="paper plane"
              onClick={() => (isNewThread ? createThread() : sendMessage())}
            />
          </MessageInput>
          {focused ? <SafariSpacer /> : null}
        </MessageInputContainer>
      )}
    </MessageMoleDiv>
  );
}

const mapStateToProps = (state) => ({ user: state.user, messageThreads: state.messages.messageThreads });
const mapDispatchToProps = (dispatch) => ({
  onMessageThreadSelected: (thread) => dispatch(actions.messages.selectThread(thread)),
  closeThread: (threadId) => dispatch(actions.messages.closeMessageThread(threadId)),
  markAsSeen: (messageThreads, markAllAsSeen, userId, newMessageCount) =>
    dispatch(actions.messages.markMessageThreadsAsSeen(messageThreads, markAllAsSeen, userId, newMessageCount)),
  onThreadCreated: (newThreadId, thread) => dispatch(actions.messages.onThreadCreated(newThreadId, thread)),
});
const MessageMoleContainer = connect(mapStateToProps, mapDispatchToProps)(MessageMole);

export default MessageMoleContainer;
