export const polls = (api) => ({
  get(id, success, fail) {
    api.maybeAuthenticatedRequest("GET", `/polls/${id}`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/polls/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest("DELETE", `/polls/${id}`, null, success, fail);
  },
  submit(id, responses, success, fail) {
    api.maybeAuthenticatedRequest("POST", `/polls/${id}/responses`, responses, success, fail);
  },
  getResponses(id, format, success, fail) {
    api.authenticatedRequest("GET", `/polls/${id}/responses${format ? `?format=${format}` : ""}`, null, success, fail);
  },
  deleteResponses(id, success, fail) {
    api.authenticatedRequest("DELETE", `/polls/${id}/responses`, null, success, fail);
  },
  getConnections(id, success, fail) {
    api.authenticatedRequest("GET", `/polls/${id}/connections`, null, success, fail);
  },
  connectExcel(id, success, fail) {
    api.authenticatedRequest("PUT", `/polls/${id}/connect/excel`, null, success, fail);
  },
  disconnectExcel(id, success, fail) {
    api.authenticatedRequest("DELETE", `/polls/${id}/connect/excel`, null, success, fail);
  },
  storeReport(reportId, success, fail) {
    api.authenticatedRequest("PUT", `/polls/reports/${reportId}/storage/onedrive`, { type: "onedrive" }, success, fail);
  },
  duplicate(id, organisationId, success, fail) {
    api.authenticatedRequest("POST", `/polls/${id}/duplicate`, { organisationId }, success, fail);
  },
  subscribeToResponses(id, userIds, success, fail) {
    api.authenticatedRequest("POST", `/polls/${id}/responses/subscribe`, { userIds }, success, fail);
  },
  unsubscribeFromResponses(id, userIds, success, fail) {
    api.authenticatedRequest("DELETE", `/polls/${id}/responses/subscribe`, { userIds }, success, fail);
  },
});
