import { OpenAPI } from "simplydo/interfaces";
import util from "utils/utils";
import { Button, Icon, Message, Segment } from "semantic-ui-react";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store";
import api, { asMutation } from "api";
import toast from "react-hot-toast";
import actions from "actions";
import { useMutation } from "@tanstack/react-query";

type SettingsProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  closeModal: () => void;
  onDelete?: (ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
};

export const Settings = ({ businessProfile, onDelete, closeModal }: SettingsProps) => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const deleteProfileMutation = useMutation({
    mutationKey: ["delete", "users", "profile", businessProfile._id],
    mutationFn: asMutation(api.users.deleteProfile),
    onSuccess: ({ success, reason }) => {
      // Attempt to delete the profile.
      // If the profile is attached to another idea, we alert the user, and don't continue with this action
      if (!success) {
        toast(reason);
      } else {
        api.queryClient.invalidateQueries({
          queryKey: ["get", "users", "profiles"],
        });
        dispatch(actions.user.updatePermissions(businessProfile._id, []));
        closeModal();
        onDelete?.(businessProfile);
      }
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const deleteProfile = useCallback(() => {
    util
      .confirm(
        `Really delete the organisation profile ${businessProfile.name}?`,
        `Deleting the organisation profile ${businessProfile.name} is permanent and cannot be undone.`,
      )
      .then(() => {
        deleteProfileMutation.mutate({
          params: [businessProfile._id],
        });
      })
      .catch(() => {});
  }, [businessProfile, deleteProfileMutation]);

  return (
    <div
      style={{
        display: "flex",
        gap: 12,
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Segment>
        <h3>Settings</h3>
        Delete this profile if it is no longer needed.
        <Message warning>
          Deleting this profile will not remove it from any ideas. Once deleted, the profile cannot be edited or
          recovered.
        </Message>
        <Button
          disabled={
            deleteProfileMutation.isError ||
            !util.hasPermission(user, "ideaBusinessProfile.deleteProfile", businessProfile._id)
          }
          inverted
          onClick={() => {
            deleteProfile();
          }}
          color="red"
        >
          <Icon name="trash" />
          Delete profile
        </Button>
      </Segment>
    </div>
  );
};
