import { Button, Checkbox, Form, Modal, Segment, Label, Message, Table } from "semantic-ui-react";
import Calendar, { CellAction } from "./Calendar";
import { DateInput, DateTimeInput } from "components/lib/DateInputs";
import { useCallback, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import api from "api";
import { useDispatch } from "react-redux";
import actions from "actions";
import toast from "react-hot-toast";
import { PlaceholderItem, PlaceholderStack, TooltipButton } from "components/lib/UI";
import { ColourChooser, UserChooser } from "components/lib/Choosers";
import styled from "styled-components";
import moment from "moment";
import RichText from "components/lib/Editors/RichText";
import { useAppSelector } from "store";
import util from "utils/utils";
import TimezoneExplainer from "components/lib/TimezoneExplainer";
import StandardAnnouncementModal from "components/lib/AnnouncementManager/Announcements/StandardAnnouncementModal";
import { challengeStandardConditions } from "components/lib/AnnouncementManager/conditions";

import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { UserChip } from "components/lib/Chips";

const SubmissionInputDiv = styled.div`
  display: ${({ theme }) => (theme.sizes.isMobile ? "block" : "flex")};
  flex-direction: row;
  align-items: center;
`;

const ColorPickerField = styled(Form.Field)`
  .pcr-button {
    width: 38px;
    height: 38px;
  }
`;

const Planner = ({ challenge, calendar, isLoading, setCalendar, getCalendar }) => {
  const [editingEvent, setEditingEvent] = useState(null);
  const dispatch = useDispatch();
  const [eventFilter, setEventFilter] = useState("all");

  const updateChallengeState = useCallback(
    (id, challengeData) => dispatch(actions.challenges.update(id, challengeData)),
    [dispatch],
  );
  const params = useParams();
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user);
  const canPublishChallenges = useMemo(
    () => util.hasPermission(user, "org.publishChallenges", user?.ownerOrganisation?._id),
    [user],
  );
  const canManageChallenge = useMemo(() => util.canManageChallenge(user, challenge), [user, challenge]);

  const challengeId = params.id;

  const setEventContent = useCallback((contentOrCallback) => {
    if (typeof contentOrCallback === "function") {
      setEditingEvent((prev) => ({ ...prev, eventContent: contentOrCallback(prev.eventContent) }));
    } else {
      setEditingEvent((prev) => ({ ...prev, eventContent: contentOrCallback }));
    }
  }, []);

  const createEvent = useCallback(() => {
    api.challenges.createCalendarEvent(
      challengeId,
      { ...editingEvent, id: uuidv4() },
      ({ events }) => {
        setEditingEvent(null);
        setCalendar((prev) => ({ ...prev, events }));
      },
      (error) => {
        toast.error(error.message);
      },
    );
  }, [challengeId, editingEvent, setCalendar]);

  const updateEvent = useCallback(() => {
    if (editingEvent.from) {
      editingEvent.from = moment(editingEvent.from).toDate();
    }
    if (editingEvent.to) {
      editingEvent.to = moment(editingEvent.to).toDate();
    }
    if (editingEvent.type !== "event") {
      // Remove the to key from creating event
      editingEvent.to = null;
    }

    api.challenges.updateCalendarEvent(
      challengeId,
      editingEvent.id,
      editingEvent,
      ({ events }) => {
        setEditingEvent(null);
        setCalendar((prev) => ({ ...prev, events }));
      },
      (error) => {
        toast.error(error.message);
      },
    );
  }, [challengeId, editingEvent, setCalendar]);

  const handleClickCreateEvent = useCallback(() => {
    if (editingEvent?.id) {
      updateEvent();
    } else {
      createEvent();
    }
  }, [editingEvent, createEvent, updateEvent]);

  const updateEventField = useCallback((field, value) => {
    setEditingEvent((prev) => ({ ...prev, [field]: value }));
  }, []);

  const updateRichtext = useCallback(
    (value, _text, fieldName) => {
      updateEventField(fieldName, value);
    },
    [updateEventField],
  );

  const deleteAnnouncement = useCallback(
    (announcementId) => {
      api.announcements.delete(
        "challenge",
        challengeId,
        announcementId,
        () => {
          getCalendar();
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [challengeId, getCalendar],
  );

  const updateOpenWithComms = useCallback(
    (openWithComms) => {
      api.challenges.update(
        challengeId,
        { openWithComms },
        () => {
          updateChallengeState(challengeId, { openWithComms: openWithComms });
        },
        () => {
          toast.error("Failed to save default preferences");
        },
      );
    },
    [challengeId, updateChallengeState],
  );

  const updateChallenge = useCallback(
    (update) => {
      api.challenges.update(
        challengeId,
        update,
        () => {
          updateChallengeState(challengeId, update);
          getCalendar();
        },
        () => {
          toast.error("Failed to save default preferences");
        },
      );
    },
    [challengeId, updateChallengeState, getCalendar],
  );

  const setNewOpenDate = useCallback(
    (enable, openDate) => {
      updateChallenge({ openDate: enable ? moment(openDate).toDate() : null });
    },
    [updateChallenge],
  );

  const setNewDeadline = useCallback(
    (enable, deadline) => {
      updateChallenge({ deadline: enable ? moment(deadline).toDate() : null });
    },
    [updateChallenge],
  );

  const deleteEvent = useCallback(
    (eventId, eventType) => {
      util
        .confirm("Delete event", "Are you sure you want to delete this event?")
        .then(() => {
          if (eventType === "announcement") {
            deleteAnnouncement(eventId);
          } else if (eventType === "open") {
            setNewOpenDate(false, null);
          } else if (eventType === "deadline") {
            setNewDeadline(false, null);
          } else {
            api.challenges.deleteCalendarEvent(
              challengeId,
              eventId,
              ({ events }) => {
                setCalendar((prev) => ({ ...prev, events }));
              },
              (error) => {
                toast.error(error.message);
              },
            );
          }
        })
        .catch(() => {});
    },
    [challengeId, deleteAnnouncement, setNewDeadline, setNewOpenDate, setCalendar],
  );

  const cellActions = useMemo(() => {
    const actions: CellAction[] = [
      {
        name: "Add a reminder",
        action: (dayDate) => ({
          type: "reminder",
          from: dayDate,
          color: "rgba(126, 79, 211, 1)",
        }),
      },
      {
        name: "Add a meeting",
        action: (dayDate) => ({ type: "meeting", from: dayDate, color: "rgba(237, 153, 46, 1)" }),
      },
      {
        name: "Schedule an announcement",
        onlyVisibleInRange: true,
        action: (dayDate) => ({
          type: "announcement",
          from: dayDate,
          eventContent: { sendAt: moment(dayDate).hour(9).minute(0).toISOString() },
        }),
      },
    ];

    if (challenge?.stage !== "published") {
      actions.push({
        name: "Set open date",
        action: (dayDate) => ({ type: "open", from: dayDate }),
      });
    }

    actions.push({
      name: "Set deadline",
      action: (dayDate) => ({ type: "deadline", from: dayDate }),
      onlyVisibleInRange: true,
    });

    return actions;
  }, [challenge]);

  const from = useMemo(() => (calendar?.start ? new Date(calendar.start) : new Date()), [calendar]);
  const nextMonth = useMemo(() => new Date(from), [from]);
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  const to = useMemo(() => (calendar?.end ? new Date(calendar.end) : nextMonth), [calendar, nextMonth]);

  const handleCloseModal = () => {
    setEditingEvent(null);
  };

  const openDateIssue = useMemo(() => {
    return challenge?.openDate < calendar?.start || challenge?.openDate > calendar?.end;
  }, [challenge, calendar?.start, calendar?.end]);

  const closeDateIssue = useMemo(() => {
    return challenge?.deadline < calendar?.start || challenge?.deadline > calendar?.end;
  }, [challenge, calendar?.start, calendar?.end]);

  const filteredEvents = (calendar?.events || []).filter((event) => {
    // Do not filter out background events for now as they should probably always display
    if (event.eventType === "background") {
      return true;
    }
    if (eventFilter === "member") {
      if (!event.members) {
        return false;
      }
      return event.members.includes(user?._id);
    }
    return true;
  });

  return (
    <div>
      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={editingEvent?.type === "event"}>
        <Modal.Header>Event</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field>
                <label>Name:</label>
                <input
                  placeholder="Event name"
                  style={{ minWidth: 300 }}
                  value={editingEvent?.title}
                  onChange={(e) => setEditingEvent((prev) => ({ ...prev, title: e.target.value }))}
                />
                {(editingEvent?.title || "").length > 100 && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    We recommend keeping the title under 100 characters.
                  </span>
                )}
              </Form.Field>
              <ColorPickerField>
                <label>Color:</label>
                <ColourChooser
                  value={editingEvent?.color}
                  onChange={(color) => setEditingEvent((prev) => ({ ...prev, color }))}
                />
              </ColorPickerField>
            </Form.Group>
            <Form.Field>
              <label>Description:</label>
              <RichText
                value={editingEvent?.description || ""}
                emptyReturnValue={""}
                placeholder="Describe this event..."
                onChange={updateRichtext}
                extraData="description"
                forType="challenge"
                forId={challengeId}
              />
            </Form.Field>
            <Form.Field>
              <label>Members:</label>
              <UserChooser
                trigger={<Button icon="add" size="small" secondary content="Add a member" />}
                enabledFeatures={{ search: true }}
                selectedUsers={editingEvent?.ownerMembers || []}
                onComplete={(members) =>
                  setEditingEvent((prev) => ({ ...prev, members: members.map((m) => m._id), ownerMembers: members }))
                }
                searchFunction={(search, success, error) => {
                  api.search.omni(
                    { query: search, include: "users" },
                    ({ users }) => {
                      success(users);
                    },
                    error,
                  );
                }}
              />
              {editingEvent?.ownerMembers?.length > 0 ? (
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>User</Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {editingEvent.ownerMembers.map((member) => (
                      <Table.Row key={member._id}>
                        <Table.Cell>
                          <UserChip user={member} compact />
                        </Table.Cell>
                        <Table.Cell collapsing>
                          <Button
                            icon="trash"
                            basic
                            onClick={() =>
                              setEditingEvent((prev) => ({
                                ...prev,
                                members: prev.members.filter((m) => m !== member._id),
                                ownerMembers: prev.ownerMembers.filter((m) => m._id !== member._id),
                              }))
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              ) : null}
            </Form.Field>
            <Form.Group>
              <Form.Field>
                <label>Start:</label>
                <DateTimeInput
                  disableMinute={true}
                  minDate={moment(from.setHours(0, 0, 0, 0)).toDate()}
                  maxDate={moment(to.setHours(23, 59, 59, 999)).toDate()}
                  dateTimeFormat="YYYY-MM-DD HH:mm"
                  value={moment(editingEvent?.from).toDate()}
                  onChange={(e, { value }) =>
                    setEditingEvent((prev) => ({ ...prev, from: moment(value, "YYYY-MM-DD HH:mm") }))
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>End:</label>
                <DateTimeInput
                  minDate={moment(editingEvent?.from).toDate()}
                  maxDate={moment(to.setHours(23, 59, 59, 999)).toDate()}
                  disableMinute={true}
                  dateTimeFormat="DD-MM-YYYY HH:mm"
                  value={moment(editingEvent?.to || editingEvent?.from).toDate()}
                  onChange={(e, { value }) => {
                    setEditingEvent((prev) => ({ ...prev, to: moment(value, "YYYY-MM-DD HH:mm") }));
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleCloseModal()}>Cancel</Button>
          <TooltipButton
            primary
            onClick={() => handleClickCreateEvent()}
            disabled={moment(editingEvent?.from) > moment(editingEvent?.to)}
            tooltip={
              moment(editingEvent?.from) > moment(editingEvent?.to) ? "Event end date must be after start date" : null
            }
          >
            Save
          </TooltipButton>
        </Modal.Actions>
      </Modal>
      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={editingEvent?.type === "open"}>
        <Modal.Header>{t("common:capitalise", { key: "generic.challenge" })} open date</Modal.Header>
        <Modal.Content>
          <p>
            Set an open date for your {t("generic.challenge")}. If an open date is set, the {t("generic.challenge")}{" "}
            will automatically open at this point.
          </p>
          <TimezoneExplainer style={{ marginBottom: 10 }} />
          <SubmissionInputDiv>
            <Checkbox
              checked={!!challenge?.openDate}
              label={`There is an open date for this ${t("generic.challenge")} of`}
              onChange={() => setNewOpenDate(!challenge?.openDate, editingEvent?.from)}
              disabled={!canPublishChallenges}
              data-tooltip={
                !canPublishChallenges
                  ? `Only users with the "Publish ${t("generic.challenges")}" permission can open ${t("generic.challenges")}.`
                  : null
              }
            />
            {canPublishChallenges ? (
              <>
                <DateTimeInput
                  disableMinute={true}
                  value={moment(editingEvent?.from).toDate()}
                  minDate={moment(new Date().setHours(0, 0, 0, 0)).toDate()}
                  dateTimeFormat="YYYY-MM-DD HH:mm"
                  iconPosition="left"
                  onClear={() => setEditingEvent((prev) => ({ ...prev, from: moment("YYYY-MM-DD HH:mm") }))}
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onChange={(e, { value }) => {
                    setEditingEvent((prev) => ({ ...prev, from: moment(value, "YYYY-MM-DD HH:mm") }));
                    challenge?.openDate &&
                      setNewOpenDate(challenge?.openDate ? true : false, moment(value, "YYYY-MM-DD HH:mm"));
                  }}
                />
              </>
            ) : (
              <>
                <Label style={{ marginLeft: 7 }} content={moment(challenge?.openDate).format("YYYY-MM-DD HH:mm")} />
              </>
            )}
          </SubmissionInputDiv>
          <Checkbox
            style={{ marginTop: 10 }}
            checked={!!challenge?.openWithComms}
            label={`Send notifications and/or emails to users with interests that match this ${t("generic.challenge")} when the ${t("generic.challenge")} is opened.`}
            onChange={() => updateOpenWithComms(!challenge?.openWithComms)}
            disabled={!canPublishChallenges}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleCloseModal()}>Cancel</Button>
          <Button
            primary
            onClick={() => {
              setNewOpenDate(challenge?.openDate ? true : false, moment(editingEvent?.from, "YYYY-MM-DD HH:mm"));
              handleCloseModal();
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={editingEvent?.type === "deadline"}>
        <Modal.Header>{t("common:capitalise", { key: "generic.challenge" })} deadline</Modal.Header>
        <Modal.Content>
          <p>
            Set a deadline for your {t("generic.challenge")}. If a deadline is set, the {t("generic.challenge")} will
            automatically close at this point.
          </p>
          <TimezoneExplainer style={{ marginBottom: 10 }} />
          <SubmissionInputDiv>
            <Checkbox
              checked={!!challenge?.deadline}
              label={`There is a deadline for this ${t("generic.challenge")} of`}
              onChange={() => setNewDeadline(!challenge?.deadline, editingEvent?.from)}
            />
            <DateTimeInput
              disableMinute={true}
              value={moment(editingEvent?.from).toDate()}
              minDate={moment(new Date().setHours(0, 0, 0, 0)).toDate()}
              dateTimeFormat="YYYY-MM-DD HH:mm"
              iconPosition="left"
              onClear={() => setEditingEvent((prev) => ({ ...prev, from: moment("YYYY-MM-DD HH:mm") }))}
              style={{ marginLeft: 10, marginRight: 10 }}
              onChange={(e, { value }) => {
                setEditingEvent((prev) => ({ ...prev, from: moment(value, "YYYY-MM-DD HH:mm") }));
                setNewDeadline(true, moment(value, "YYYY-MM-DD HH:mm"));
              }}
            />
          </SubmissionInputDiv>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleCloseModal()}>Cancel</Button>
          <Button
            primary
            onClick={() => {
              setNewDeadline(challenge?.deadline, editingEvent?.from);
              handleCloseModal();
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={editingEvent?.type === "reminder"}>
        <Modal.Header>Reminder</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field>
                <label>Title:</label>
                <input
                  placeholder="Reminder name"
                  style={{ minWidth: 300, marginBottom: 5 }}
                  value={editingEvent?.title}
                  onChange={(e) => setEditingEvent((prev) => ({ ...prev, title: e.target.value }))}
                />
                {(editingEvent?.title || "").length > 100 && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    We recommend keeping the title under 100 characters.
                  </span>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Description:</label>
              <RichText
                value={editingEvent?.description || ""}
                emptyReturnValue={""}
                placeholder="Describe this reminder..."
                onChange={updateRichtext}
                extraData="description"
                forType="challenge"
                forId={challengeId}
              />
            </Form.Field>
            <Form.Group>
              <Form.Field>
                <label>Start:</label>
                <DateInput
                  dateTimeFormat="YYYY-MM-DD"
                  value={moment(editingEvent?.from).format("YYYY-MM-DD")}
                  onChange={(e, { value }) =>
                    setEditingEvent((prev) => ({ ...prev, from: moment(value, "YYYY-MM-DD").toISOString() }))
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleCloseModal()}>Cancel</Button>
          <Button primary onClick={() => handleClickCreateEvent()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={editingEvent?.type === "meeting"}>
        <Modal.Header>Meeting</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field>
                <label>Title:</label>
                <input
                  placeholder="Meeting name"
                  value={editingEvent?.title}
                  style={{ minWidth: 300 }}
                  onChange={(e) => setEditingEvent((prev) => ({ ...prev, title: e.target.value }))}
                />
                {(editingEvent?.title || "").length > 100 && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    We recommend keeping the title under 100 characters.
                  </span>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Description:</label>
              <RichText
                value={editingEvent?.description || ""}
                emptyReturnValue={""}
                placeholder="Describe this meeting..."
                onChange={updateRichtext}
                extraData="description"
                forType="challenge"
                forId={challengeId}
              />
            </Form.Field>
            <Form.Group>
              <Form.Field>
                <label>Start:</label>
                <DateTimeInput
                  disableMinute={true}
                  dateTimeFormat="YYYY-MM-DD HH:mm"
                  value={moment(editingEvent?.from).toDate()}
                  onChange={(e, { value }) =>
                    setEditingEvent((prev) => ({ ...prev, from: moment(value, "YYYY-MM-DD HH:mm").toISOString() }))
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleCloseModal()}>Cancel</Button>
          <Button primary onClick={() => handleClickCreateEvent()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      {canManageChallenge || util.hasPermission(user, "challenge.editSettings", challengeId) ? (
        <StandardAnnouncementModal
          forType={"challenge"}
          forId={challengeId}
          standardConditions={challengeStandardConditions}
          standardConditionsRequired
          scheduledAnnouncements={[]}
          setScheduledAnnouncements={() => {
            getCalendar();
          }}
          editingAnnouncement={editingEvent?.type === "announcement" && editingEvent?.eventContent}
          setEditingAnnouncement={setEventContent}
          deleteAnnouncement={deleteAnnouncement}
        />
      ) : null}
      <Segment>
        {isLoading ? (
          <PlaceholderStack gap={25}>
            <PlaceholderItem height={50} />
            <PlaceholderItem height={400} />
          </PlaceholderStack>
        ) : (
          <>
            {openDateIssue || closeDateIssue ? (
              <Message
                warning
                size="small"
                icon="warning sign"
                header="Important"
                content={`The challenge ${openDateIssue && closeDateIssue ? "open/close" : closeDateIssue ? "close" : "open"} date is currently outside of the planner date range.`}
                style={{ marginBottom: 20 }}
              />
            ) : null}

            <Form>
              <Form.Group>
                <Form.Dropdown
                  label="Event filter"
                  selection
                  options={[
                    { key: "all", text: "All events", value: "all" },
                    { key: "member", text: "Only events I'm a member of", value: "member" },
                  ]}
                  value={eventFilter}
                  onChange={(_, { value }) => setEventFilter(value as string)}
                />
              </Form.Group>
            </Form>

            <Calendar
              events={filteredEvents}
              start={from}
              end={to}
              deleteEvent={
                canManageChallenge || util.hasPermission(user, "challenge.editSettings", challengeId)
                  ? deleteEvent
                  : null
              }
              leaveEvent={(eventId) => {
                util
                  .confirm("Leave event", "Are you sure you want to leave this event?")
                  .then(() => {
                    api.challenges.leaveCalendarEvent(
                      challengeId,
                      eventId,
                      ({ events }) => {
                        setCalendar((prev) => ({ ...prev, events }));
                      },
                      () => {},
                    );
                  })
                  .catch(() => {});
              }}
              setEditingEvent={setEditingEvent}
              editable={canManageChallenge || util.hasPermission(user, "challenge.editSettings", challengeId)}
              cellActions={cellActions}
            />
          </>
        )}
      </Segment>
    </div>
  );
};

export default Planner;
