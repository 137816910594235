import React, { useState, useEffect, useCallback, ReactElement } from "react";
import { Link } from "react-router-dom";
import { Modal, Input, Loader, Segment, Button, Message, MessageProps, ButtonProps } from "semantic-ui-react";
import { useAppSelector } from "store";
import api from "api";
import util from "utils/utils";
import useThrottle from "utils/useThrottle";

import IdeasImage from "src/images/ideas.png";
import ConfigurableTable from "../ConfigurableTable";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ImageWithFallback } from "../ImageWithFallback";

type ChallengeChooserProps = {
  trigger?: ReactElement;
  ignores?: string[];
  createNewAction?: () => void;
  instructions?: string;
  onComplete: (challenge: any) => void;
  controlled?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  messageProps?: MessageProps;
  buttonProps?: ButtonProps;
  forType?: string;
};

const ChallengeChooser = ({
  trigger,
  ignores,
  createNewAction,
  instructions,
  onComplete,
  controlled,
  isOpen,
  onClose,
  messageProps,
  buttonProps = {},
  forType,
}: ChallengeChooserProps) => {
  const user = useAppSelector((state) => state.user);
  const [challenges, setChallenges] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [challengeSearchState, setChallengesSearchState] = useState({
    page: 1,
    limit: 10,
    query: "",
    forType: forType,
  });
  const userId = user?._id;
  const { t } = useTranslation();

  const getChallenges = useThrottle(
    () => {
      if (open && userId) {
        setLoading(true);
      }
      api.users.getChallenges(
        userId,
        challengeSearchState,
        (data) => {
          setLoading(false);
          let { challenges: newChallenges } = data;
          if (ignores?.length > 0) newChallenges = newChallenges.filter((c) => ignores.indexOf(c._id) === -1);
          setChallenges(newChallenges);
        },
        () => {},
      );
    },
    400,
    [open, userId, ignores, challengeSearchState],
    { useLeadingCall: true },
  );

  useEffect(() => {
    getChallenges();
  }, [getChallenges]);

  const onClickChallenge = useCallback(
    (challenge) => {
      if (selectedChallenge?._id === challenge._id) setSelectedChallenge(null);
      else setSelectedChallenge(challenge);
    },
    [selectedChallenge],
  );

  const onChoose = useCallback(
    (challenge) => {
      onComplete(challenge);
      setSelectedChallenge(null);
    },
    [onComplete],
  );

  return (
    <>
      {controlled ? null : (
        <>
          {trigger ? (
            React.cloneElement(trigger, { onClick: () => setOpen(!open) })
          ) : (
            <Button
              size="small"
              primary
              icon="lightbulb"
              content={`Choose ${t("generic.challengeWithArticle")}`}
              onClick={() => setOpen(!open)}
            />
          )}
        </>
      )}

      <Modal
        open={controlled ? isOpen : open}
        onClose={() => {
          if (controlled) onClose();
          else setOpen(false);
        }}
        mountNode={document.getElementById("semantic-modal-mount-node")}
      >
        <Modal.Header>
          <h3>Choose {t("generic.challengeWithArticle")}</h3>
          {instructions && <h5 style={{ marginTop: 0 }}>{instructions}</h5>}
        </Modal.Header>
        <Modal.Content>
          {messageProps && <Message {...messageProps} />}
          {loading ? (
            <Loader active />
          ) : (
            <>
              <div style={{ textAlign: "right", marginBottom: 20 }}>
                <Input
                  autoFocus
                  icon="search"
                  placeholder={`Search for ${t("generic.challengeWithArticle")}...`}
                  value={challengeSearchState.query}
                  onChange={(e) => {
                    setSelectedChallenge(null);
                    setChallengesSearchState((prev) => ({ ...prev, query: e.target.value }));
                  }}
                />
              </div>

              {challenges.length > 0 ? (
                <ConfigurableTable
                  tableKey="challenge-chooser"
                  preventSelectAll
                  selectedKeys={selectedChallenge ? [selectedChallenge._id] : []}
                  data={challenges}
                  columns={[
                    {
                      key: "name",
                      name: "Name",
                      render: ({ item }) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <ImageWithFallback avatar src={item.bannerImageUrl} fallbackSrc={util.challengeImage()} />
                          <Link to={`/challenges/${item._id}`} target="_blank" rel="noopener noreferrer">
                            {item.name}
                          </Link>
                        </div>
                      ),
                    },
                    {
                      key: "createdAt",
                      name: "Created",
                      render: ({ cell }) => moment(cell).format("DD/MM/YYYY"),
                    },
                    {
                      key: "stage",
                      name: "Status",
                      render: ({ cell }) => (cell === "published" ? "Open" : "Closed"),
                    },
                  ]}
                  onSelect={onClickChallenge}
                />
              ) : (
                <Segment placeholder textAlign="center">
                  <img
                    src={IdeasImage}
                    style={{
                      maxHeight: 100,
                      maxWidth: "80%",
                      display: "block",
                      margin: "5px auto",
                    }}
                    alt={`No ${t("generic.challenges")} yet`}
                  />
                  <h4>No {t("generic.challenges")} found</h4>
                  {challengeSearchState.query ? (
                    <p>Try adjusting your search term.</p>
                  ) : (
                    <p>There are no {t("generic.challenges")} to choose from.</p>
                  )}
                </Segment>
              )}
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => (controlled ? onClose() : setOpen(false))} content="Cancel" />
          {util.canCreateChallenges(user) && challenges?.length > 0 && createNewAction && (
            <Button
              primary
              onClick={createNewAction}
              content={`Create a new ${t("generic.challenge")} instead`}
              icon="plus"
            />
          )}
          {selectedChallenge ? (
            <Button
              primary
              onClick={() => onChoose(selectedChallenge)}
              content={`Choose this ${t("generic.challenge")}`}
              icon="check"
              {...buttonProps}
            />
          ) : null}
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ChallengeChooser;
