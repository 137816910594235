import React from "react";
import { Dropdown as SemanticDropdown } from "semantic-ui-react";

const Dropdown = ({ field, fieldValue = "", updateIdea, isEditing }) => {
  if (!isEditing) return <span>{fieldValue}</span>;
  return (
    <SemanticDropdown
      basic
      placeholder="Options"
      clearable
      selection
      value={fieldValue}
      onChange={(e, { value }) => updateIdea(value, ["templated", field.id])}
      options={(field.options || []).map((option, i) => ({ key: i, value: option, text: option }))}
    />
  );
};

export default Dropdown;
