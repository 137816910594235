import React, { useCallback, useEffect, useState } from "react";
import { Header, Icon, Button, Message } from "semantic-ui-react";
import { IdeaCompanyVerification } from "components/lib/Ideas";
import CompanyVerificationReportGenerator from "components/lib/CompanyVerificationReportGenerator";
import toast from "react-hot-toast";
import api from "api";
import { BodySegment } from "./styles";

const CompanyReport = ({ user, field, ideaId, challengeId, fieldValue = "", updateIdea, isEditing }) => {
  const [creditsafeEnabled, setCreditsafeEnabled] = useState(false);
  const [hasHandledShowingCreditsafeLookup, setHasHandledShowingCreditsafeLookup] = useState(false);

  useEffect(() => {
    if (!hasHandledShowingCreditsafeLookup) {
      setHasHandledShowingCreditsafeLookup(true);
      api.challenges.get(
        challengeId,
        (challenge) => {
          setCreditsafeEnabled(challenge.creditsafeAvailable);
        },
        () => {
          setCreditsafeEnabled(false);
        },
      );
    }
  }, [challengeId, hasHandledShowingCreditsafeLookup]);

  const updateReportUrl = useCallback(
    (reportUrl) => {
      if (!fieldValue) {
        return;
      }
      updateIdea({ ...fieldValue, reportUrl }, ["templated", field.id], true);
    },
    [updateIdea, fieldValue, field.id],
  );

  if (!isEditing) {
    if (fieldValue) {
      return <IdeaCompanyVerification idea={{ _id: ideaId }} report={fieldValue} updateReportUrl={updateReportUrl} />;
    }
    return null;
  }
  if (!fieldValue && !creditsafeEnabled) {
    return (
      <Message info>
        <Icon info name="info circle" />
        We have detected an issue with the company lookup service. Please enter your information manually or contact
        support for assistance.
      </Message>
    );
  }

  return (
    <BodySegment placeholder textAlign="center">
      {!fieldValue ? (
        <>
          <Header icon>
            <Icon name="building" /> Company lookup
          </Header>
          <CompanyVerificationReportGenerator
            user={user}
            trigger={<Button primary content="Find your company" />}
            forType="idea"
            forId={ideaId}
            onComplete={(report) => {
              const { tempReportUrl } = report;
              delete report.tempReportUrl;
              updateIdea({ report: report._id, ...report }, ["templated", field.id]);
              updateIdea({ report: report._id, ...report, reportUrl: tempReportUrl }, ["templated", field.id], true);
            }}
            onError={({ creditsafeEnabled, message }) => {
              toast.error(message);
              setCreditsafeEnabled(creditsafeEnabled);
            }}
          />
        </>
      ) : (
        <div style={{ marginTop: 20 }}>
          <IdeaCompanyVerification idea={{ _id: ideaId }} report={fieldValue} updateReportUrl={updateReportUrl} />
          <Button
            size="small"
            style={{ marginTop: 15 }}
            basic
            icon="close"
            color="orange"
            content="Use a different company"
            onClick={() => updateIdea(null, ["templated", field.id])}
          />
        </div>
      )}
    </BodySegment>
  );
};

export default CompanyReport;
