import { useCallback, useContext, useMemo } from "react";
import api from "api";
import toast from "react-hot-toast";
import util from "utils/utils";
import { useNavigate } from "react-router-dom";
import { OpenAPI, Other } from "simplydo/interfaces";
import { CompanyClient } from "./CompanyClient";
import {
  addList,
  removeList,
  addCompanyToList,
  removeCompanyFromList,
  setCreatingList,
  removeCompany as removeReducerCompany,
  bulkAddCompanyToList,
  bulkRemoveCompanyFromList,
  bulkRemoveCompany as bulkRemoveCompaniesReducer,
  ListTypes,
  CompanyStateItem,
  setSearchState,
  updateStateMeta,
} from "./companyReducer";

const client = new CompanyClient([
  {
    name: "search",
    activePath: "/innovationintelligence",
    allowMultipleCompanyStates: true,
  },
  {
    name: "list",
    activePath: "/innovationintelligence/lists/:listid",
  },
  {
    name: "industryScout",
    activePath: "/innovationintelligence/industryscout/:dashboardId",
  },
]);

export const useSearchState = () => {
  const { companiesState } = useContext(client.stateContext);
  return useMemo(
    () => ({
      ...companiesState.searchState,
      searchLoading: companiesState.searchLoading,
    }),
    [companiesState.searchState, companiesState.searchLoading],
  );
};

export const useSearchCompanyState = () => {
  const { companiesState } = useContext(client.stateContext);
  const { dispatchToState } = useContext(client.dispatchContext);
  if (!Array.isArray(companiesState.states.search)) {
    throw new Error("Global state is not an array");
  }

  const { activeStateId } = companiesState.stateMeta.search;

  const setActiveStateId = useCallback(
    (searchId: string) => {
      dispatchToState.search(updateStateMeta({ activeStateId: searchId || "" }));

      const nextSearch = (companiesState.states.search as CompanyStateItem[]).find(
        (state) => state.searchId === searchId,
      );
      if (!nextSearch) {
        return;
      }
      dispatchToState.search(setSearchState(nextSearch.searchState, { ignoreSource: true }));
    },
    [dispatchToState, companiesState.states.search],
  );

  const activeState = useMemo(
    () => (companiesState.states.search as CompanyStateItem[]).find((state) => state.searchId === activeStateId),
    [companiesState, activeStateId],
  );

  return {
    activeState,
    activeStateId,
    setActiveStateId,
    allStates: companiesState.states.search,
    meta: companiesState.stateMeta.search,
  };
};

export const useSearchCompanyDispatch = () => {
  const { dispatchToState } = useContext(client.dispatchContext);
  return dispatchToState.search;
};

export const useListCompanyState = () => {
  const { companiesState } = useContext(client.stateContext);
  return companiesState.states.list;
};

export const useListCompanyDispatch = () => {
  const { dispatchToState } = useContext(client.dispatchContext);
  return dispatchToState.list;
};

export const useIndustryScoutCompanyState = (): CompanyStateItem => {
  const { companiesState } = useContext(client.stateContext);
  return companiesState.states.industryScout as CompanyStateItem;
};

export const useIndustryScoutCompanyDispatch = () => {
  const { dispatchToState } = useContext(client.dispatchContext);
  return dispatchToState.industryScout;
};

type IIList = OpenAPI.Schemas["InnovationIntelligenceList"] & {
  ownerUser?: Other.IUser;
  subscribed?: boolean;
  events?: unknown[];
};

export const useListState = () => {
  const navigate = useNavigate();
  const { companiesState } = useContext(client.stateContext);
  const { dispatchListAction, dispatchToState, initLists } = useContext(client.dispatchContext);
  const { lists, creatingList } = companiesState;

  const findListAndType = useCallback(
    (listId: string) => {
      let result: { type: keyof typeof lists | null; list: IIList | null } = { type: null, list: null };
      Object.entries(lists).forEach(([type, listOfLists]) => {
        const list = listOfLists.find((l) => l._id === listId);
        if (list) {
          result = { type, list } as { type: keyof typeof lists; list: IIList };
        }
      });
      return result;
    },
    [lists],
  );

  const createList = useCallback(
    (listName: string, companies: string[] | undefined = []) => {
      dispatchListAction("user", setCreatingList(true));
      api.innovationIntelligence.createList(
        { name: listName, companies },
        ({ list: newList }) => {
          dispatchListAction("user", addList(newList));
          toast.success("List created");
        },
        (err) => {
          dispatchListAction("user", setCreatingList(false));
          toast.error(err.message);
        },
      );
    },
    [dispatchListAction],
  );

  const handleRemoveList = useCallback(
    (forType: ListTypes, listId: string) => {
      dispatchListAction(forType, removeList(listId));
    },
    [dispatchListAction],
  );

  const deleteList = useCallback(
    (listId: string) => {
      util
        .confirm(
          "Delete list",
          "Are you sure you want to delete this list? It cannot be recovered, and will no longer be accessible by anyone that it has been shared with",
        )
        .then(() => {
          api.innovationIntelligence.deleteList(
            listId,
            () => {
              handleRemoveList("user", listId);
              navigate("/innovationintelligence");
              toast.success("List deleted");
            },
            (err) => {
              toast.error(err.message);
            },
          );
        })
        .catch(() => {});
    },
    [navigate, handleRemoveList],
  );

  const addCompany = useCallback(
    (listId: string, companyId: string) => {
      api.innovationIntelligence.addListCompany(
        listId,
        companyId,
        () => {
          const { type, list } = findListAndType(listId);
          dispatchListAction(type, addCompanyToList(listId, companyId));
          toast.success(`Company added to list '${list.name}'`);
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [findListAndType, dispatchListAction],
  );

  const removeCompany = useCallback(
    (listId: string, company: OpenAPI.Schemas["Company"]) => {
      util
        .confirm(
          `Remove '${company.name}' from this list`,
          `'${company.name}' will no longer appear in this list for you or anyone this list is shared with. You can search for this company and add it again later if you wish.`,
        )
        .then(() => {
          api.innovationIntelligence.removeListCompany(
            listId,
            company._id,
            () => {
              const { type, list } = findListAndType(listId);
              dispatchListAction(type, removeCompanyFromList(listId, company._id));
              dispatchToState.list(removeReducerCompany(company._id));
              toast.success(`Company removed from list '${list.name}'`);
            },
            (err) => {
              toast.error(err.message);
            },
          );
        })
        .catch(() => {});
    },
    [findListAndType, dispatchListAction, dispatchToState],
  );

  const bulkAddCompanies = useCallback(
    (addToListId: string, companyIds: string[]) => {
      api.innovationIntelligence.addListCompanyMulti(
        addToListId,
        { companies: companyIds },
        () => {
          const { type, list } = findListAndType(addToListId);
          dispatchListAction(type, bulkAddCompanyToList(addToListId, companyIds));
          toast.success(`Company added to list '${list.name}'`);
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [dispatchListAction, findListAndType],
  );

  const bulkRemoveCompanies = useCallback(
    (listId: string, companyIds: string[]) => {
      api.innovationIntelligence.removeListCompanyMulti(
        listId,
        { companies: companyIds },
        () => {
          const { type, list } = findListAndType(listId);
          dispatchListAction(type, bulkRemoveCompanyFromList(listId, companyIds));
          dispatchToState.list(bulkRemoveCompaniesReducer(companyIds));
          toast.success(`Companies removed from list '${list.name}'`);
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [dispatchListAction, findListAndType, dispatchToState],
  );

  const companyIds = useMemo(() => Object.values(lists).flatMap((lol) => lol.flatMap((l) => l.companies)), [lists]);

  return {
    lists,
    initLists,
    creatingList,
    dispatchListAction,
    createList,
    deleteList,
    handleRemoveList,
    addCompany,
    removeCompany,
    findListAndType,
    companyIds,
    bulkAddCompanies,
    bulkRemoveCompanies,
  } as const;
};

export { client };
