import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Segment } from "semantic-ui-react";
import { connect } from "react-redux";

const ErrorDiv = styled.div`
  width: 100vw;
  height: 100vh;
  padding: ${(props) => (props.$padding ? props.$padding : "10px")};
  .centerDiv {
    text-align: center;
  }
`;

function ErrorScreen({ setError, theme, padding }) {
  const navigate = useNavigate();

  return (
    <ErrorDiv $isMobile={theme?.sizes?.isMobile} $padding={padding}>
      <div className="centerDiv">
        <Segment placeholder>
          <h2>Sorry, we are experiencing a problem with this part of the platform</h2>
          <p>An automated report of your issue has been submitted.</p>
          <p style={{ fontWeight: "bold", marginTop: 20, marginBottom: 20 }}>
            Please refresh the page or return to the previous page. If the issue continues, please contact our{" "}
            <a href="mailto:support@simplydo.co.uk?subject=Platform%20Problem">support.</a>
          </p>
          <Button
            className="buttonClass"
            size="medium"
            onClick={() => {
              setError(false);
              navigate(-1);
            }}
            style={{ maxWidth: "fit-content" }}
            icon="arrow left"
            content="Return to previous page"
          />
        </Segment>
      </div>
    </ErrorDiv>
  );
}

export default connect()(ErrorScreen);
