import React, { useEffect, useState, useCallback, useRef, useMemo, Dispatch, SetStateAction } from "react";
import { useAppSelector } from "store";
import { useLocation } from "react-router-dom";
import { Other } from "simplydo/interfaces";
import toast from "react-hot-toast";
import api from "api";
import websocketApi from "api/websocket";

import { HandledIdeaPreview as IdeaPreview } from "components/lib/Ideas/IdeaPreview";
import PageLoadError from "components/lib/PageLoadError";

import ProjectBoard from "./ProjectBoard";
import ProjectBoardSetup from "./ProjectBoardSetup";
import useThrottle from "utils/useThrottle";
import { SearchParams } from "simplydo/core";
import { useTranslation } from "react-i18next";
import util from "utils/utils";

export type ProjectSelectionModes = "multi";

type IProject = {
  forId: string;
  canViewBoard?: boolean;
  canManageBoard?: boolean;
  getBoard: (idea: Other.IIdea) => Other.IIdeaProjectManagementBoard | undefined;
  heading?: string;
  loading?: boolean;
  basicLoading?: boolean;
  assessmentEnabled?: boolean;
  defaultLane?: Other.IDefaultLane;
  updateDefaultLane?: (updatedLane: Other.IDefaultLane) => void;
  // Use external ideas prop to make this component controlled
  ideas?: Other.IIdea[];
  totalIdeas?: number;
  setIdeas?: (cb: (prevIdeas: Other.IIdea[]) => Other.IIdea[]) => void;

  currentIdeaId?: string;
  setCurrentIdeaId?: (ideaId: string) => void;

  // Multi select
  selectionMode?: ProjectSelectionModes;
  selectedIdeaIds?: string[];
  setSelectedIdeaIds?: Dispatch<SetStateAction<string[]>>;

  laneFilter?: string[];
};

type IIdeaEvents = {
  [ideaId: string]: Other.IProjectIdeaEvent[];
};

export type IProjectGeneralProps = {
  user: Other.IUserMe;
  forId: string;
  canManageBoard?: boolean;
  canViewBoard?: boolean;
  getBoard: (idea: Other.IIdea) => Other.IIdeaProjectManagementBoard;
  selectionMode?: ProjectSelectionModes;
};

export type IProjectLaneProps = {
  lanes: Other.IProjectLane[];
  currentLane?: Other.IProjectLane | Other.IDefaultLane;
  defaultLane?: Other.IDefaultLane;
  setLanes: Dispatch<SetStateAction<Other.IProjectLane[]>>;
  setCurrentLane: Dispatch<SetStateAction<Other.IProjectLane | Other.IDefaultLane>>;
  handleWebsocketLanes: Dispatch<SetStateAction<Other.IProjectLane[]>>;
  updateDefaultLane?: (updatedLane: Other.IDefaultLane) => void;
  updateIdeaPositionMulti: (originalLane: string, targetLane: string) => void;
};

export type IProjectIdeaProps = {
  ideas: Other.IIdea[];
  usingPropIdeas?: boolean;
  ideasTotal: number;
  ideaEvents: IIdeaEvents;
  ideaOpen?: boolean;
  currentIdea?: Other.IIdea;
  availableTags: Other.ITag[];
  setIdeaOpen: Dispatch<SetStateAction<boolean>>;
  setIdeas: Dispatch<SetStateAction<Other.IIdea[]>>;
  setIdeasTotal: Dispatch<SetStateAction<number>>;
  setIdeaEvents: Dispatch<SetStateAction<IIdeaEvents>>;
  handleWebsocketIdeas: Dispatch<SetStateAction<Other.IIdea[]>>;
};

export type IProjectAssigneeProps = {
  potentialAssignees: Other.IUser[];
  potentialAssigneeSearch: string;
  setPotentialAssignees: Dispatch<SetStateAction<Other.IUser[]>>;
  setPotentialAssigneeSearch: Dispatch<SetStateAction<string>>;
};

function Project({
  forId,
  canViewBoard,
  canManageBoard,
  getBoard,
  heading,
  loading,
  basicLoading,
  assessmentEnabled,
  defaultLane,
  updateDefaultLane,
  laneFilter,

  // Controlled ideas
  ideas: propIdeas,
  setIdeas: propSetIdeas,
  totalIdeas,
  currentIdeaId: propCurrentIdeaId,
  setCurrentIdeaId: propSetCurrentIdeaId,

  // Multi select
  selectionMode,
  selectedIdeaIds,
  setSelectedIdeaIds,
}: IProject) {
  const { t } = useTranslation();

  if (propIdeas !== undefined && (propSetIdeas === undefined || totalIdeas === undefined)) {
    throw new Error(
      `If '${t("generic.ideas")}' are provided, 'set${t("common:capitalise", { key: "generic.ideas" })}'/'total${t("common:capitalise", { key: "generic.ideas" })}' must also be provided.`,
    );
  }
  if (propCurrentIdeaId !== undefined && propSetCurrentIdeaId === undefined) {
    throw new Error(
      `If 'current${t("common:capitalise", { key: "generic.idea" })}Id' is provided, 'setCurrent${t("common:capitalise", { key: "generic.idea" })}Id' must also be provided.`,
    );
  }
  const usingPropIdeas = useMemo(() => propIdeas !== undefined, [propIdeas]);

  const user = useAppSelector((state) => state.user);
  const searchParams = new SearchParams(window.location.search);
  const params = useLocation().search;
  const queryIdea = useMemo(() => {
    const splitParams = (params || "").split("idea=");
    if (splitParams.length) {
      return splitParams[1];
    }
    return "";
  }, [params]);
  const [lanesLoading, setLanesLoading] = useState<boolean>(false);

  const [lanesLoaded, setLanesLoaded] = useState<boolean>(false);

  const [ideaOpen, setIdeaOpen] = useState<boolean>(false);
  const [ideaEvents, setIdeaEvents] = useState<IIdeaEvents>({});
  const [laneOpen, setLaneOpen] = useState<boolean>(false);
  const [currentLane, setCurrentLane] = useState<Other.IProjectLane | Other.IDefaultLane | null>(null);

  const [_lanes, setLanes] = useState<Other.IProjectLane[]>([]);
  const lanes = useMemo(
    () => _lanes.filter((l) => !laneFilter?.length || laneFilter.indexOf(l._id) > -1),
    [_lanes, laneFilter],
  );

  const [setupModalOpen, setSetupModalOpen] = useState<boolean>(false);
  const [laneIdeaOrder, setLaneIdeaOrder] = useState<string[]>([]);
  const [localIdeas, setLocalIdeas] = useState<Other.IIdea[]>([]);
  const [ideasTotal, setIdeasTotal] = useState<number>(0);
  const [rewardIdeaId, setRewardIdeaId] = useState<string>("");
  const [potentialAssigneeSearch, setPotentialAssigneeSearch] = useState<string>("");
  const [potentialAssignees, setPotentialAssignees] = useState<Other.IUser[]>([]);
  const [newLaneName, setNewLaneName] = useState<string>("");
  const [newLaneDescription, setNewLaneDescription] = useState<string>("");
  const [newLaneNameSaved, setNewLaneNameSaved] = useState<boolean>(true);
  const [newLaneDescriptionSaved, setNewLaneDescriptionSaved] = useState<boolean>(true);
  const [handledQueryIdea, setHandledQueryIdea] = useState<boolean>(false);

  // Load the idea into local state based on the current ID in the URL
  const [localCurrentIdeaId, setLocalCurrentIdeaId] = useState<string>("");
  const [_loadingCurrentIdea, setLoadingCurrentIdea] = useState<boolean>(false);
  const [currentIdea, setCurrentIdea] = useState<Other.IIdea | null>(null);

  const [highlightCard, setHighlightCard] = useState<string>(searchParams.has("idea") ? queryIdea : "");
  const projectWebsocket = useRef<ReturnType<(typeof websocketApi)["subscribe"]>>();
  const userId = user?._id;

  const ideas = useMemo(() => propIdeas ?? localIdeas, [propIdeas, localIdeas]);
  const setIdeas = useMemo(() => propSetIdeas ?? setLocalIdeas, [propSetIdeas, setLocalIdeas]);

  const currentIdeaId = useMemo(() => propCurrentIdeaId ?? localCurrentIdeaId, [propCurrentIdeaId, localCurrentIdeaId]);
  const setCurrentIdeaId = useMemo(
    () => propSetCurrentIdeaId ?? setLocalCurrentIdeaId,
    [propSetCurrentIdeaId, setLocalCurrentIdeaId],
  );

  useEffect(() => {
    if (ideas.length && queryIdea && !handledQueryIdea) {
      const ideaIds = ideas.map((i) => i._id);
      if (ideaIds.indexOf(queryIdea) > -1) {
        setHandledQueryIdea(true);
        setCurrentIdeaId(queryIdea);
      }
    }
  }, [ideas, queryIdea, handledQueryIdea, setCurrentIdeaId]);

  const getCurrentIdea = useCallback(() => {
    if (currentIdeaId) {
      setCurrentIdea(null);
      setLoadingCurrentIdea(true);
      api.ideas.get(
        currentIdeaId,
        (idea) => {
          setCurrentIdea(idea);
          setLoadingCurrentIdea(false);
        },
        (err) => {
          toast.error(err.message);
          setLoadingCurrentIdea(false);
        },
        null,
      );
    }
  }, [currentIdeaId]);

  useEffect(() => {
    getCurrentIdea();
  }, [getCurrentIdea]);

  const ideaOrderIndex = useMemo<number>(
    () => currentIdeaId && laneIdeaOrder.findIndex((id) => id === currentIdeaId),
    [currentIdeaId, laneIdeaOrder],
  );

  const handleWebsocketData = useCallback(
    (data: { lanes: Other.IProjectLane[]; ideas: Other.IIdea[]; channel: string; fromUser: string }) => {
      const { lanes: updatedLanes, ideas: updatedIdeas, channel, fromUser } = data;

      if (fromUser === userId) {
        return;
      }

      switch (channel) {
        case "lanesUpdated":
          setLanes(updatedLanes);
          break;
        case "ideasUpdated":
          setIdeas(() => updatedIdeas);
          break;
        default:
          break;
      }
    },
    [setIdeas, userId],
  );

  useEffect(() => setRewardIdeaId(null), [setRewardIdeaId]);

  const handleWebsocketLanes = useCallback(
    (updatedLanes: Other.IProjectLane[]) => {
      if (projectWebsocket && projectWebsocket.current) {
        projectWebsocket.current.publish("lanesUpdated", { lanes: updatedLanes, fromUser: userId });
      }
    },
    [projectWebsocket, userId],
  );

  const handleWebsocketIdeas = useCallback(
    (updatedIdeas: Other.IIdea[]) => {
      if (projectWebsocket && projectWebsocket.current) {
        projectWebsocket.current.publish("ideasUpdated", { ideas: updatedIdeas, fromUser: userId });
      }
    },
    [projectWebsocket, userId],
  );

  const moveIdea = useCallback(
    (amount: number) => {
      if (ideaOrderIndex === -1) return;
      const newIndex = ideaOrderIndex + amount;
      const newIdeaId = laneIdeaOrder[newIndex];
      if (newIdeaId) {
        setCurrentIdeaId(newIdeaId);
      }
    },
    [ideaOrderIndex, laneIdeaOrder, setCurrentIdeaId],
  );

  useEffect(() => {
    // @ts-ignore
    projectWebsocket.current = websocketApi.subscribe(`projectBoard-${forId}`, (data) => handleWebsocketData(data));
    return () => projectWebsocket.current.unsubscribe();
  }, [handleWebsocketData, forId]);

  const onUpdateIdea = useCallback(
    (data: Partial<Other.IIdea>) => {
      if (!currentIdeaId) {
        return;
      }
      setIdeas((prevIdeas) =>
        prevIdeas.map((i) => {
          if (i._id !== currentIdeaId) {
            return i;
          }
          return { ...i, ...data };
        }),
      );
    },
    [currentIdeaId, setIdeas],
  );

  const getBoardLanes = useCallback(() => {
    setLanesLoading(true);
    api.boards.getProjectLanes(
      forId,
      ({ lanes: newLanes }) => {
        setLanes(newLanes);
        setLanesLoading(false);
        setLanesLoaded((prevLoaded) => prevLoaded || true);
      },
      () => {
        setLanesLoading(false);
      },
    );
  }, [forId]);

  useEffect(() => {
    getBoardLanes();
  }, [getBoardLanes]);

  useEffect(() => {
    if (currentLane && currentLane) {
      const updatedLane = lanes.find((l) => l._id === (currentLane?._id ? currentLane?._id : currentLane?.id));
      if (updatedLane) setCurrentLane(updatedLane);
    }
  }, [currentLane, lanes]);

  useEffect(() => {
    if (!currentIdeaId) return;
    api.boards.getProjectIdeaEvents(
      forId,
      currentIdeaId,
      (data) => {
        const events = { ...ideaEvents };
        if (!events[currentIdeaId]) events[currentIdeaId] = [];
        events[currentIdeaId] = data.events;
        setIdeaEvents(events);
      },
      () => {},
    );
  }, [forId, currentIdeaId]); // eslint-disable-line react-hooks/exhaustive-deps

  const cardClicked = (ideaId: string) => {
    setIdeaOpen(true);
    setCurrentIdeaId(ideaId);
    if (ideaId === highlightCard) {
      setHighlightCard("");
    }
  };

  const searchPotentialAssignees = useThrottle(
    (term: string) => {
      if (!currentIdeaId) return;
      api.boards.searchProjectAssignees(
        forId,
        term,
        ({ users: newAssignees }) => setPotentialAssignees(newAssignees),
        () => {},
      );
    },
    300,
    [forId, currentIdeaId],
  );

  useEffect(() => {
    searchPotentialAssignees(potentialAssigneeSearch);
  }, [searchPotentialAssignees, potentialAssigneeSearch]);

  const updateIdeaPosition = useCallback(
    (ideaId: string, targetLaneId: string, position: number) => {
      setRewardIdeaId(null);
      const idea = ideas.filter((i) => i._id === ideaId)[0];
      const board = getBoard(idea);
      const originalLane = board?.lane;
      if (
        !canManageBoard &&
        !(board?.assignees?.indexOf(user._id) > -1) &&
        !util.hasPermission(user, "challenge.editProjectBoard", forId)
      ) {
        return false;
      }
      // If no new lane or order, then return:
      if (!((board?.lane || "default") !== targetLaneId || position !== board?.order || 0)) return;
      api.boards.updateProjectIdea(
        forId,
        ideaId,
        targetLaneId,
        position,
        ({ idea: updatedIdea, event, updatedIdeas }) => {
          if (event) {
            const newIdeaEvents = { ...ideaEvents };
            newIdeaEvents[updatedIdea._id] = Object.assign([], newIdeaEvents[updatedIdea._id]);
            newIdeaEvents[updatedIdea._id].splice(0, 0, event);
            setIdeaEvents(newIdeaEvents);
          }

          const moveToLane = lanes.find((l) => l._id === targetLaneId);
          if (originalLane !== targetLaneId && moveToLane?.metaType === "completed") {
            setRewardIdeaId(ideaId);
          }
          let newIdeas = null;
          setIdeas((prev) => {
            newIdeas = prev.map((i) => {
              const thisIdeaBoard = getBoard(i);

              // Update this idea if it's the one that was moved:
              if (i._id === ideaId) {
                if (thisIdeaBoard) {
                  // Update only specific fields so we don't lose the assigneeUsers array:
                  const newBoards = updatedIdea.projectManagement?.boards;
                  const newBoard = newBoards.find((b) => b.forId === forId);
                  thisIdeaBoard.lane = newBoard?.lane;
                  thisIdeaBoard.updatedAt = newBoard?.updatedAt;
                  thisIdeaBoard.order = newBoard?.order;
                }
              } else {
                // Otherwise check if this idea is in the updatedIdeas from the server in case we need to update an order
                const currentUpdatedIdea = updatedIdeas.find((c) => c._id === i._id);
                if (currentUpdatedIdea) {
                  const currentUpdatedIdeaBoard = getBoard(currentUpdatedIdea);
                  thisIdeaBoard.order = currentUpdatedIdeaBoard.order;
                }
              }
              return i;
            });
            return newIdeas;
          });
          handleWebsocketIdeas(newIdeas);
        },
        () => {},
      );
      return true;
    },
    [user, ideas, getBoard, canManageBoard, forId, lanes, setIdeas, handleWebsocketIdeas, ideaEvents],
  );

  const updateIdeaPositionMulti = useCallback(
    (originalLane: string, targetLane: string) => {
      const moveData = { originalLane, targetLane };
      api.boards.updateProjectIdeasMulti(
        forId,
        moveData,
        ({ updatedIdeas, events }) => {
          if (events) {
            const newIdeaEvents = { ...events, ...ideaEvents };
            setIdeaEvents(newIdeaEvents);
          }
          let newIdeas = null;
          setIdeas((prevIdeas) => {
            newIdeas = prevIdeas.map((i) => {
              const thisIdeaBoard = getBoard(i);
              // Update this idea if it's the one that was moved:
              if (thisIdeaBoard) {
                const updatedIdea = updatedIdeas.find((updIdea) => i._id === updIdea._id);
                if (updatedIdea) {
                  // Update only specific fields so we don't lose the assigneeUsers array:
                  const newBoards = updatedIdea.projectManagement?.boards;
                  const newBoard = newBoards.find((b) => b.forId === forId);
                  thisIdeaBoard.lane = newBoard?.lane;
                  thisIdeaBoard.updatedAt = newBoard?.updatedAt;
                  thisIdeaBoard.order = newBoard?.order;
                }
              }
              return i;
            });
            return newIdeas;
          });
          handleWebsocketIdeas(newIdeas);
          toast.success(`${t("common:capitalise", { key: "generic.ideas" })} moved`);
        },
        () => {
          toast.error(`Unable to move ${t("generic.ideas")}`);
        },
      );
    },
    [forId, setIdeas, handleWebsocketIdeas, ideaEvents, getBoard, t],
  );

  const onIdeaActivity = useCallback(
    (ideaId: string) => {
      const board = getBoard(ideas.find((i) => i._id === ideaId));
      if (
        (board.assignees && board.assignees.indexOf(userId) > -1) ||
        canManageBoard ||
        canViewBoard ||
        util.hasPermission(user, "challenge.viewProjectActivity", forId)
      ) {
        let updatedIdeas = null;
        setIdeas((prevIdeas) => {
          updatedIdeas = prevIdeas.map((i) => {
            if (i._id !== ideaId) return i;
            const updatedBoards = i.projectManagement.boards.map((b) => {
              if (b.forId !== forId) return b;
              return { ...b, updatedAt: new Date().toString() };
            });
            return { ...i, projectManagement: { boards: updatedBoards } };
          });
          return updatedIdeas;
        });
        handleWebsocketIdeas(updatedIdeas);
      }
    },
    [getBoard, ideas, userId, user, canManageBoard, canViewBoard, setIdeas, handleWebsocketIdeas, forId],
  );

  if (!user) return <PageLoadError title={"Please login to view the project board"} message={undefined} />;
  if (!canViewBoard && !util.hasPermission(user, "challenge.viewProjectBoard", forId)) return null;

  const generalProps: IProjectGeneralProps = {
    user,
    getBoard,
    forId,
    canManageBoard,
    canViewBoard,
    selectionMode,
  };

  const laneProps: IProjectLaneProps = {
    lanes,
    setLanes,
    handleWebsocketLanes,
    currentLane,
    setCurrentLane,
    defaultLane,
    updateDefaultLane,
    updateIdeaPositionMulti,
  };

  const ideaProps: IProjectIdeaProps = {
    ideas,
    usingPropIdeas,
    ideasTotal,
    setIdeas,
    setIdeasTotal,
    ideaEvents,
    setIdeaEvents,
    handleWebsocketIdeas,
    ideaOpen,
    setIdeaOpen,
    currentIdea,
  };

  const assigneeProps: IProjectAssigneeProps = {
    potentialAssignees,
    setPotentialAssignees,
    potentialAssigneeSearch,
    setPotentialAssigneeSearch,
  };

  return (
    <>
      {!usingPropIdeas ? (
        <IdeaPreview
          isProjectBoard
          forBoardId={forId}
          getBoard={getBoard}
          ideaId={currentIdeaId}
          closeModal={() => setCurrentIdeaId(null)}
          moveIdea={moveIdea}
          canGoNextIdea={ideaOrderIndex + 1 !== ideas.length}
          canGoPrevIdea={ideaOrderIndex !== 0}
          onUpdateIdea={(_ideaId, updateData) => onUpdateIdea(updateData)}
          updateIdeaPosition={updateIdeaPosition}
          onIdeaActivity={onIdeaActivity}
          assessmentEnabled={assessmentEnabled}
          lanes={lanes}
        />
      ) : null}

      <ProjectBoardSetup
        forId={forId}
        dataLoaded={lanesLoaded}
        lanes={lanes}
        setLanes={setLanes}
        setupModalOpen={setupModalOpen}
        setSetupModalOpen={setSetupModalOpen}
        laneFilter={laneFilter}
        canManageBoard={canManageBoard}
      />
      <ProjectBoard
        {...assigneeProps}
        {...generalProps}
        {...ideaProps}
        {...laneProps}
        canManageBoard={canManageBoard}
        canViewBoard={canViewBoard}
        cardClicked={cardClicked}
        heading={heading}
        initialDataLoaded={lanesLoaded}
        basicLoading={basicLoading}
        loading={loading || lanesLoading}
        setLaneIdeaOrder={setLaneIdeaOrder}
        setLaneOpen={setLaneOpen}
        setNewLaneDescription={setNewLaneDescription}
        setNewLaneName={setNewLaneName}
        setCurrentIdeaId={setCurrentIdeaId}
        updateIdeaPosition={updateIdeaPosition}
        onIdeaActivity={onIdeaActivity}
        setRewardIdeaId={setRewardIdeaId}
        rewardIdeaId={rewardIdeaId}
        setSetupModalOpen={setSetupModalOpen}
        highlightCard={highlightCard}
        updateIdeaPositionMulti={updateIdeaPositionMulti}
        lanes={lanes}
        laneFilter={laneFilter}
        laneOpen={laneOpen}
        newLaneDescription={newLaneDescription}
        newLaneDescriptionSaved={newLaneDescriptionSaved}
        newLaneName={newLaneName}
        newLaneNameSaved={newLaneNameSaved}
        setNewLaneDescriptionSaved={setNewLaneDescriptionSaved}
        setNewLaneNameSaved={setNewLaneNameSaved}
        selectedIdeaIds={selectedIdeaIds}
        setSelectedIdeaIds={setSelectedIdeaIds}
      />
    </>
  );
}

export default Project;
