import React, { useState, useEffect, useCallback } from "react";
import { Button, Icon, Popup, Message } from "semantic-ui-react";
import { OpenAPI, Other } from "simplydo/interfaces";
import styled from "styled-components";
import toast from "react-hot-toast";
import api, { asQuery, asMutation } from "api";
import actions from "actions";

import utils from "utils/utils";
import BusinessProfileSelector from "./ProfileSelector";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "store";
import { Overview } from "components/lib/IdeaBusinessProfileModal/Overview";
import IdeaBusinessProfileModal from "components/lib/IdeaBusinessProfileModal/IdeaBusinessProfileModal";
import { BusinessProfileSearcher } from "./BusinessProfileSearcher";
import { useQuery, useMutation } from "@tanstack/react-query";

const IdeaSection = styled.div`
  > h5 {
    display: block;
    margin: 0;
  }
  > .title-explainer {
    color: gray;
    display: block;
    margin: 2.5px 0 20px;
  }
`;

// `updateIdea` can be undefined if the idea is open in an idea preview, in those cases the idea is never editable and no update function is available. However, this is not an issue as the component doesn't expose any editing functionality in that case anyway
type IIdeaBusinessProfileComponent = {
  idea: Other.IIdea;
  isEditing?: boolean;
  style?: object;
  updateIdea?: (updatedValue: any, path: string[], suppressSave?: boolean) => void;
};

const IdeaBusinessProfileComponent = ({ idea, isEditing, style, updateIdea }: IIdeaBusinessProfileComponent) => {
  // Track state of the profile editor - what stage the user is at
  const [creditsafeEnabled, setCreditsafeEnabled] = useState(false);

  const profilesQuery = useQuery({
    queryKey: ["get", "users", "profiles"],
    queryFn: asQuery(api.users.getExistingProfiles),
  });

  const [generatingCreditReport, setGeneratingCreditReport] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const [openInEditMode, setOpenInEditMode] = useState(false);

  const ideaBusinessProfile = idea["lockedOwnerIdeaBusinessProfile"];
  const [businessProfile, setBusinessProfile] = useState<OpenAPI.Schemas["IdeaBusinessProfile"]>(ideaBusinessProfile);
  const {
    name,
    address = {},
    creditsafeRegNo,
    foundingYear,
    vatNo,
    phoneNumber,
    creditsafeCompanyId,
    primaryContact,
    websiteUrl,
    websiteUrlSkipped,
    type,
    status,
    size,
    vatNoSkipped,
    creditsafeRegNoSkipped,
    creditsafeReportUrl,
  } = businessProfile || {};

  const { phoneNumber: contactPhoneNumber, email, fullName, jobTitle } = primaryContact || {};
  const { country, city, streetAddress, postcode, region } = address || {};

  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const challengeId = idea?.challenge;
  const hasReportPermissions =
    utils.hasPermission(user, "challenge.viewAssessments", idea.challenge) ||
    utils.canManageChallenge(user, idea.ownerChallenge) ||
    idea.isAssessor;

  const onProfileUpdated = (businessProfile) => {
    api.queryClient.setQueryData(["get", "users", "profiles", businessProfile._id], {
      ideaBusinessProfile: businessProfile,
    });
    api.queryClient.invalidateQueries({ queryKey: ["get", "users", "profiles"] });
  };

  useEffect(() => {
    api.challenges.get(
      challengeId,
      (challenge) => {
        setCreditsafeEnabled(challenge.creditsafeAvailable);
      },
      () => {
        setCreditsafeEnabled(false);
      },
    );
  }, [challengeId]);

  // Attach a new, or previously created organisation profile
  // Once done, update the idea locally and display the preview of the organisation profile
  const attachBusinessProfile = useCallback(
    (ideaBusinessProfile) => {
      setBusinessProfile(ideaBusinessProfile);
      updateIdea(ideaBusinessProfile._id, ["ideaBusinessProfile"]);
      updateIdea(ideaBusinessProfile, ["lockedOwnerIdeaBusinessProfile"], true);
    },
    [updateIdea],
  );

  const unattachBusinessProfile = useCallback(() => {
    updateIdea(null, ["ideaBusinessProfile"]);
    updateIdea(null, ["lockedOwnerIdeaBusinessProfile"], true);
    setBusinessProfile(null);
  }, [updateIdea]);

  const viewReportForCompany = useCallback(() => {
    if (ideaBusinessProfile?._id) {
      setGeneratingCreditReport(true);
      api.users.generateIdeaBusinessProfileCreditReport(
        ideaBusinessProfile?._id,
        ({ success, creditsafeReportUrl }) => {
          if (!success) {
            toast.error("Could not generate CreditSafe report");
            setGeneratingCreditReport(false);
            return;
          }
          // undefined if opened in idea preview, the update is only really for the frontend anyway
          // The actual caching is built into the API
          updateIdea?.(creditsafeReportUrl, ["lockedOwnerIdeaBusinessProfile", "creditsafeReportUrl"], true);
          setGeneratingCreditReport(false);
          window.open(creditsafeReportUrl, "_blank", "noopener noreferrer");
        },
        (err) => {
          toast.error(err.message);
          setGeneratingCreditReport(false);
        },
      );
    }
  }, [ideaBusinessProfile?._id, updateIdea]);

  const createProfileMutation = useMutation({
    mutationKey: ["post", "users", "profiles"],
    mutationFn: asMutation(api.users.createProfile),
    onSuccess: ({ ideaBusinessProfile }) => {
      toast.success("Profile created");
      dispatch(
        actions.user.updatePermissions(ideaBusinessProfile._id, [
          "ideaBusinessProfile.viewDetails",
          "ideaBusinessProfile.editDetails",
          "ideaBusinessProfile.viewIdeas",
          "ideaBusinessProfile.editIdeas",
          "ideaBusinessProfile.viewMembers",
          "ideaBusinessProfile.editMembers",
          "ideaBusinessProfile.deleteProfile",
        ]),
      );
      onProfileUpdated(ideaBusinessProfile);
    },
    onError: () => {
      toast.error("Failed to create profile");
    },
  });

  const createBusinessProfile = useCallback(
    (profile) => {
      createProfileMutation.mutate(
        {
          params: [profile],
        },
        {
          onSuccess: ({ ideaBusinessProfile }) => {
            setOpenInEditMode(true);
            setBusinessProfile(ideaBusinessProfile);
            setIsCreationModalOpen(false);
            setIsProfileModalOpen(true);
          },
        },
      );
    },
    [createProfileMutation],
  );

  if (!isEditing && !ideaBusinessProfile) {
    return null;
  }

  const currentYear = new Date().getFullYear();

  const requiredInfoFields = [
    { label: "Name", value: name },
    { label: "Website, social media, or other web presence", value: websiteUrl, skipped: websiteUrlSkipped },
    { label: "Phone number", value: phoneNumber },
    { label: "Street address", value: streetAddress },
    { label: "Country", value: country },
    { label: "Region", value: region, skipped: country !== "GB" },
    { label: "City", value: city },
    { label: "Postcode", value: postcode },
    { label: "Organisation size", value: size },
    { label: "Organisation type", value: type },
    { label: "Organisation status", value: status },
    {
      label: "Organisation founding year",
      value: foundingYear && foundingYear > 999 && foundingYear <= currentYear ? foundingYear : "",
    },
    { label: "Company registration number", value: creditsafeRegNo, skipped: creditsafeRegNoSkipped },
    { label: "VAT registration number", value: vatNo, skipped: vatNoSkipped },
    { label: "Contact name", value: fullName },
    { label: "Contact position or job title", value: jobTitle },
    { label: "Contact phone number", value: contactPhoneNumber },
    { label: "Contact email", value: email },
  ];

  const isMissingRequiredInfo = requiredInfoFields.some((field) => !field.value && !field.skipped);

  return (
    <IdeaSection style={style}>
      {isProfileModalOpen ? (
        <IdeaBusinessProfileModal
          openInEditMode={openInEditMode}
          businessProfile={businessProfile}
          onClose={() => {
            setIsProfileModalOpen(false);
            updateIdea(businessProfile._id, ["ideaBusinessProfile"]);
          }}
          onSave={(savedProfile) => {
            setBusinessProfile(savedProfile);
            attachBusinessProfile(savedProfile);
          }}
          onDelete={() => {
            updateIdea(null, ["lockedOwnerIdeaBusinessProfile"], true);
            updateIdea(null, ["ideaBusinessProfile"]);
            setIsProfileModalOpen(false);
            setBusinessProfile(null);
          }}
        />
      ) : null}
      {isCreationModalOpen ? (
        <BusinessProfileSearcher
          onClose={() => setIsCreationModalOpen(false)}
          onSelect={(profile) => createBusinessProfile(profile)}
          creditsafeEnabled={creditsafeEnabled}
          ideaId={idea._id}
        />
      ) : null}
      <h3>
        Organisation details
        {isEditing && idea.ideaTemplate?.body?.find((section) => section.type === "businessProfile")?.isRequired ? (
          <Popup
            size="small"
            trigger={
              <Icon name="asterisk" style={{ position: "relative", top: -5, marginLeft: 3 }} color="red" size="tiny" />
            }
            content={`This field must be filled in to submit your ${t("generic.idea")}`}
          />
        ) : null}
      </h3>
      {isEditing ? (
        <span className="title-explainer">
          Please provide your organisation's details to supplement your {t("generic.idea")}.
        </span>
      ) : (
        <div style={{ marginBottom: 5 }} />
      )}

      {isEditing && ideaBusinessProfile && isMissingRequiredInfo && (
        <Message
          icon="exclamation triangle"
          warning
          size="small"
          content={
            <>
              <p>
                <strong>Your organisation profile is missing required information</strong>
              </p>
              <ul>
                {requiredInfoFields.map((field) => {
                  if (field.value || field.skipped) {
                    return null;
                  }
                  return <li key={field.label}>{field.label}</li>;
                })}
              </ul>
              <Button
                icon="pencil"
                basic
                size="small"
                onClick={() => {
                  setIsProfileModalOpen(true);
                  setOpenInEditMode(true);
                }}
                content="Edit profile"
              />
            </>
          }
        />
      )}

      {isEditing ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            alignItems: profilesQuery.data?.profiles ? "flex-end" : "stretch",
          }}
        >
          <BusinessProfileSelector
            key={ideaBusinessProfile?._id}
            attachedBusinessProfile={ideaBusinessProfile}
            loadingExistingProfiles={profilesQuery.isLoading}
            existingProfiles={profilesQuery.data?.profiles ?? []}
            attachBusinessProfile={(ideaBusinessProfile) => attachBusinessProfile(ideaBusinessProfile)}
            unattachBusinessProfile={unattachBusinessProfile}
          />
          {ideaBusinessProfile ? (
            <div
              style={{
                display: "flex",
                gap: 8,
                alignSelf: "flex-end",
              }}
            >
              <Button basic onClick={() => setIsProfileModalOpen(true)}>
                <Icon name="pencil" />
                Manage profile
              </Button>
            </div>
          ) : (
            <Button
              secondary={profilesQuery.data?.profiles?.length === 0}
              basic={profilesQuery.data?.profiles?.length !== 0}
              onClick={() => (creditsafeEnabled ? setIsCreationModalOpen(true) : setIsProfileModalOpen(true))}
            >
              <Icon name="plus" />
              Create new profile
            </Button>
          )}
        </div>
      ) : null}

      {ideaBusinessProfile ? (
        <div style={{ marginTop: 16 }}>
          <Overview businessProfile={ideaBusinessProfile} />
          {creditsafeCompanyId && hasReportPermissions ? (
            <div className="credit-report-area">
              {creditsafeReportUrl ? (
                <Button
                  content="Download credit report"
                  icon="file pdf"
                  as="a"
                  href={creditsafeReportUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ) : creditsafeEnabled ? (
                <Button
                  content="Download credit report"
                  icon="file pdf"
                  onClick={() => viewReportForCompany()}
                  loading={generatingCreditReport}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </IdeaSection>
  );
};

export default IdeaBusinessProfileComponent;
