import React, { useEffect, useCallback, useState, useMemo, CSSProperties } from "react";
import { Button, Icon, Input, List, Image, Popup, Dropdown, Modal, Divider } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "store";
import actions from "actions";
import { EmptyBox } from "components/lib/UI";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import api from "api";
import util from "utils/utils";
import toast from "react-hot-toast";
import useWindowDimensions from "utils/useWindowDimensions";
import { connect } from "react-redux";

const GoogleHelperFloatingContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

// Animated button for the google helper

const EXPANDED_WIDTH = 200;
const COLLAPSED_WIDTH = 50;

const expandWidth = keyframes`
  from {
    width: ${EXPANDED_WIDTH}px;
  }
  to {
    width: ${COLLAPSED_WIDTH}px;
  }
`;

const collapseWidth = keyframes`
  from {
    width: ${COLLAPSED_WIDTH}px;
  }
  to {
    width: ${EXPANDED_WIDTH}px;
  }
`;

const AnimatedButton = styled(Button)<{ $isExpanded?: boolean }>`
  width: ${({ $isExpanded }) => (!$isExpanded ? `${EXPANDED_WIDTH}px` : `${COLLAPSED_WIDTH}px`)};
  transition: width 0.3s ease;

  animation: ${({ $isExpanded }) => ($isExpanded ? expandWidth : collapseWidth)} 0.3s ease;
  height: 40px;
  text-overflow: hidden;
  white-space: nowrap;

  z-index: 1000;
`;

const IntroContainer = styled.div`
  > .info {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    padding: 0 20px;
    > span,
    a {
      display: block;
    }
    .search-term {
      padding: 10px !important;
      margin: 0 -10px;
      text-decoration-line: underline;
      cursor: pointer;
      &:hover {
        background-color: #e9ebee;
        border-radius: 5px;
      }
    }
  }
`;

interface AnimatedSearchBoxProps {
  $isExpanded?: boolean;
  $expandedHeight: number;
  $expandedWidth?: number;
}

const EXPANDED_CONTAINER_HEIGHT = 800;

const AnimatedSearchBox = styled.div<AnimatedSearchBoxProps>`
  width: ${({ $isExpanded, $expandedWidth }) => ($isExpanded ? $expandedWidth : EXPANDED_WIDTH)}px;
  height: ${({ $isExpanded, $expandedHeight }) => ($isExpanded ? $expandedHeight : 0)}px;
  opacity: ${({ $isExpanded }) => ($isExpanded ? "1" : "0")};
  pointer-events: ${({ $isExpanded }) => ($isExpanded ? "auto" : "none")};
  transition:
    width 0.3s ease,
    height 0.3s ease,
    opacity 0.3s ease,
    pointer-events 0.3s ease;

  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 0.5px solid #e9ebee;

  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  position: absolute;
  right: 0;
  bottom: 50px;
`;

const SearchBoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  > h5 {
    display: block;
    margin: 0;
  }
`;

const StyledListItem = styled(List.Item)`
  display: flex !important;
  max-width: calc(100% - 20px);
`;

const StyledListContent = styled(List.Content)`
  overflow: hidden;
  width: 100% !important;
  max-width: calc(100% - 28px - 0.5em);
  > a,
  div {
    max-width: 100%;
  }
  > a {
    color: blue;
    font-size: 0.8em;
  }
`;

type GoogleHelperProps = {
  // List of pages to display the helper on, accepts regex to be tested
  displayOnPages: string[];
  containerStyle?: CSSProperties;
  isOpen?: boolean;
  openGoogleHelper?: (isOpen: boolean) => void;
};

const GoogleHelper = ({ displayOnPages, containerStyle = {}, isOpen, openGoogleHelper }: GoogleHelperProps) => {
  const [didSearch, setDidSearch] = useState(false);
  const [lists, setLists] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [summaryLoading, setSummaryLoading] = useState<string[]>([]);
  const [actionPopupOpen, setActionPopupOpen] = useState<string[]>([]);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { height, width } = useWindowDimensions();
  const user = useAppSelector((state) => state.user);
  const { innovationIntelligence } = user;
  const { previousSearches = [] } = innovationIntelligence ?? {};

  const usingGoogleHelper = useMemo(() => util.organisationFeaturesEnabled(user, ["googleHelper"]), [user]);

  const shouldDisplay = useMemo(() => {
    if (!usingGoogleHelper) {
      return false;
    }
    return displayOnPages.some((page) => {
      const regex = new RegExp(page);
      return regex.test(location.pathname);
    });
  }, [displayOnPages, location.pathname, usingGoogleHelper]);

  const getLists = useCallback(() => {
    api.innovationIntelligence.getLists(
      ({ userLists, sharedWith }) => {
        setLists([...userLists, ...sharedWith]);
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, []);

  useEffect(() => {
    if (!shouldDisplay) {
      return;
    }
    getLists();
  }, [getLists, shouldDisplay]);

  const { searchTerm, loading, page, limit, searchResults } = useAppSelector((state) => state.googleHelper);

  const searchGoogle = useCallback(
    (propSearchTerm = "") => {
      const usingSearchTerm = propSearchTerm || searchTerm;

      if (!usingSearchTerm) {
        dispatch(actions.googleHelper.receiveSearch([]));
      }

      if (propSearchTerm) {
        dispatch(actions.googleHelper.setSearchQuery(propSearchTerm));
      }

      dispatch(actions.googleHelper.requestSearch());
      api.search.google(
        usingSearchTerm,
        page,
        limit,
        (data) => {
          const { success } = data;
          setDidSearch(true);
          if (success) {
            dispatch(actions.googleHelper.receiveSearch(data.results));
          } else {
            toast.error("Failed to search Google.");
            dispatch(actions.googleHelper.receiveSearch([]));
          }
        },
        (err) => {
          setDidSearch(true);
          toast.error(err.message);
          dispatch(actions.googleHelper.receiveSearch([]));
        },
      );
    },
    [dispatch, limit, page, searchTerm],
  );

  const handleEnterPress = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        searchGoogle();
      }
    },
    [searchGoogle],
  );

  useEffect(() => {
    setDidSearch(false);
  }, [searchTerm]);

  const addToListByUrl = useCallback(
    (list, company) => {
      util
        .confirm(
          "Add company to List",
          `Are you sure you want to add the company '${company.title} - ${company.link}' to ${list.name}?`,
        )
        .then(() => {
          api.innovationIntelligence.addListCompanyByUrl(
            list._id,
            company.link,
            () => {
              getLists();
              toast.success("Company added to list.");
            },
            (err) => {
              toast.error(err.message);
            },
          );
        })
        .catch(() => {});
    },
    [getLists],
  );

  const removeFromListByUrl = useCallback(
    (list, company) => {
      util
        .confirm(
          "Remove company from List",
          `Are you sure you want to remove the company '${company.title} - ${company.link}' from ${list.name}?`,
        )
        .then(() => {
          api.innovationIntelligence.removeListCompanyByUrl(
            list._id,
            company.link,
            () => {
              getLists();
              toast.success("Company removed from list.");
            },
            (err) => {
              toast.error(err.message);
            },
          );
        })
        .catch(() => {});
    },
    [getLists],
  );

  const generateCompanySummary = useCallback(
    (searchResult) => {
      if (summaryLoading.includes(searchResult.link)) {
        return;
      }
      setSummaryLoading([...summaryLoading, searchResult.link]);
      api.users.generateBusinessDescription(
        { websiteUrl: searchResult.link, companyName: searchResult.title },
        ({ description }) => {
          if (!description) {
            toast("Unable to generate summary for this company.");
            return;
          }
          const updatedSearchResults = Object.assign([], searchResults);
          const searchResultIndex = updatedSearchResults.findIndex((result) => result.link === searchResult.link);
          const newResult = Object.assign({}, updatedSearchResults[searchResultIndex]);
          newResult.description = description;
          updatedSearchResults[searchResultIndex] = newResult;
          dispatch(actions.googleHelper.receiveSearch(updatedSearchResults));
          toast.success("Company summary generated.");
          setSummaryLoading(summaryLoading.filter((id) => id !== searchResult.link));
          setActionPopupOpen([...actionPopupOpen, searchResult.link]);
        },
        () => {},
      );
    },
    [dispatch, searchResults, summaryLoading, actionPopupOpen],
  );

  useEffect(() => {
    if (!isOpen && didSearch) {
      setDidSearch(false);
    }
  }, [didSearch, isOpen]);

  if (!shouldDisplay) {
    return null;
  }
  return (
    <GoogleHelperFloatingContainer
      style={{
        ...containerStyle,
        right: 100,
      }}
    >
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={companyModalOpen}
        onClose={() => setCompanyModalOpen(false)}
      >
        <Modal.Header>Company Summary</Modal.Header>
        <Modal.Content>
          <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
            {selectedCompany?.pagemap?.cse_thumbnail?.length ? (
              <Image
                avatar
                src={selectedCompany.pagemap?.cse_thumbnail?.length ? selectedCompany.pagemap.cse_thumbnail[0].src : ""}
                style={{ objectFit: "cover", minWidth: 28 }}
              />
            ) : (
              <Icon name="linkify" style={{ minWidth: 28 }} size="large" />
            )}
            <b>{selectedCompany?.title}</b>
          </div>
          <a
            style={{ color: "blue", maxWidth: "100%" }}
            href={selectedCompany?.link}
            target="_blank"
            rel="noreferrer"
            onClick={() => setSelectedCompany(selectedCompany)}
          >
            {selectedCompany?.link}
          </a>
          <p style={{ marginTop: 10 }}>{selectedCompany?.description}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setCompanyModalOpen(false)}>Close</Button>
          {lists && (
            <Dropdown direction="left" trigger={<Button icon="plus" content="Add to list" />} icon={null}>
              <Dropdown.Menu style={{ width: "100%" }} fluid>
                <Dropdown.Header>Select list to add company</Dropdown.Header>
                {lists.map((list) => {
                  const isInList = list?.companyUrls.includes(selectedCompany?.link);
                  return (
                    <Dropdown.Item
                      key={list._id}
                      icon={isInList ? "check" : null}
                      disabled={isInList}
                      content={list.name}
                      onClick={() =>
                        isInList ? removeFromListByUrl(list, selectedCompany) : addToListByUrl(list, selectedCompany)
                      }
                    />
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Modal.Actions>
      </Modal>
      <AnimatedSearchBox
        $isExpanded={isOpen}
        $expandedHeight={Math.min(height - 80, EXPANDED_CONTAINER_HEIGHT)}
        $expandedWidth={Math.min(width - 40, 600)}
      >
        <SearchBoxHeader>
          <Icon name="google" size="large" />
          <h5>Google Helper</h5>
        </SearchBoxHeader>

        <Input
          placeholder="Search..."
          value={searchTerm}
          style={{ marginBottom: 10, marginTop: 7 }}
          onChange={(e) => dispatch(actions.googleHelper.setSearchQuery(e.target.value))}
          icon={<Icon name="search" inverted circular link onClick={() => searchGoogle()} disabled={!searchTerm} />}
          loading={loading}
          onKeyDown={handleEnterPress}
        />

        {!didSearch && !searchResults.length && !loading ? (
          <IntroContainer>
            <div className="info">
              <span>
                You can use the <b>Google Helper</b> to search for anything across the web and aid with your innovation
                intelligence search.
              </span>
              {previousSearches.length ? (
                <>
                  <Divider />
                  <span>Why not try searching for...</span>
                  <List style={{ margin: 0 }}>
                    {previousSearches
                      .filter((search) => !!search.params?.query && !util.validateUrl(search.params.query))
                      .slice(0, 9)
                      .map((search) => (
                        <List.Item
                          className="search-term"
                          key={search._id}
                          onClick={() => searchGoogle(search.params.query)}
                        >
                          {search.params.query}
                        </List.Item>
                      ))}
                  </List>
                </>
              ) : null}
            </div>
          </IntroContainer>
        ) : null}

        {didSearch && !searchResults.length ? <EmptyBox title="No results found." style={{ margin: 0 }} /> : null}

        <List relaxed="very" divided style={{ margin: 0, overflowY: "scroll", overflowX: "auto" }}>
          {searchResults.map((searchResult) => (
            <StyledListItem key={searchResult.link}>
              {searchResult.pagemap?.cse_thumbnail?.length ? (
                <Image
                  avatar
                  src={searchResult.pagemap?.cse_thumbnail?.length ? searchResult.pagemap.cse_thumbnail[0].src : ""}
                  style={{ objectFit: "cover", minWidth: 28 }}
                />
              ) : (
                <Icon name="linkify" style={{ minWidth: 28 }} size="large" />
              )}

              <StyledListContent>
                <List.Description style={{ maxWidth: "100%" }}>{searchResult.title}</List.Description>
                <List.Description
                  as="a"
                  style={{ color: "blue", fontSize: "0.8em", maxWidth: "100%" }}
                  href={searchResult.link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    setSelectedCompany(searchResult);
                    setCompanyModalOpen(true);
                  }}
                >
                  {searchResult.link}
                </List.Description>
              </StyledListContent>
              <List.Content style={{ marginLeft: 10 }}>
                <Popup
                  on={["hover", "click"]}
                  open={actionPopupOpen.includes(searchResult.link)}
                  onOpen={() => setActionPopupOpen((prevState) => [...prevState, searchResult.link])}
                  onClose={() => setActionPopupOpen((prevState) => prevState.filter((id) => id !== searchResult.link))}
                  content={
                    searchResult?.description ? (
                      <>
                        <h5>Company summary</h5>
                        <p>{searchResult.description}</p>
                      </>
                    ) : (
                      "Generate company summary"
                    )
                  }
                  style={{ maxWidth: 400 }}
                  trigger={
                    <Button
                      icon="eye"
                      loading={summaryLoading.includes(searchResult.link)}
                      basic={!!searchResult?.description}
                      onClick={() => generateCompanySummary(searchResult)}
                    />
                  }
                />
              </List.Content>
              <List.Content>
                <Popup
                  content="Search this page with innovation intelligence"
                  trigger={
                    <Button
                      icon="search"
                      onClick={() =>
                        navigate(
                          `/innovationintelligence?searchFor=${btoa(JSON.stringify({ query: searchResult.link, source: "website" }))}`,
                        )
                      }
                    />
                  }
                />
              </List.Content>
              <List.Content>
                {lists && (
                  <Dropdown
                    direction="left"
                    trigger={<Popup trigger={<Button icon="plus" />} content="Add to list" />}
                    icon={null}
                  >
                    <Dropdown.Menu style={{ width: "100%" }} fluid>
                      <Dropdown.Header>Select list to add company</Dropdown.Header>
                      {lists.map((list) => {
                        const isInList = list?.companyUrls.includes(searchResult?.link);
                        return (
                          <Dropdown.Item
                            icon={isInList ? "check" : null}
                            key={list._id}
                            content={list.name}
                            onClick={() =>
                              isInList ? removeFromListByUrl(list, searchResult) : addToListByUrl(list, searchResult)
                            }
                          />
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </List.Content>
            </StyledListItem>
          ))}
        </List>
      </AnimatedSearchBox>

      <AnimatedButton
        circular
        color="google plus"
        content={isOpen ? null : "Search with Google"}
        icon={isOpen ? "chevron down" : "google"}
        onClick={() => openGoogleHelper(!isOpen)}
        $isExpanded={isOpen}
      />
    </GoogleHelperFloatingContainer>
  );
};

const mapStateToProps = (state) => ({ isOpen: state.googleHelper.isOpen });

const mapDispatchToProps = (dispatch) => ({
  openGoogleHelper: (isOpen) => dispatch(actions.googleHelper.openGoogleHelper(isOpen)),
});
const GoogleHelperContainer = connect(mapStateToProps, mapDispatchToProps)(GoogleHelper);

export default GoogleHelperContainer;
