import React from "react";
import { Form, Header } from "semantic-ui-react";
import util from "utils/utils";

const Assessment = ({ field, fieldValue, isEditing, updateIdea }) => {
  if (!isEditing)
    return (
      <p>
        <Header size="tiny">Score</Header>
        {fieldValue} / {field.options.availableScore}
      </p>
    );
  return (
    <Form>
      <Form.Input
        width="8"
        disabled={!isEditing}
        label={`Score (between 0 - ${field.options.availableScore})`}
        value={fieldValue ?? ""}
        placeholder="Enter a score..."
        icon={fieldValue === undefined || fieldValue === "" || fieldValue === null ? "wait" : "check"}
        onChange={(e) =>
          util.limitNumberInput(e, (val) =>
            updateIdea(val === "" ? val : Math.max(0, Math.min(field.options.availableScore, val)), [
              "assessment",
              field.id,
              "score",
            ]),
          )
        }
        type="number"
        min="0"
        max={String(field.options.availableScore)}
      />
    </Form>
  );
};

export default Assessment;
