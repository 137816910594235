import { OpenAPI } from "simplydo/interfaces";

export const invitations = (api) => ({
  get(success, fail) {
    api.authenticatedRequest("GET", "/invitations", null, success, fail);
  },
  getAccepted(success, fail) {
    api.authenticatedRequest("GET", "/invitations?accepted=true", null, success, fail);
  },
  findByToken(token, success, fail) {
    api.maybeAuthenticatedRequest("GET", "/invitations/find_by_token?token=" + token, null, success, fail);
  },
  getForType(
    forType,
    forId,
    success: (data: OpenAPI.GET<"/invitations/{for_type}/{for_id}">["response"]) => void,
    fail,
  ) {
    api.authenticatedRequest("GET", `/invitations/${forType}/${forId}`, null, success, fail);
  },
  create(data, success, fail) {
    api.authenticatedRequest("POST", "/invitations", data, success, fail);
  },
  createBulk(
    data: OpenAPI.POST<"/invitations/bulk">["requestBody"],
    success: (data: OpenAPI.POST<"/invitations/bulk">["response"]) => void,
    fail,
  ) {
    api.authenticatedRequest("POST", "/invitations/bulk", data, success, fail);
  },
  remove(id, success: (data: OpenAPI.DELETE<"/invitations/{id}">["response"]) => void, fail) {
    api.authenticatedRequest("DELETE", `/invitations/${id}`, null, success, fail);
  },
  accept(id, success, fail) {
    api.authenticatedRequest("POST", `/invitations/${id}/accept`, { targetUser: null }, success, fail);
  },
  acceptToken(token, success, fail) {
    api.authenticatedRequest("POST", "/invitations/accept_token", { token }, success, fail);
  },
  reject(id, success, fail) {
    api.authenticatedRequest("POST", `/invitations/${id}/reject`, null, success, fail);
  },
  resend(id, success, fail) {
    api.authenticatedRequest("POST", `/invitations/${id}/resend`, null, success, fail);
  },
});
