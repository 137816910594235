import React from "react";
import { styled } from "styled-components";

type CircularProgressProps = {
  size: number;
  strokeWidth?: number;
  progress: number;
  label?: string;
  color?: string;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  iconVisible?: boolean;
} & (
  | {
      label?: string;
      showPercentage?: never;
    }
  | {
      showPercentage?: boolean;
      label?: never;
    }
);

const Progress = styled.div<{ $size: number }>`
  position: relative;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;

  circle {
    transition:
      stroke 0.5s,
      stroke-dasharray 0.5s,
      stroke-dashoffset 0.5s;
  }
`;

const ProgressIcon = styled.div<{ $size?: number; $visible?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  text-align: center;

  && i {
    transition: font-size 0.6s cubic-bezier(0.25, -0.5, 0.5, 2.5);
    margin: 0 !important;
    font-size: ${(props) => (props.$visible ? props.$size / 2 : 0)}px;
    line-height: 1.1 !important;
  }
`;

const CircularProgress: React.FC<CircularProgressProps> = ({
  size,
  strokeWidth = 4,
  progress,
  showPercentage,
  label,
  color = "#007bff",
  style,
  icon,
  iconVisible = true,
}) => {
  const radius = size / 2 - strokeWidth / 2; // Subtract to account for stroke width
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - progress * circumference + Math.PI / 2;

  return (
    <Progress $size={size} style={style}>
      <svg width={size} height={size}>
        <circle cx={size / 2} cy={size / 2} r={radius} fill="transparent" stroke="#ccc" strokeWidth={strokeWidth} />

        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="transparent"
          stroke={progress === 0 ? "#ccc" : color}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          strokeLinecap="round"
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
      {icon ? (
        <ProgressIcon $size={size} $visible={iconVisible}>
          {icon}
        </ProgressIcon>
      ) : null}
      {showPercentage || label ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: size / 4,
            fontWeight: "bold",
            textAlign: "center",
            width: "100%",
          }}
        >
          {label ?? `${Math.round(progress * 100)}%`}
        </div>
      ) : null}
    </Progress>
  );
};

export default CircularProgress;
