import React, { lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Loader, Container, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ErrorBoundary from "components/lib/Error/ErrorBoundary";
import IdeaPDF, { MultiIdeaPDF } from "components/ideas/IdeaPDF";
import { Footer, NavBar } from "components/lib/UI";
import CompanyPDF from "components/innovationintelligence/Companies/CompanyPDF";
import useTheme from "theme/useTheme";

import CompleteVerifyEmail from "components/lib/Entry/CompleteVerifyEmail";
import SmartLink from "components/entry/SmartLink";
import LoginError from "components/entry/LoginError";
import LoginWithToken from "components/entry/LoginWithToken";
import AuthoriseToken from "components/entry/AuthoriseToken";
import Idea from "components/ideas";
import Challenge from "components/challenges/Challenge";

import UserSwitch from "./UserSwitch";
import NonUserSwitch from "./NonUserSwitch";
import SuspenseLoading from "./SuspenseLoading";
import { useAppSelector } from "store";

const Poll = lazy(() => import("components/polls/Poll"));
const MobileNewIdeaButton = lazy(() => import("components/lib/MobileNewIdeaButton"));

const StyledDashboard = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;
const StyledDashboardContent = styled.div.attrs({ className: "app-content" })`
  flex: 1 0 auto;
  position: relative;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
`;

const AppRouting = () => {
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user);
  const isGettingUser = useAppSelector((state) => state.auth.isGettingUser);
  const gettingUserError = useAppSelector((state) => state.auth.errorMessage);
  const showLogin = useAppSelector((state) => state.auth.showLogin);
  const ipBlocked = useAppSelector((state) => state.auth.ipBlocked);

  return (
    <Routes>
      <Route path="/login/error" element={<LoginError />} />
      <Route path="/login/with" element={<LoginWithToken />} />
      <Route path="/start" element={<SmartLink />} />
      <Route path="/tokens/authorise" element={<AuthoriseToken />} />
      <Route path="/verify-email" element={<CompleteVerifyEmail />} />
      <Route path="/polls/:id" element={<Poll />} />
      <Route
        path="/challenges/:id/share"
        element={
          <Container style={{ marginTop: 40 }}>
            <Challenge />
          </Container>
        }
      />
      <Route path="/companies/:id/pdf" element={<CompanyPDF />} />
      <Route path="/ideas/multi-pdf/:ids" element={<MultiIdeaPDF />} />
      <Route path="/ideas/:id/pdf" element={<IdeaPDF />} />
      <Route
        path="/ideas/:id/share"
        element={
          <Container style={{ marginTop: 40 }}>
            <Idea />
          </Container>
        }
      />
      <Route
        path="*"
        element={
          <>
            {isGettingUser ? (
              <Loader active inline="centered" style={{ marginTop: 30 }} />
            ) : (
              <StyledDashboard>
                <NavBar />
                <ErrorBoundary theme={theme} pathName={location.pathname}>
                  <StyledDashboardContent>
                    {ipBlocked ? (
                      <Container style={{ marginTop: 20 }}>
                        <Message>
                          <p>
                            <strong>Access denied</strong>
                          </p>
                          <p>
                            The network or location you are trying to access SimplyDo from is not permitted in your
                            account's security settings. Please reach out to an administrator in your organisation for
                            assistance.
                          </p>
                        </Message>
                      </Container>
                    ) : (
                      <>
                        <Suspense fallback={<SuspenseLoading />}>
                          {user && <UserSwitch />}
                          <NonUserSwitch />
                          {gettingUserError && !showLogin ? (
                            <Container style={{ marginTop: 20 }}>
                              <Message>
                                <p>
                                  <strong>{t("main.problemLoading")}</strong>
                                </p>
                                <p>{gettingUserError}</p>
                              </Message>
                            </Container>
                          ) : null}
                          {theme.sizes.isMobile && user && <MobileNewIdeaButton />}
                        </Suspense>
                      </>
                    )}
                  </StyledDashboardContent>
                </ErrorBoundary>
                <Footer />
              </StyledDashboard>
            )}
          </>
        }
      />
    </Routes>
  );
};

export default AppRouting;
