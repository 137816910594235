import React from "react";
import websocketApi from "api/websocket";
import actions from "actions";
import util from "utils/utils";
import * as Sentry from "@sentry/react";
// @ts-ignore
import i18n from "src/i18n";
import moment from "moment";
import { store } from "store";
import { unsubscribeFromPush } from "utils/pushMessaging";
import toast from "react-hot-toast";
import { Button } from "semantic-ui-react";
import { SearchParams } from "simplydo/core";

export const auth = (api) => ({
  store,
  localLogin(token, preventStore, success = () => {}, fail = (_err) => {}) {
    if (!token || !token.length) {
      this.store.dispatch(actions.auth.loginFail());
      success();
      return;
    }
    if (!preventStore && util.localStorageIsSupported()) {
      localStorage.setItem("auth_token", token);
    }
    api.token = token;
    this.store.dispatch(actions.entry.clear());
    this.store.dispatch(actions.auth.login());
    this.store.dispatch(actions.auth.startGettingUser());

    // Get UTM from URL parameters if available
    // Use this to track engagement from shortened links
    const params = new SearchParams(window.location.search);
    const utmReferrer = params.get("utm-link");

    api.users.me(
      utmReferrer,
      (user) => {
        websocketApi.connect(
          () =>
            new Promise((resolve) =>
              api.auth.requestWebsocketToken(
                ({ token: newWebsocketToken }) => resolve(newWebsocketToken),
                () => resolve(null),
              ),
            ),
        );
        // After resuming a session we clear the link and challenge utms
        this.store.dispatch(actions.auth.setClearUtm(true));
        this.store.dispatch(actions.user.receiveUser(user));
        this.store.dispatch(actions.auth.finishGettingUser());
        if (util.localStorageIsSupported()) {
          const ideaViewPreference = localStorage.getItem("ideaViewTypePreference");
          if (ideaViewPreference) {
            if (
              ideaViewPreference === "spreadsheet" &&
              user?.ownerOrganisation?.enabledFeatures?.includes("ideaSpreadsheetView")
            ) {
              this.store.dispatch(actions.challenges.setIdeaViewType("spreadsheet"));
              this.store.dispatch(
                actions.challenges.updateChallengeFilters({
                  ideaLimit: 10000,
                }),
              );
            } else if (ideaViewPreference !== "board") {
              this.store.dispatch(actions.challenges.setIdeaViewType(ideaViewPreference));
            }
          }
        }
        const searchParams = new SearchParams(window.location.search);
        const paramLang = searchParams.get("lang");
        if (paramLang) {
          i18n.changeLanguage(paramLang);
          if (user.language !== paramLang) {
            toast(
              (t) =>
                React.createElement(
                  "div",
                  {
                    style: {
                      fontSize: "0.9em",
                    },
                  },
                  [
                    "You followed a link with a different language preference than yours.",
                    React.createElement(
                      "div",
                      {
                        style: {
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "1rem",
                        },
                      },
                      [
                        React.createElement(
                          Button,
                          {
                            size: "small",
                            onClick: () => {
                              i18n.changeLanguage(user.language);
                              toast.dismiss(t.id);
                            },
                          },
                          "Revert",
                        ),
                        React.createElement(
                          Button,
                          {
                            size: "small",
                            primary: true,
                            onClick: () => {
                              toast.dismiss(t.id);
                            },
                          },
                          "Keep",
                        ),
                      ],
                    ),
                  ],
                ),
              {
                duration: Infinity,
              },
            );
          }
        } else if (user.language) {
          i18n.changeLanguage(user.language);
        } else {
          i18n.changeLanguage("en");
        }
        Sentry.setUser({
          id: user._id,
          username: user.profile.fullName,
          organisation: user.ownerOrganisation.name,
          orgId: user.ownerOrganisation._id,
        });
        success();
      },
      (err) => {
        fail(err);
        this.store.dispatch(actions.auth.getUserFail(err));
      },
    );
  },
  resumeSession(success, fail) {
    if (!util.localStorageIsSupported()) {
      return this.localLogin(null, success, fail);
    }
    const token = localStorage.getItem("auth_token");
    if (token) {
      api.token = token;
      const tokenData = util.parseJwt(token);
      // Times expiry by a thousand to get from milliseconds to seconds
      const expiryDate = moment(tokenData.exp * 1000);
      // Get diff between now, and the expiry
      const diffFromNow = expiryDate.diff(moment(), "days");
      // If diff is less than 29, we request a new token
      if (diffFromNow < 29) {
        this.refreshAuthToken(
          (newToken) => {
            // New token is received and we continue local login using new token
            this.localLogin(newToken, false, success, fail);
          },
          () => {
            // On failure we attempt to revert using old token
            this.localLogin(token, false, success, fail);
          },
        );
      } else this.localLogin(token, false, success, fail);
    } else {
      // The success function simply tells the main UI that the token refresh check is done
      this.localLogin(token, success, fail);
    }
  },
  login(data, success, fail) {
    const { email, password, otp, exchangeToken, org, challengeResponse, utmLink } = data;
    const loginData = {
      email,
      password,
      otp,
      exchangeToken,
      org,
      challengeResponse,
      utmLink,
    };
    const params = new SearchParams(window.location.search);
    const stateParam = params.get("state");
    if (stateParam) {
      loginData.state = stateParam;
    }
    api.unauthenticatedRequest(
      "POST",
      "/accounts/login",
      loginData,
      (responseData) => {
        // After logging in we clear the link and challenge utms
        this.store.dispatch(actions.auth.setClearUtm(true));
        if (responseData.token) {
          this.localLogin(responseData.token);
          success && success(responseData.token);
        } else {
          // e.g. when OTP required
          success(responseData);
        }
      },
      fail,
    );
  },
  loginWithToken(token, success, fail) {
    api.unauthenticatedRequest(
      "POST",
      "/accounts/login/magic",
      { token },
      (data) => {
        this.localLogin(data.token, false, success);
      },
      fail,
    );
  },
  requestLoginEmail(email, success, fail) {
    api.unauthenticatedRequest(
      "POST",
      "/accounts/tokens/authorise",
      { email },
      (data) => {
        success && success(data);
      },
      fail,
    );
  },
  authoriseToken(authenticationToken, securityPhrase, success, fail) {
    api.unauthenticatedRequest(
      "PUT",
      "/accounts/tokens/authorise",
      { authenticationToken, securityPhrase },
      (data) => {
        success && success(data);
      },
      fail,
    );
  },
  verifyAccount(organisation, email, challengeResponse, success, fail) {
    api.unauthenticatedRequest(
      "GET",
      `/accounts?org_id=${organisation}&email=${encodeURIComponent(email)}&challenge_response=${challengeResponse}`,
      null,
      success,
      fail,
    );
  },
  register(
    organisation,
    email,
    password,
    firstName,
    lastName,
    challengeResponse,
    agreedTermsVersion,
    utmLink,
    utmChallenge,
    inviteToken,
    success,
    fail,
  ) {
    api.unauthenticatedRequest(
      "POST",
      "/accounts",
      {
        organisation,
        email,
        password,
        firstName,
        lastName,
        challengeResponse,
        agreedTermsVersion,
        utmLink,
        utmChallenge,
        inviteToken,
      },
      ({ token, addedToOrganisation }) => {
        // After register in we clear the link and challenge utms
        this.store.dispatch(actions.auth.setClearUtm(true));
        this.localLogin(token);
        success && success(addedToOrganisation);
      },
      fail,
    );
  },
  localLogout(success) {
    if (util.localStorageIsSupported()) {
      localStorage.removeItem("auth_token");
    }
    // @ts-ignore
    window.$chatwoot?.reset();
    websocketApi.disconnect();
    api.token = null;
    this.store.dispatch(actions.messages.logout());
    this.store.dispatch(actions.user.logout());
    this.store.dispatch(actions.auth.logout());
    success && success();
  },
  logout(success, fail) {
    unsubscribeFromPush(() => {
      api.authenticatedRequest(
        "POST",
        "/accounts/logout",
        null,
        (data) => {
          this.localLogout(() => {
            success && success();
            if (data && data.logoutSaml) util.logoutWithSaml(data.nameId, data.logoutCode);
          });
        },
        fail,
      );
    });
  },
  logoutOthers(success, fail) {
    api.authenticatedRequest("POST", "/accounts/logout/others", null, success, fail);
  },
  sendPasswordResetEmail(email, organisation, success, fail) {
    api.unauthenticatedRequest("POST", "/accounts/password/reset", { email, organisation }, success, fail);
  },
  updatePassword(currentPassword, newPassword, success, fail) {
    api.authenticatedRequest("PUT", "/accounts/password", { currentPassword, newPassword }, success, fail);
  },
  adminUpdatePassword(userId, data, success, fail) {
    api.authenticatedRequest("PUT", `/accounts/${userId}/password`, data, success, fail);
  },
  updatePasswordWithToken(token, newPassword, success, fail) {
    api.unauthenticatedRequest("PUT", "/accounts/password", { token, newPassword }, success, fail);
  },
  agreeToTerms(version, success, fail) {
    api.authenticatedRequest("PUT", `/accounts/terms/${version}/agree`, null, success, fail);
  },
  provideEmail(data, success, fail) {
    api.authenticatedRequest("PUT", "/accounts/email/add", data, success, fail);
  },
  requestEmailVerification(token, success, fail) {
    const params = new SearchParams({ token });
    api.maybeAuthenticatedRequest("POST", `/accounts/email/verify${params.toSafeString()}`, null, success, fail);
  },
  adminUpdateEmail(userId, data, success, fail) {
    api.authenticatedRequest("PUT", `/accounts/${userId}/email`, data, success, fail);
  },
  verifyEmail(token, success, fail) {
    api.unauthenticatedRequest("PUT", "/accounts/email/verify", { token }, success, fail);
  },
  getApiTokens(success, fail) {
    api.authenticatedRequest("GET", "/accounts/api/tokens", null, success, fail);
  },
  deleteApiToken(tokenId, tokenIndex, success, fail) {
    const tokenData: { tokenId?: string; tokenIndex?: string } = {};
    if (tokenId !== undefined) tokenData.tokenId = tokenId;
    if (tokenIndex !== undefined) tokenData.tokenIndex = tokenIndex;
    const params = new SearchParams(tokenData);

    api.authenticatedRequest("DELETE", `/accounts/api/tokens?${params.toString()}`, null, success, fail);
  },
  viewExternalApiToken(tokenId, tokenIndex, success, fail) {
    const tokenData: { tokenId?: string; tokenIndex?: string } = {};
    if (tokenId !== undefined) tokenData.tokenId = tokenId;
    if (tokenIndex !== undefined) tokenData.tokenIndex = tokenIndex;
    const params = new SearchParams(tokenData);

    api.authenticatedRequest("GET", `/accounts/api/tokens/external?${params.toString()}`, null, success, fail);
  },
  createExternalApiToken(data, success, fail) {
    api.authenticatedRequest("POST", "/accounts/api/tokens/external", data, success, fail);
  },
  deleteExternalApiToken(tokenId, tokenIndex, success, fail) {
    const tokenData: { tokenId?: string; tokenIndex?: string } = {};
    if (tokenId !== undefined) tokenData.tokenId = tokenId;
    if (tokenIndex !== undefined) tokenData.tokenIndex = tokenIndex;
    const params = new SearchParams(tokenData);

    api.authenticatedRequest("DELETE", `/accounts/api/tokens/external?${params.toString()}`, null, success, fail);
  },
  refreshAuthToken(success, fail) {
    api.authenticatedRequest("POST", "/accounts/tokens/refresh", null, success, fail);
  },
  generateOtpUri(success, fail) {
    api.authenticatedRequest("POST", "/accounts/mfa", null, success, fail);
  },
  disableOtp(data, success, fail) {
    api.authenticatedRequest("DELETE", "/accounts/mfa", data, success, fail);
  },
  enableOtp(data, success, fail) {
    api.authenticatedRequest("PUT", "/accounts/mfa", data, success, fail);
  },
  updateEmailAddress(email, success, fail) {
    api.authenticatedRequest("PUT", "/accounts/email", { email }, success, fail);
  },
  generateExchangeToken(success, fail) {
    api.authenticatedRequest("POST", "/accounts/tokens", null, success, fail);
  },
  addPhoneNumber(number, success, fail) {
    api.authenticatedRequest("POST", "/accounts/phone", { number }, success, fail);
  },
  removePhoneNumber(number, success, fail) {
    api.authenticatedRequest("DELETE", "/accounts/phone", { number }, success, fail);
  },
  verifyPhoneNumber(number, code, success, fail) {
    api.authenticatedRequest("POST", "/accounts/phone/verify", { number, code }, success, fail);
  },
  resendPhoneNumberVerification(number, success, fail) {
    api.authenticatedRequest("POST", "/accounts/phone/verify/new", { number }, success, fail);
  },
  requestWebsocketToken(success, fail) {
    api.authenticatedRequest("GET", "/accounts/websocket/token", null, success, fail);
  },
  requestReportsToken(path, success, fail) {
    api.authenticatedRequest("GET", `/accounts/reports/token?path=${encodeURIComponent(path)}`, null, success, fail);
  },
  requestWebdriverToken(path, success, fail) {
    api.authenticatedRequest("GET", `/accounts/webdriver/token?path=${encodeURIComponent(path)}`, null, success, fail);
  },
  addFCMPushToken(token, success, fail) {
    return api.authenticatedRequest("POST", "/accounts/push/fcm_tokens", { token }, success, fail);
  },
  removeFCMPushToken(token, success, fail) {
    return api.authenticatedRequest("DELETE", "/accounts/push/fcm_tokens", { token }, success, fail);
  },
});
