import React, { useState, useEffect, useCallback, SetStateAction, Dispatch, useMemo } from "react";
import { Input, Pagination } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import { Link } from "react-router-dom";
import ConfigurableTable from "components/lib/ConfigurableTable";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import { ImageWithFallback } from "components/lib/ImageWithFallback";
import { useAppSelector } from "store";
import useThrottle from "utils/useThrottle";

type RoleEditorAssignmentProps = {
  forType: "organisation" | "group" | "global" | "ideaBusinessProfile" | "challenge";
  forId: string;
  usingRole: Other.IRole;
  setRoles: Dispatch<SetStateAction<Other.IRole[]>>;
};

const RoleEditorAssignment = ({ forType, forId, setRoles, usingRole }: RoleEditorAssignmentProps) => {
  const [usersSearchState, setUsersSearchState] = useState<{
    loading: boolean;
    query: string;
    page: number;
  }>({
    loading: false,
    query: "",
    page: 1,
  });
  const [potentialUsersSearchState, setPotentialUsersSearchState] = useState<{
    loading: boolean;
    query: string;
    page: number;
  }>({
    loading: false,
    query: "",
    page: 1,
  });
  const [usersState, setUsersState] = useState<{
    users: Other.IUser[];
    total: number;
    nextPageAvailable: boolean;
    prevPageAvailable: boolean;
  }>({
    users: [],
    total: 0,
    nextPageAvailable: false,
    prevPageAvailable: false,
  });
  const [potentialUserState, setPotentialUserState] = useState<{
    users: Other.IUser[];
    total: number;
    nextPageAvailable: boolean;
    prevPageAvailable: boolean;
  }>({
    users: [],
    total: 0,
    nextPageAvailable: false,
    prevPageAvailable: false,
  });

  const user = useAppSelector((state) => state.user);

  const potentialUsersWithRole = useMemo(() => {
    if (!usingRole?._id) {
      return [];
    }
    return potentialUserState.users.filter((u) => (u.roleIds ?? []).includes(usingRole._id)).map((u) => u._id);
  }, [potentialUserState.users, usingRole?._id]);

  const assignedUsersWithRole = useMemo(() => {
    if (!usingRole?._id) {
      return [];
    }
    return usersState.users.filter((u) => (u.roleIds ?? []).includes(usingRole._id)).map((u) => u._id);
  }, [usersState.users, usingRole?._id]);

  const usingForId = forType === "global" ? "global" : forId;

  const fetchAssignedUsers = useThrottle(
    (query, page) => {
      if (!forId || !forType) return;

      api.roles.getAssignedRoleUsers(
        forType,
        forId,
        usingRole._id,
        { query, page },
        (data) => {
          setUsersSearchState((prevSearchState) => ({ ...prevSearchState, loading: false }));
          setUsersState((prevState) => ({
            ...prevState,
            users: data.users,
            total: data.total,
            nextPageAvailable: data.nextPageAvailable,
            prevPageAvailable: data.prevPageAvailable,
          }));
        },
        (err) => {
          setUsersSearchState((prevSearchState) => ({ ...prevSearchState, loading: false }));
          toast.error(err.message);
        },
      );
    },
    250,
    [forId, forType, usingRole._id],
  );

  const getAssignedUsers = useCallback(
    (query, page) => {
      setUsersSearchState((prevSearchState) => ({ ...prevSearchState, loading: true, query, page }));
      fetchAssignedUsers(query, page);
    },
    [fetchAssignedUsers],
  );

  const fetchPotentialUsers = useThrottle(
    (query, page) => {
      if (!forId || !forType) return;

      api.roles.getPotentialRoleUsers(
        forType,
        forId,
        usingRole._id,
        { query, page },
        (data) => {
          setPotentialUsersSearchState((prevSearchState) => ({ ...prevSearchState, loading: false }));
          setPotentialUserState((prevState) => ({
            ...prevState,
            users: data.users,
            total: data.total,
            nextPageAvailable: data.nextPageAvailable,
            prevPageAvailable: data.prevPageAvailable,
          }));
        },
        (err) => {
          setPotentialUsersSearchState((prevSearchState) => ({ ...prevSearchState, loading: false }));
          toast.error(err.message);
        },
      );
    },
    250,
    [forId, forType, usingRole._id],
  );

  const getPotentialUsers = useCallback(
    (query, page) => {
      setPotentialUsersSearchState((prevSearchState) => ({ ...prevSearchState, loading: true, query, page }));
      fetchPotentialUsers(query, page);
    },
    [fetchPotentialUsers],
  );

  useEffect(() => {
    getPotentialUsers("", 1);
    getAssignedUsers("", 1);
  }, [getPotentialUsers, getAssignedUsers, usingRole.isDefaultRole]);

  const updateUserRoles = useCallback((userId: string, roleId: string) => {
    setUsersState((prevUsersState) => ({
      ...prevUsersState,
      users: prevUsersState.users.map((prevUser) => {
        if (prevUser._id === userId) {
          return {
            ...prevUser,
            roleIds: prevUser.roleIds?.includes(roleId)
              ? prevUser.roleIds.filter((role) => role !== roleId)
              : [...(prevUser.roleIds ?? []), roleId],
          };
        }
        return prevUser;
      }),
    }));
    setPotentialUserState((prevUsersState) => ({
      ...prevUsersState,
      users: prevUsersState.users.map((prevUser) => {
        if (prevUser._id === userId) {
          return {
            ...prevUser,
            roleIds: prevUser.roleIds?.includes(roleId)
              ? prevUser.roleIds.filter((role) => role !== roleId)
              : [...(prevUser.roleIds ?? []), roleId],
          };
        }
        return prevUser;
      }),
    }));
  }, []);

  const assignRoleToUser = useCallback(
    (userId: string) => {
      api.roles.assignRole(
        forType,
        usingForId,
        usingRole._id,
        [userId],
        () => {
          toast.success(`Role ${usingRole.name} assigned to user`);
          updateUserRoles(userId, usingRole._id);
          // setUsersState((prevUsersState) => ({
          //   ...prevUsersState,
          //   users: [...prevUsersState.users.filter((user) => user._id !== userId)],
          // }));
          getAssignedUsers(usersSearchState.query, usersSearchState.page);
          setRoles((prevRoles) =>
            prevRoles.map((role) => {
              if (role._id === usingRole._id) {
                return {
                  ...role,
                  userCount: (role.userCount ?? 0) + 1,
                };
              }
              return role;
            }),
          );
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [forType, setRoles, updateUserRoles, usingForId, usingRole._id, usingRole.name, usersSearchState, getAssignedUsers],
  );

  const unassignRoleFromUser = useCallback(
    (userId: string) => {
      api.roles.unassignRole(
        forType,
        usingForId,
        usingRole._id,
        [userId],
        () => {
          toast.success(`Role ${usingRole?.name} unassigned from user`);
          updateUserRoles(userId, usingRole._id);
          setRoles((prevRoles) =>
            prevRoles.map((role) => {
              if (role._id === usingRole._id) {
                return {
                  ...role,
                  userCount: (role.userCount ?? 0) - 1,
                };
              }
              return role;
            }),
          );
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [forType, setRoles, updateUserRoles, usingForId, usingRole._id, usingRole?.name],
  );

  const onSelectUser = useCallback(
    (selectedUser) => {
      const userHasRole = selectedUser.roleIds?.includes(usingRole._id);
      if (userHasRole) {
        if (user._id === selectedUser._id) {
          util
            .confirm(
              "Unassign yourself",
              "Are you sure you want to unassign yourself from this role? This might result in you losing access to this resource.",
            )
            .then(() => {
              unassignRoleFromUser(selectedUser._id);
            })
            .catch(() => {});
        } else {
          unassignRoleFromUser(selectedUser._id);
        }
      } else {
        assignRoleToUser(selectedUser._id);
      }
    },
    [assignRoleToUser, unassignRoleFromUser, usingRole._id, user._id],
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      {usersState?.users?.length > 0 ? (
        <div>
          <h5>Currently assigned users</h5>
          <ConfigurableTable
            tableKey="roleEditorAssignment"
            data={usersState.users}
            keyExtractor={(user) => user._id}
            selectedKeys={assignedUsersWithRole}
            onSelect={onSelectUser}
            preventSelectAll
            columns={[
              {
                key: "image",
                name: "",
                settingName: "Image",
                width: 40,
                center: true,
                render: ({ item }) => (
                  <Link to={`/users/${item._id}`}>
                    <ImageWithFallback
                      style={{ objectFit: "cover" }}
                      avatar
                      src={util.avatarUrl(item)}
                      fallbackSrc={util.avatarUrl()}
                    />
                  </Link>
                ),
              },
              {
                key: "firstName",
                name: "First name",
                render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.firstName}</Link>,
                sortable: true,
              },
              {
                key: "lastName",
                name: "Last name",
                render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.lastName}</Link>,
                sortable: true,
              },
              {
                key: "email",
                name: "Email",
                render: ({ item }) => (item.emails?.length ? item.emails[0].address : ""),
                sortable: false,
              },
              {
                key: "department",
                name: "Department",
                render: ({ item }) => item.profile.department,
                sortable: false,
              },
            ]}
          />
        </div>
      ) : null}
      {usersState.prevPageAvailable || usersState.nextPageAvailable ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            activePage={usersSearchState.page}
            totalPages={Math.ceil(usersState.total / 10)}
            onPageChange={(_, { activePage }) => {
              getAssignedUsers(usersSearchState.query, activePage as number);
            }}
            ellipsisItem={null}
          />
        </div>
      ) : null}
      <h5>Assign new users</h5>
      <Input
        icon="search"
        placeholder="Search users..."
        value={potentialUsersSearchState.query}
        fluid
        onChange={(e) => {
          getPotentialUsers(e.target.value, 1);
        }}
        loading={potentialUsersSearchState.loading}
        style={{ marginBottom: 10 }}
      />
      <ConfigurableTable
        tableKey="roleEditorAssignment"
        data={potentialUserState.users}
        keyExtractor={(user) => user._id}
        selectedKeys={potentialUsersWithRole}
        onSelect={onSelectUser}
        preventSelectAll
        columns={[
          {
            key: "image",
            name: "",
            settingName: "Image",
            width: 40,
            center: true,
            render: ({ item }) => (
              <Link to={`/users/${item._id}`}>
                <ImageWithFallback
                  style={{ objectFit: "cover" }}
                  avatar
                  src={util.avatarUrl(item)}
                  fallbackSrc={util.avatarUrl()}
                />
              </Link>
            ),
          },
          {
            key: "firstName",
            name: "First name",
            render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.firstName}</Link>,
            sortable: true,
          },
          {
            key: "lastName",
            name: "Last name",
            render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.lastName}</Link>,
            sortable: true,
          },
          {
            key: "email",
            name: "Email",
            render: ({ item }) => (item.emails?.length ? item.emails[0].address : ""),
            sortable: false,
          },
          {
            key: "department",
            name: "Department",
            render: ({ item }) => item.profile.department,
            sortable: false,
          },
        ]}
      />

      {potentialUserState.prevPageAvailable || potentialUserState.nextPageAvailable ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            activePage={potentialUsersSearchState.page}
            totalPages={Math.ceil(potentialUserState.total / 10)}
            onPageChange={(_, { activePage }) => {
              getPotentialUsers(potentialUsersSearchState.query, activePage as number);
            }}
            ellipsisItem={null}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RoleEditorAssignment;
