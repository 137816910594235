import React, { CSSProperties } from "react";
import { Icon, Popup, SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.5px solid #c9c9c9;
  border-radius: 5px;
  background-color: #fff;
  padding: 6px 3px;
`;

export const ActionArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 5px 0 5px;
  border-top: 0.5px solid silver;
  padding-top: 5px;
  > div {
    display: flex;
    align-items: center;
  }
  > .ui.button {
    margin: 0;
  }
  i {
    margin: 0 0 3px 0;
  }
`;

export const StyledActionButton = styled.div<{ $disabled: boolean }>`
  padding: 3px 7.5px;
  border-radius: 3px;
  display: flex;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 33px;
  ${({ $disabled }) => !$disabled && "cursor: pointer;"}
  ${({ $disabled }) => $disabled && "opacity: 0.5;"}
  span {
    display: block;
    margin-right: 7.5px;
    color: gray;
    font-weight: 600;
    text-transform: uppercase;
  }
  i {
    color: gray;
    height: 1.2em;
    font-size: 1.2em;
    margin: 0;
  }
  ${({ $disabled }) =>
    !$disabled &&
    `
    &:hover {
      background-color: #e9ebee;
    }
  `}
`;

type ActionButtonProps = {
  title?: string;
  position?:
    | "top center"
    | "top left"
    | "top right"
    | "bottom right"
    | "bottom left"
    | "right center"
    | "left center"
    | "bottom center";
  iconName: SemanticICONS;
  content?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  loading?: boolean;
  style?: CSSProperties;
};

export const ActionButton = ({
  title,
  position,
  iconName,
  content,
  disabled,
  onClick,
  loading: _loading,
  style,
}: ActionButtonProps) => (
  <Popup
    position={position || "top center"}
    content={title}
    disabled={!title}
    trigger={
      <StyledActionButton onClick={disabled ? null : onClick} $disabled={disabled} style={style}>
        {content ? <span>{content}</span> : null}
        <Icon name={iconName} />
      </StyledActionButton>
    }
  />
);
