import React, { useContext } from "react";
import { Popup, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import util from "utils/utils";
import { useTranslation } from "react-i18next";

import { ShadowedCard, FakeLink } from "components/lib/UI";

const IdeaCardComponent = styled(ShadowedCard)`
  .cover-header {
    width: 100%;
    height: 50px;
    background-size: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    ${({ $hasProgress }) =>
      !$hasProgress &&
      `
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    `}
  }
  .name-container {
    width: 100%;
    overflow-wrap: wrap;
    margin-bottom: 2px;
  }
  .name-text {
    max-width: 100%;
    color: rgb(77, 73, 73);
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .footer-container {
    text-align: center;
    font-weight: 600;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .owner-challenge-link {
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.4);
    text-decoration: underline;
  }
  .owner-name {
    border-bottom: 1px dotted ${({ theme }) => theme.primaryColour};
  }
  .owner-link {
    margin-bottom: 5px;
  }
  .summarisation {
    color: rgba(0, 0, 0, 0.4);
    max-height: calc(1em * 5); /* Adjust the line height as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

const IdeaCard = ({ onHeaderClicked, idea }) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <IdeaCardComponent onCardClicked={() => onHeaderClicked()}>
      <div
        style={{
          position: "relative",
          backgroundImage: `url(${util.mixinCssUrlFallback(util.ideaCoverImage(idea), util.ideaCoverImage())})`,
        }}
        className="cover-header"
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.2)",
          }}
        />
      </div>

      <div className="content">
        <div className="name-container">
          <h4 className="name-text">{idea.name}</h4>
        </div>
        {idea.ownerChallenge && <div className="owner-challenge-link">{idea.ownerChallenge?.name}</div>}

        {idea?.owner?._id ? (
          <div className="owner-link">
            {idea.lockedOwnerIdeaBusinessProfile ? (
              <FakeLink>{idea.lockedOwnerIdeaBusinessProfile?.name}</FakeLink>
            ) : (
              <Link className="owner-name" to={`/users/${idea?.owner?._id}`}>
                {idea.owner.profile.fullName}
              </Link>
            )}
            {(idea.collaborators?.length || !!idea.lockedOwnerIdeaBusinessProfile?.name) && idea.authors?.length ? (
              <Popup
                on="click"
                disabled={!idea.lockedOwnerIdeaBusinessProfile?.name && (idea.authors?.length || 0) < 2}
                content={
                  idea.authors?.length &&
                  idea.authors.filter((a) => !!idea.lockedOwnerIdeaBusinessProfile?.name || a._id !== idea.user).length
                    ? idea.authors
                        .filter((a) => !!idea.lockedOwnerIdeaBusinessProfile?.name || a._id !== idea.user)
                        .map((author) => (
                          <Link key={author._id} to={`/users/${author._id}`}>
                            {author.profile.fullName}
                          </Link>
                        ))
                        .reduce((prev, curr) => [prev, ", ", curr])
                    : null
                }
                trigger={
                  <FakeLink>{` + ${util.pluralise(idea.lockedOwnerIdeaBusinessProfile?.name ? (idea.collaborators?.length ?? 0) + 1 : idea.collaborators?.length ?? 0, "other", "others")}`}</FakeLink>
                }
              />
            ) : null}
          </div>
        ) : null}
        {idea?.summarisedContent && (
          <Popup
            style={{ marginTop: 20 }}
            on="hover"
            content={idea.summarisedContent}
            trigger={<p className="summarisation">{idea.summarisedContent}</p>}
          />
        )}
      </div>
      <div className="extra-content footer-container" style={{ backgroundColor: theme.primaryColour }}>
        <div style={{ color: "#fff", textTransform: "uppercase" }}>
          View {t("generic.idea")} <Icon name="arrow alternate circle right outline" />
        </div>
      </div>
    </IdeaCardComponent>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const IdeaCardContainer = connect(mapStateToProps)(IdeaCard);

export default IdeaCardContainer;
