import React, { useCallback } from "react";
import { Button, ButtonProps, Icon, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import actions from "actions";
import api from "api";
import util from "utils/utils";
import { useAppDispatch, useAppSelector } from "store";
import { OpenAPI } from "simplydo/interfaces";

type FollowButtonProps = {
  forType: string;
  forId: string;
  onFollow: () => void;
  onUnfollow: () => void;
  tooltip: string;
  buttonProps?: ButtonProps;
};

const FollowButton = ({ forType, forId, onFollow, onUnfollow, tooltip, buttonProps }: FollowButtonProps) => {
  const user = useAppSelector((state) => state.user);
  const followObject = util.userIsFollowingContextObject(user, forType, forId);

  const dispatch = useAppDispatch();
  const updateUser = useCallback((user) => dispatch(actions.user.receiveUser(user)), [dispatch]);

  return (
    <Popup
      on="hover"
      trigger={
        <div>
          {followObject.following ? (
            <Button
              size="mini"
              {...buttonProps}
              secondary
              onClick={() => {
                if (!onUnfollow) {
                  return;
                }
                followObject.following = false;
                followObject.unfollowed = true;

                const oldFollowing = (user?.following || []).filter((f) => f._id !== forId);
                const newFollowing = [followObject, ...oldFollowing];

                updateUser({ ...user, following: newFollowing });
                onUnfollow();
              }}
            >
              <Icon name="star" />
              Following
            </Button>
          ) : (
            <Button
              size="mini"
              {...buttonProps}
              basic
              inverted
              onClick={() => {
                if (!onFollow) {
                  return;
                }
                followObject.following = true;
                followObject.unfollowed = false;

                const oldFollowing = (user?.following || []).filter((f) => f._id !== forId);
                const newFollowing = [followObject, ...oldFollowing];

                updateUser({ ...user, following: newFollowing });
                onFollow();
              }}
            >
              <Icon name="star outline" />
              Follow
            </Button>
          )}
        </div>
      }
      content={tooltip}
    />
  );
};

type ChallengeFollowButtonProps = {
  challenge: OpenAPI.Schemas["Challenge"];
  onFollow: () => void;
  onUnfollow: () => void;
  buttonProps?: ButtonProps;
};

export const ChallengeFollowButton = ({
  challenge,
  onFollow = () => {},
  onUnfollow = () => {},
  buttonProps,
}: ChallengeFollowButtonProps) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);

  const followChallenge = useCallback(() => {
    api.challenges.follow(
      challenge._id,
      true,
      () => {
        toast.success(`${t("common:capitalise", { key: "generic.challenge" })} followed`);
        onFollow();
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [challenge, onFollow, t]);

  const unfollowChallenge = useCallback(() => {
    api.challenges.follow(
      challenge._id,
      false,
      () => {
        toast(`${t("common:capitalise", { key: "generic.challenge" })} unfollowed`);
        onUnfollow();
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [challenge, onUnfollow, t]);

  const isChallengeAdmin = util.hasPermission(user, "challenge.editSettings", challenge?._id);
  const isFollowing = util.userIsFollowingContext(user, "challenge", challenge?._id);
  const showUnfollowInfo = isFollowing && isChallengeAdmin;

  if (!user) return null;
  return (
    <FollowButton
      forType="challenge"
      forId={challenge._id}
      onFollow={!isChallengeAdmin && followChallenge}
      onUnfollow={!isChallengeAdmin && unfollowChallenge}
      tooltip={
        showUnfollowInfo
          ? `You cannot unfollow ${t("generic.challengeWithArticle")} you are currently managing`
          : `Follow ${t("generic.challengeWithArticle")} to receive updates on your homepage, and the ${t("generic.challenge")} will appear on your '${t("common:capitalise", { key: "generic.challenge" })}' page`
      }
      buttonProps={buttonProps}
    />
  );
};

type IdeaFollowButtonProps = {
  idea: OpenAPI.Schemas["Idea"];
  onFollow: () => void;
  onUnfollow: () => void;
  buttonProps?: ButtonProps;
};

export const IdeaFollowButton = ({
  idea,
  onFollow = () => {},
  onUnfollow = () => {},
  buttonProps,
}: IdeaFollowButtonProps) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);

  const followIdea = useCallback(() => {
    api.ideas.follow(
      idea._id,
      true,
      () => {
        toast.success(`${t("common:capitalise", { key: "generic.idea" })} followed`);
        onFollow();
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [idea, onFollow, t]);

  const unfollowIdea = useCallback(() => {
    api.ideas.follow(
      idea._id,
      false,
      () => {
        toast(`${t("common:capitalise", { key: "generic.idea" })} unfollowed`);
        onUnfollow();
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [idea, onUnfollow, t]);

  if (!user) return null;

  return (
    <FollowButton
      forType="idea"
      forId={idea._id}
      onFollow={followIdea}
      onUnfollow={unfollowIdea}
      tooltip={`Follow ${t("generic.ideaWithArticle")} to receive notifications when somebody likes it or posts a comment. When creating or interacting with ${t("generic.ideaWithArticle")} you will automatically start following it.`}
      buttonProps={buttonProps}
    />
  );
};
