import { OpenAPI } from "simplydo/interfaces";
import util from "utils/utils";

type Impact = OpenAPI.GET<"/organisations/{id}/impacts">["response"]["impacts"][number];

export const impactMultipliers = {
  "one-off": 1,
  annually: 1,
  monthly: 12,
  weekly: 52,
};
type Frequency = keyof typeof impactMultipliers;

const forceNumber = (value: string, multiplier?: number) => {
  if (!value) return;
  const number = Number(value);
  if (isNaN(number)) return;
  return number * (multiplier ?? 1);
};

const formatNumber = (value: string, multiplier?: number) => {
  const number = forceNumber(value, multiplier);
  if (!number) return value;

  if (window.Intl) {
    return new Intl.NumberFormat().format(number);
  }
  return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatRange = (
  impact: Impact,
  from: string,
  to?: string,
  options?: {
    frequency?: Frequency;
  },
) => {
  let fromValue = from;
  let toValue = to;

  let prefix = "";
  const suffix = "";

  const multiplier = options?.frequency ? impactMultipliers[options.frequency] ?? 1 : 1;

  if (impact.type === "time") {
    fromValue = util.convertToDayOrWeek(forceNumber(fromValue, multiplier)) ?? fromValue;
    if (toValue) {
      toValue = util.convertToDayOrWeek(forceNumber(toValue, multiplier)) ?? toValue;
    }
  } else {
    fromValue = formatNumber(fromValue, multiplier);
    if (toValue) {
      toValue = formatNumber(toValue, multiplier);
    }
  }

  if (impact.type === "money") {
    prefix = "£";
  }
  return `${prefix}${fromValue}${toValue ? ` - ${toValue}` : ""}${suffix}`;
};

export const formatImpact = (
  orgImpact: Impact,
  impact: any,
  options?: {
    annually?: boolean;
  },
) => {
  return formatRange(orgImpact, impact.choice?.value ?? impact.value, impact.choice?.toRange, {
    frequency: options?.annually ? impact.frequency ?? "annually" : undefined,
  });
};

export const formatOption = (
  impact: Impact,
  option: {
    value: string;
    toRange?: string;
  },
) => {
  return formatRange(impact, option.value, option.toRange);
};
