import React from "react";
import moment from "moment";

import { DateTimeInput as SemanticDateTimeInput, DateTimeInputProps } from "semantic-ui-calendar-react";

type InputProps = Omit<DateTimeInputProps, "value" | "minDate" | "maxDate"> & {
  value: Date;
  minDate?: Date;
  maxDate?: Date;
};

const DateTimeInput = ({ minDate, maxDate, value, dateTimeFormat = "DD-MM-YYYY HH:mm", ...props }: InputProps) => {
  // Semantic date input will crash if the current value passed in is outside the range of min and max date
  // This wrapper component will prevent that from happening by intercepting the value prop and setting a new min/max date that fits the current value
  if (value) {
    if (minDate && value < minDate) {
      minDate = value;
    }
    if (maxDate && value > maxDate) {
      maxDate = value;
    }
  }
  return (
    // @ts-ignore
    <SemanticDateTimeInput
      minDate={minDate}
      maxDate={maxDate}
      value={moment(value).format(dateTimeFormat)}
      dateTimeFormat={dateTimeFormat}
      {...props}
    />
  );
};

export default DateTimeInput;
