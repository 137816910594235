import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Loader, Container, Card, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import api from "api";
import useTheme from "theme/useTheme";
import { Other } from "simplydo/interfaces";

import { EmptyBox, BasicAccordion } from "components/lib/UI";
import { SideNav } from "components/lib/SideNav";
import IdeaCard from "components/lib/Cards/IdeaCard";
import YourIdeasFilters from "components/lib/Filters/YourIdeasFilters";
import NewChallengeModal from "components/challenges/NewChallengeModal";
import IdeasImage from "src/images/ideas.png";

export const SideNavContainer = styled.div`
  ${({ theme }) =>
    !theme.sizes.isComputer
      ? `
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      alignItems: center;
      width: 100%;
      margin: 12.5px 0 2.5px 0;
    `
      : `
      margin-top: 10px;
    `}
`;

type IYourIdeaFilters = {
  sort: string;
  showDraft: boolean;
  showSubmitted: boolean;
  filter: string;
};

const YourIdeas = () => {
  const [ideas, setIdeas] = useState<Other.IIdea[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [newChallengeOpen, setNewChallengeOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<IYourIdeaFilters>({
    sort: "newest",
    showDraft: true,
    showSubmitted: true,
    filter: "",
  });
  const theme = useTheme();
  const { t } = useTranslation();
  const user = useSelector((state: { user: Other.IUserMe | null }) => state?.user);
  const { sort, showDraft, showSubmitted, filter } = filters;

  const fetchIdeas = useCallback(() => {
    if (!isMounted) return;
    setLoading(true);
    api.users.ideas(
      user?._id,
      null,
      (data) => {
        setLoading(false);
        setIdeas(data.ideas);
      },
      () => {},
    );
  }, [isMounted, user?._id]);

  useEffect(() => {
    setIsMounted(true);
    fetchIdeas();
    return () => {
      api.journey.record("ideas", null, "viewIdeasPage");
      setIsMounted(false);
    };
  }, [fetchIdeas]);

  const filteredIdeas = ideas.filter((idea) => {
    if (!showDraft && !idea.isSubmitted) return false;
    if (!showSubmitted && idea.isSubmitted) return false;
    if (idea.ownerTags && idea.ownerTags.find((tag) => tag.value.match(RegExp(filter, "i")))) return true;
    if (idea.name && idea.name.match(RegExp(filter, "i"))) return true;
    if (idea.ownerChallenge && idea.ownerChallenge?.name.includes(filter)) return true;
    return false;
  });
  if (sort === "oldest") filteredIdeas.reverse();
  return (
    <div>
      <NewChallengeModal newChallengeOpen={newChallengeOpen} closeModal={() => setNewChallengeOpen(false)} />
      <Helmet title={"Your ideas"} />
      <div style={{ marginTop: 20 }}>
        <Container>
          <h1 style={{ color: "#3b3b3b", margin: "0px" }}>{t("challenges.yours.ideas.title")}</h1>
        </Container>
      </div>

      <SideNav
        top={60}
        navItems={[
          theme.sizes.isComputer
            ? {
                key: "filters",
                type: "custom" as const,
                content: (
                  <YourIdeasFilters
                    t={t}
                    setFilters={setFilters}
                    showDraft={showDraft}
                    showSubmitted={showSubmitted}
                    sort={sort}
                    filter={filter}
                  />
                ),
              }
            : null,
        ].filter((item) => Boolean(item))}
        main={
          <>
            {!theme.sizes.isComputer && (
              <BasicAccordion
                title={`${t("common:capitalise", { key: "generic.idea" })} Filters`}
                content={
                  <YourIdeasFilters
                    t={t}
                    setFilters={setFilters}
                    showDraft={showDraft}
                    showSubmitted={showSubmitted}
                    sort={sort}
                    filter={filter}
                  />
                }
                style={{ marginBottom: 15 }}
              />
            )}
            {loading && !(filteredIdeas && filteredIdeas.length) && <Loader active inline="centered" />}
            {!loading && !filteredIdeas.length && !filteredIdeas.length && (
              <EmptyBox>
                <img
                  src={IdeasImage}
                  style={{
                    maxHeight: 150,
                    maxWidth: "70%",
                    display: "block",
                    margin: "10px auto",
                  }}
                  alt="Discover"
                />
                <h2>{t("ideas.yours.sidebar.noneCreated")}</h2>
                <p style={{ marginBottom: 10 }}>{t("ideas.yours.sidebar.start")}</p>
                <Button
                  as={Link}
                  to="/challenges/discover"
                  primary
                  icon="eye"
                  content={t("challenges.discover.title")}
                />
              </EmptyBox>
            )}
            {!loading && !filteredIdeas.length && filteredIdeas.length > 0 ? (
              <EmptyBox>
                <img
                  src={IdeasImage}
                  style={{
                    maxHeight: 150,
                    maxWidth: "70%",
                    display: "block",
                    margin: "10px auto",
                  }}
                  alt="Discover"
                />
                <h2>{t("ideas.yours.sidebar.noResults")}</h2>
              </EmptyBox>
            ) : null}
            <Card.Group style={{ marginBottom: 10 }} itemsPerRow={3} stackable>
              {filteredIdeas.map((idea) => (
                <IdeaCard
                  key={idea._id}
                  idea={idea}
                  listView={false}
                  footer={
                    <Link to={`/ideas/${idea._id}`} style={{ color: "#fff", textTransform: "uppercase" }}>
                      {t("ideas.card.view")} <Icon name="arrow alternate circle right outline" />
                    </Link>
                  }
                  themedFooter
                />
              ))}
            </Card.Group>
            <div style={{ marginTop: 30, textAlign: "center" }}>
              <Link
                to="/challenges/discover"
                style={{ color: theme.primaryColour, textDecorationLine: "underline", bottom: 0 }}
              >
                {t("challenges.discover.title")}
              </Link>
            </div>
          </>
        }
      />
    </div>
  );
};

export default YourIdeas;
