import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown, Button, Segment, Divider, Message, Input, Popup, Icon, Loader } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { FakeLink, IconHeader } from "components/lib/UI";
import styled, { ThemeContext } from "styled-components";
import actions from "actions";
import util from "utils/utils";

import RichTextViewer from "components/lib/Editors/RichTextViewer";
import Video from "components/lib/Video/Video";
import RedactedInfo from "components/lib/RedactedInfo";
import ChallengeMyIdeas from "./Ideas/MyIdeas";
import ChallengeProblems from "./Problems";
import { useAppSelector } from "store";

const NewIdeaTrigger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ChallengeClosedActions = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
  .ui.button {
    ${({ theme }) =>
      theme.sizes.isMobile
        ? `
      &:not(:first-of-type) {
        margin-top: 10px;
      }
    `
        : `
      &:not(:first-of-type) {
        margin-left: 10px;
      }
    `}
  }
`;

const OverviewActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  width: 100%;
`;

const OverviewActions = styled.div`
  display: flex;
  min-width: 66%;
  flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
  align-items: ${({ theme }) => (theme.sizes.isMobile ? "center" : "flex-start")};
  justify-content: ${({ theme }) => (theme.sizes.isMobile ? "flex-start" : "space-evenly")};
  .ui.dropdown {
    height: 40px;
  }
  .ui.button,
  .ui.dropdown,
  ${NewIdeaTrigger} {
    flex-grow: 1;
    display: block;
    ${({ theme }) => theme.sizes.isMobile && "width: 100%;"}
    &:not(:last-of-type) {
      ${({ theme }) => (theme.sizes.isMobile ? "margin-bottom: 10px" : "margin-right: 5px")};
    }
  }
  ${NewIdeaTrigger} {
    display: flex;
  }
`;

const Overview = ({
  challenge = { name: "Challenge" },
  t,
  newIdea,
  myIdeas = [],
  isPreview,
  loading,
  ipStatement,
  ipStatementHandled,
  ipManagementInfo,
  setIpStatementAgreementOpen,
  redirectToLogin,
}) => {
  const [showMyIdeas, setShowMyIdeas] = useState(true);
  const theme = useContext(ThemeContext);
  const { video, videoUrl, reward, links, attachments, stage } = challenge;
  const { description = "", instructions, requirements, helpfulTips } = challenge;
  const user = useAppSelector((state) => state.user);
  const canMakeIdea = util.canCreateIdea(user, challenge);
  myIdeas = isPreview ? [] : myIdeas;

  const myUnsubmittedIdeas = useMemo(() => myIdeas?.filter((idea) => !idea.isSubmitted), [myIdeas]);
  return (
    <div>
      <ChallengeProblems challenge={challenge} />
      {challenge.stage === "closed" ? (
        <Message info>
          <Message.Header>
            This {t("generic.challenge")} is currently closed to new {t("generic.ideas")}
          </Message.Header>
          {challenge.ideaVisibility === "users" ||
          challenge.projectManagementVisibility === "users" ||
          util.canManageChallenge(user, challenge) ||
          util.hasPermission(user, "challenge.viewIdeas", challenge?._id) ||
          util.hasPermission(user, "challenge.viewProjectBoard", challenge?._id) ? (
            <Message.Content>You can still take part in the following activities:</Message.Content>
          ) : null}
          <ChallengeClosedActions>
            {challenge.ideaVisibility === "users" ||
            util.canManageChallenge(user, challenge) ||
            util.hasPermission(user, "challenge.viewIdeas", challenge?._id) ? (
              <Button
                basic
                as={Link}
                icon="lightbulb"
                to={`/challenges/${challenge._id}/ideas`}
                content={`${challenge.votingVisibility === "users" ? "Like and comment on" : "View and comment on"} ${t("generic.idea")} submissions`}
              />
            ) : null}
            {challenge.projectManagementVisibility === "users" ||
            util.canManageChallenge(user, challenge) ||
            util.hasPermission(user, "challenge.viewProjectBoard", challenge?._id) ? (
              <Button
                basic
                as={Link}
                icon="compass"
                to={`/challenges/${challenge._id}/board`}
                content={`View ${t("generic.idea")} project board`}
              />
            ) : null}
          </ChallengeClosedActions>
        </Message>
      ) : null}
      {loading && <Loader active />}
      <ChallengeMyIdeas
        showMyIdeas={showMyIdeas}
        setShowMyIdeas={setShowMyIdeas}
        myIdeas={myIdeas}
        style={{ marginBottom: 20 }}
      />
      {(video || description) && (
        <h3 className="challenge-tour-step-2" style={{ textTransform: "uppercase" }}>
          {t("challenge.details.overview")}
        </h3>
      )}
      {video && (
        <div>
          <Video src={videoUrl} canExpand />
          <Divider hidden />
        </div>
      )}
      {description && (
        <div>
          <RichTextViewer content={description} forType="challenge" forId={challenge._id} />
          <Divider hidden />
        </div>
      )}

      {reward && (
        <div>
          <Segment>
            <IconHeader
              as="h3"
              icon="check circle outline"
              header={t("challenge.details.benefits")}
              content={<RichTextViewer content={reward} forType="challenge" forId={challenge._id} />}
              wrapped={theme.sizes.isMobile}
            />
          </Segment>
          <Divider section />
        </div>
      )}

      {instructions && (
        <div>
          <h3 style={{ textTransform: "uppercase" }}>{t("challenge.details.instructions")}</h3>
          <RichTextViewer content={instructions} forType="challenge" forId={challenge._id} />
          <Divider section />
        </div>
      )}

      {requirements && (
        <div>
          <IconHeader
            as="h3"
            icon="envelope open outline"
            header={t("challenge.details.include")}
            content={<RichTextViewer content={requirements} forType="challenge" forId={challenge._id} />}
            wrapped={theme.sizes.isMobile}
          />
          <Divider section />
        </div>
      )}

      {helpfulTips && (
        <div>
          <IconHeader
            as="h3"
            icon="info circle"
            header={t("challenge.details.tips")}
            content={<RichTextViewer content={helpfulTips} forType="challenge" forId={challenge._id} />}
            wrapped={theme.sizes.isMobile}
          />
          <Divider section />
        </div>
      )}

      {((links && links.length > 0) || (attachments && attachments.length > 0)) && (
        <div>
          <IconHeader
            as="h3"
            icon="folder"
            header={t("challenge.links.title")}
            content={
              <div>
                {links &&
                  links.map((link) => (
                    <p key={link.link} style={{ marginBottom: 10 }}>
                      <strong>{link.title}</strong>
                      <br />
                      <a className="with-border" href={link.link} target="_blank" rel="noopener noreferrer">
                        {link.link}
                      </a>
                    </p>
                  ))}
                {attachments &&
                  attachments.map((a, i) => (
                    <p key={i} style={{ marginBottom: 10 }}>
                      <strong>{a.title}</strong>
                      <br />
                      <a className="with-border" href={a.url} target="_blank" rel="noopener noreferrer">
                        {a.name}
                      </a>
                    </p>
                  ))}
              </div>
            }
          />
          <Divider hidden />
        </div>
      )}

      {ipManagementInfo?.hasSensitiveInfo && util.organisationFeaturesEnabled(user, ["ipManagement"]) && (
        <Message info>
          <h3>ADDITIONAL INFORMATION</h3>
          {!ipManagementInfo.userHasSigned ? (
            <>
              <p>
                <Icon name="info" />{" "}
                <strong>
                  This information will be available once you have agreed to and signed the IP statement for this
                  challenge.
                </strong>
              </p>
              <OverviewActionsContainer style={{ alignItems: "flex-start", marginBottom: 0 }}>
                {[...Array(6)].map((e, i) => (
                  <RedactedInfo key={i} width={Math.random() * 25 + 60} />
                ))}
              </OverviewActionsContainer>
            </>
          ) : (
            <>
              <p>
                <strong>
                  <Icon name="info" /> You can read this information because you have signed the IP statement for this
                  challenge.
                </strong>
              </p>
              <RichTextViewer content={ipStatement?.statementSensitiveInfo} forType="challenge" forId={challenge._id} />
            </>
          )}
          <Button
            secondary
            onClick={() => setIpStatementAgreementOpen(true)}
            icon="file alternate"
            content="View IP statement"
            style={{ marginTop: 10 }}
          />
        </Message>
      )}

      {stage === "published" && !user && (
        <OverviewActionsContainer>
          <OverviewActions>
            {challenge.ideaVisibility === "users" && (
              <Button
                style={{
                  border: `1px solid ${theme.primaryColour}`,
                  color: theme.primaryColour,
                  backgroundColor: "#fff",
                  marginBottom: 5,
                }}
                icon="unhide"
                content={t("challenge.viewOtherIdeas")}
                as={Link}
                to={`/challenges/${challenge._id}/ideas`}
              />
            )}

            <NewIdeaTrigger>
              <Button
                primary
                icon="plus"
                content={challenge.buttonText ? challenge.buttonText : t("challenge.ideas.createYourIdea")}
                onClick={canMakeIdea ? newIdea : () => redirectToLogin()}
              />
              <Popup
                trigger={
                  <div style={{ alignSelf: "center", marginTop: 3 }}>
                    <FakeLink withBorder>
                      <Icon name="question circle" />
                      {t("challenge.details.ideaHelpTitle")}
                    </FakeLink>
                  </div>
                }
                content={t("challenge.details.ideaHelpDescription")}
              />
            </NewIdeaTrigger>

            {myUnsubmittedIdeas?.length === 1 && (
              <Button
                icon="lightbulb"
                content={t("challenge.continueIdea")}
                primary
                as={Link}
                to={`/ideas/${myIdeas[0]._id}`}
              />
            )}

            {myUnsubmittedIdeas?.length > 1 && (
              <Dropdown icon={null} trigger={<Button primary icon="lightbulb" content={t("challenge.continueIdea")} />}>
                <Dropdown.Menu>
                  {myUnsubmittedIdeas.map((i) => (
                    <Dropdown.Item key={i._id} as={Link} to={`/ideas/${i._id}`} content={i.name} />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </OverviewActions>
        </OverviewActionsContainer>
      )}

      {stage === "published" && user && (
        <OverviewActionsContainer>
          <OverviewActions>
            {(challenge.ideaCount >= 1 && challenge.ideaVisibility === "users") ||
            util.canManageChallenge(user, challenge) ||
            util.hasPermission(user, "challenge.viewIdeas", challenge?._id) ? (
              <Button
                inverted
                basic
                primary
                icon="unhide"
                content={t("challenge.viewOtherIdeas")}
                as={Link}
                to={`/challenges/${challenge._id}/ideas`}
                className="challenge-tour-step-3"
              />
            ) : null}

            {canMakeIdea && (!myUnsubmittedIdeas || myUnsubmittedIdeas?.length === 0) ? (
              <Button
                primary
                icon="plus"
                content={challenge.buttonText ? challenge.buttonText : t("challenge.ideas.createYourIdea")}
                onClick={canMakeIdea ? newIdea : () => redirectToLogin()}
                disabled={!ipStatementHandled}
                className="challenge-tour-step-3"
              />
            ) : null}

            {myUnsubmittedIdeas?.length === 1 && (
              <Button
                icon="lightbulb"
                content={t("challenge.continueIdea")}
                primary
                as={Link}
                to={`/ideas/${myIdeas[0]._id}`}
              />
            )}

            {myUnsubmittedIdeas?.length > 1 && (
              <Dropdown
                icon={null}
                trigger={
                  <Button style={{ width: "100%" }} primary icon="lightbulb" content={t("challenge.continueIdea")} />
                }
              >
                <Dropdown.Menu>
                  {myUnsubmittedIdeas.map((i) => (
                    <Dropdown.Item key={i._id} as={Link} to={`/ideas/${i._id}`} content={i.name} />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </OverviewActions>
          {util.canManageChallenge(user, challenge) ||
          util.hasPermission(user, "challenge.editSettings", challenge?._id) ? (
            <Input
              style={{ marginTop: 10 }}
              label="Login link"
              value={`${user?.ownerOrganisation?.code || "simplydo"}.simplydo.co.uk`}
              readOnly
            />
          ) : null}
        </OverviewActionsContainer>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({ user: props.isPreview ? {} : state.user });
const mapDispatchToProps = (dispatch) => ({
  updateFilters: (data) => dispatch(actions.challenges.updateIdeaFilters(data)),
});
const ChallengeOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(Overview);

export default withTranslation()(ChallengeOverviewContainer);
