import React, { useMemo, useState } from "react";

import { Modal, Button, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

type ExternalLinkModalProps = {
  href: string;
  color?: string;
  onClose?: () => void;
  onFollow?: () => void;
} & (
  | {
      open: boolean;
      children?: never;
    }
  | {
      children: React.ReactNode;
      open?: never;
    }
);

const ExternalLinkModal = ({ children, color, open, href, onClose, onFollow }: ExternalLinkModalProps) => {
  const [controlledOpen, setControlledOpen] = useState(false);
  const { t } = useTranslation();

  const sureHref = useMemo(() => {
    return href?.startsWith("http") ? href : `https://${href}`;
  }, [href]);

  return (
    <>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        small
        open={open || controlledOpen}
        onClose={() => {
          onClose?.();
          setControlledOpen(false);
        }}
      >
        <Modal.Header>Warning</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            You are about to leave the safety of the Simply Do platform to follow an external link. Please make sure
            that you can trust the linked resource before proceeding.
            <br />
            <br />
            Please make sure to never enter your login information anywhere but on the Simply Do platform.
          </Modal.Description>
          <Divider />
          <Modal.Description>
            <b>{sureHref}</b>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Open link in a new tab"
            secondary
            onClick={() => {
              window.open(sureHref, "_blank");
              setControlledOpen(false);
              onFollow?.();
            }}
          />
          <Button
            content={t("generic.cancel")}
            onClick={() => {
              setControlledOpen(false);
              onClose?.();
            }}
          />
        </Modal.Actions>
      </Modal>
      {children ? (
        <a
          onClick={(e) => {
            e.preventDefault();
            setControlledOpen(true);
          }}
          href={sureHref}
          style={{ color }}
        >
          {children}
        </a>
      ) : null}
    </>
  );
};

export default ExternalLinkModal;
