import React, { useState, useEffect, useCallback, useMemo } from "react";
import "remirror/styles/all.css";

import Menu from "./menu";
import Mentions from "./Mentions";
import createExtension from "./extensions";
import EditorWrapper from "./editor";
import { DefaultPreset } from "./extensions/default";

const getExtensions = (options, settings, readOnly) => {
  const order = [];

  const combinedOptions = options.reduce((p, { name, config = {} }) => {
    if (!order.includes(name)) {
      order.push(name);
    }

    // Workaround for injecting `uploadHandler` for images during paste actions
    if ("uploadFile" in config && "uploadOptions" in settings) {
      config.uploadHandler = (files) => {
        const promises = files.map(
          ({ file, progress }, i) =>
            () =>
              new Promise((resolve) => {
                config
                  .uploadFile(file, settings.uploadOptions)
                  .then((src) => {
                    progress((i + 1) / files.length);
                    resolve({ src, name: file.name, fileName: file.name });
                  })
                  .then((result) => Promise.resolve(result))
                  .catch((err) => {
                    console.error(err);
                  });
              }),
        );

        return promises;
      };
    }

    if (name === "link") {
      config.onExternalLink = settings.onExternalLink;
    }

    // Workaround for image resizing not respecting Remirror `editable`
    if (name === "image") {
      config.enableResizing = !readOnly;
    }

    // Set fullMentionLinks to true to get full links instead of relative links
    if (name === "mention") {
      if (settings.fullMentionLinks && !config._relativeFormatMention) {
        config._relativeFormatMention = config.formatMention ?? ((item) => item);
        config.formatMention = (item) => {
          const formatted = config._relativeFormatMention(item);
          return {
            ...formatted,
            href: window.location.origin + formatted.href,
          };
        };
      }
    }

    const prev = p[name];
    if (!prev) {
      p[name] = [config];
    } else {
      p[name] = [...prev, config];
    }
    return p;
  }, {});

  const initialisedExtensions = order.map((name) => createExtension(name, combinedOptions[name]));
  return { initialisedExtensions, combinedOptions };
};

const Editor = ({
  value,
  onChange,
  onChangeJson,
  onBlur,
  emptyReturnValue,
  onMenuClick,
  extraMenuItems,
  HelpText,
  stickyMenu = false,
  stickyMenuOffset = 0,
  options = DefaultPreset,
  mentions,
  onSuggest,
  onMentionSelect,
  hideMenu,
  autoHideMenu = true,
  noShadow,
  readOnly = false,
  placeholder = null,
  settings = {},
  historyEnabled = true,
  editorRef,
  handleKeyDown,
}) => {
  const [focused, setFocused] = useState(false);
  const [extensions, setExtensions] = useState(() => getExtensions(options, settings, readOnly));
  const [hasMentions, setHasMentions] = useState(false);
  const { initialisedExtensions, combinedOptions } = extensions;

  useEffect(() => {
    setExtensions(getExtensions(options, settings, readOnly));
    setHasMentions(options.findIndex((o) => o.name === "mention") !== -1);
  }, [options, settings, readOnly]);

  // if (readOnly) {
  //   return <div dangerouslySetInnerHTML={{__html: value}} />;
  // }

  const blurHandler = useCallback(
    (val) => {
      if (document.hasFocus()) {
        setFocused(false);
      }
      onBlur && onBlur(val);
    },
    [onBlur],
  );

  const focusHandler = useCallback(() => {
    setFocused(true);
  }, []);

  const realValue = useMemo(() => value?.split("\n")?.join("<p>") ?? emptyReturnValue, [value, emptyReturnValue]);

  return (
    <EditorWrapper
      placeholder={placeholder}
      readOnly={readOnly}
      value={realValue}
      onChange={onChange}
      onChangeJson={onChangeJson}
      onBlur={blurHandler}
      onFocus={focusHandler}
      emptyReturnValue={emptyReturnValue}
      extensions={initialisedExtensions}
      noShadow={noShadow}
      settings={settings}
      combinedOptions={combinedOptions}
      editorRef={editorRef}
      handleKeyDown={handleKeyDown}
    >
      {!hideMenu ? (
        <Menu
          extraMenuItems={extraMenuItems}
          onMenuClick={onMenuClick}
          HelpText={HelpText}
          sticky={stickyMenu}
          stickyOffset={stickyMenuOffset}
          historyEnabled={historyEnabled}
          focused={focused}
          setFocused={setFocused}
          autoHideMenu={autoHideMenu}
        />
      ) : null}
      {hasMentions ? (
        <Mentions
          mentions={mentions}
          config={combinedOptions.mention}
          onMentionSelect={onMentionSelect}
          onSuggest={onSuggest}
        />
      ) : null}
    </EditorWrapper>
  );
};

export default React.memo(Editor);
